<template>
  <div>
    <h2 class="content-block">
      {{ $t('file_types.title') }}
    </h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <DxDataGrid :data-source="file_types_datasource" :columns="file_types_columns" :show-row-lines="showRowLines"
          :show-column-lines="showColumnLines" :row-alternation-enabled="true" :rtl-enabled="true"
          :remote-operations="true" :column-auto-width="true" :allow-column-reordering="true"
          :allow-column-resizing="true" :hover-state-enabled="true" :noDataText="checkData"
          :selection="{ mode: 'single' }" @toolbar-preparing="onToolbarPreparing"
          @selection-changed="onSelectionChanged" class="mt-3" id="ps_Table" key-expr="id" ref="main_grid">

          <DxColumnChooser :enabled="true" />
          <DxExport :enabled="allowExportDataGrid" :allow-export-selected-data="allowExportSelectedDataGrid" />

          <DxHeaderFilter :visible="showHeaderFilter" />
          <DxSearchPanel :visible="true" :width="190" :placeholder="this.$i18n.t('file_types.grid_search')" class="" />

          <DxSelection :select-all-mode="SelectionAllMode" :show-check-boxes-mode="checkBoxesMode" mode="multiple" />

          <template #btnGroup class="row">
            <div class="d-lg-none">
              <div class="mt-2">
                <DxDropDownButton :items="Dropactions" :drop-down-options="{ width: 190 }"
                  :text="this.$i18n.t('file_types.drop_down_toolbar')" icon="save" @item-click="onItemClick" />
              </div>
            </div>
            <div class="d-none d-lg-block">
              <DxButton class="ml-2 bg-success" color="success" size="sm" icon="plus" @click="NewFileType()">
              </DxButton>
              <DxButton color="secondary" class="ml-2" size="sm" icon="revert" @click="$router.go(-1)">
              </DxButton>
              <DxButton color="light" size="sm" icon="pulldown" @click="reloadData">
              </DxButton>
            </div>
          </template>


          <template #btnRGroup class="p-0 m-0">
            <div class="d-none d-lg-block">
              <div class="row">
                <div class="col-sm-12">
                  <button class="btn w-100 btn-sm mr-1 bg-warning" size="sm"> {{ $t('file_types.drop_down_toolbar')
                  }}</button>
                </div>
              </div>
            </div>

          </template>
          <template #actions="{ data }">
            <div>
              <DxButton class="bg-warning m-1" icon="edit" type="edit" :hint="this.$i18n.t('file_types.grid_row_edit')"
                @click="setEdit(data)">
              </DxButton>
              <DxButton class="bg-danger m-1" icon="remove" :hint="this.$i18n.t('file_types.grid_row_delete')"
                @click="setDeleteDialog">
              </DxButton>
            </div>
          </template>
          <template #radif="{ data }">
            {{ data.rowIndex + 1 }}
          </template>
          <DxFilterRow :visible="true" />
          <DxLoadPanel :enabled="true" />
          <DxScrolling mode="standard" :use-native="true"/>
        </DxDataGrid>

      </div>
    </div>



    <!-- اطلاعات نوع فایل جدید-اصلاح    -->
    <DxPopup style="text-align: :center;" :width="showDialogNewWidth" :height="showDialogNewHeight" :show-title="true"
      :title="this.$i18n.t('file_types.dialog_new_header')" alignment="center" :drag-enabled="true"
      :hide-on-outside-click="false" v-model:visible="showDialogNew">
      <template #content="{ data }">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">

            <div class="card">
              <!-- <h5 class="card-header">فرم اطلاعات کاربر</h5> -->
              <div class="card-body">
                <form>
                  <!-- Height-Width-Controller -->
                  <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                      <div class="d-flex flex-row">
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Height-Width-Controller -->
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewFileTypeInfo.title" @input="this.check_content($event, 'inputTitle')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputTitle'), 'border-success-my': this.isStateOk('inputTitle'), 'rounded': true }"
                          :placeholder="this.$i18n.t('file_types.dialog_new_title_file_type')" type="text">
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <textarea v-model="NewFileTypeInfo.description" rows="2" class="form-control"
                          :placeholder="this.$i18n.t('file_types.dialog_new_desc_file_type')" type="text" />
                      </div>
                    </div>
                  </div>
                  <hr>



                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewFileTypeInfo.ext" class="form-control rounded"
                          :placeholder="this.$i18n.t('file_types.dialog_new_ext_file_type')" type="text">
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-12">
                      <button @click="addNewFileToList" class="btn btn-primary m-1"> {{
                          $t('file_types.dialog_new_add_file_type')
                      }}</button>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <DxDataGrid :data-source="NewFileTypes_Datasource" :columns="NewFileTypes_columns"
                          height="190px" :ref="gridNewFileType" :show-row-lines="showRowLines"
                          :show-column-lines="showColumnLines" :row-alternation-enabled="true" :rtl-enabled="true"
                          :remote-operations="true" :column-auto-width="true" :allow-column-reordering="true"
                          :allow-column-resizing="true" :hover-state-enabled="true" :noDataText="checkData"
                          :selection="{ mode: 'single' }" @selection-changed="onSelectionChangedNewFileType"
                          class="mt-3" id="ps_Table" key-expr="title" ref="new_file_type_grid">
                          <DxSelection :select-all-mode="SelectionAllMode" :show-check-boxes-mode="checkBoxesMode"
                            mode="multiple" />
                          <template #radif_1="{ data }">
                            {{ data.rowIndex + 1 }}
                          </template>
                          <template #actions_1="{ data }">
                            <div>
                              <DxButton class="bg-danger m-1" icon="remove"
                                title="this.$i18n.t('file_types.dialog_new_grid_row_delete')"
                                @click="DeleteNewFileType">
                              </DxButton>
                            </div>
                          </template>
                        </DxDataGrid>
                      </div>
                    </div>
                  </div>

                  <button v-if="editMode" class="btn btn-success mt-3 me-1 ms-2" @click="editFileTypeInfo"><i
                      class="fa fa-home"></i>{{ $t('file_types.dialog_new_btn_edit') }}</button>
                  <button v-else class="btn btn-success mt-3 me-1 ms-2" @click="saveNewFileType"><i
                      class="fa fa-home"></i>{{ $t('file_types.dialog_new_btn_save') }}</button>
                  <button class="btn btn-warning mt-3" @click="this.showDialogNew = false;"><i class="fa fa-home"></i>{{
                      $t('file_types.dialog_new_btn_back')
                  }}</button>
                </form>

                <div class="card-footer text-muted mt-2">{{ $t('file_types.dialog_new_footer_label') }}</div>
              </div>
            </div>

          </div>
        </DxScrollView>
      </template>
    </DxPopup>




    <customDial :show="showDeleteDialog" :cancel="() => { this.showDeleteDialog = false; }" :confirm="delFileTypeM"
      :title="this.$i18n.t('file_types.delete_dialog_title')" :userPersonalInfo="fullFileTypeSelected"
      :description="this.$i18n.t('file_types.delete_dialog_body')" />

  </div>
</template>
<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxForm, DxItem } from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import { DxDataGrid, DxSelection } from 'devextreme-vue/data-grid';
import { dxGrid } from '@/components/dxGrid'
import DxButton from 'devextreme-vue/button';
import { mapGetters, useStore } from 'vuex';
import MyValidator from '@/components/MyValidator'
import notify from 'devextreme/ui/notify';
import customDial from '@/components/customDialog.vue'
import themes from 'devextreme/ui/themes';
import { computed } from '@vue/reactivity';
import DxDropDownButton from 'devextreme-vue/drop-down-button';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import users_compo from '@/components/users_compo.vue'
import { DxDropDownBox } from 'devextreme-vue/drop-down-box';
import responsives from '@/utils/responsives';


import DataSource from 'devextreme/data/data_source';

const ds = new DataSource({
  // ...
});

export default {
  setup() {
    const store = useStore();
    return {
      store,
      file_types_datasource: computed(() => store.getters['file_types/getFileTypes']),

    }
  },
  mixins: [dxGrid, MyValidator, responsives],
  components: {
    DxButton,
    DxDataGrid,
    DxPopup,
    DxForm,
    DxSelectBox,
    DxDropDownButton,
    DxScrollView,
    DxDropDownBox,
    customDial
  },
  data() {
    return {
      selectedNewFileTypeRowIndex: -1,
      gridNewFileType: 'gridNewFileTypeRef',
      NewFileTypeSelected: {},
      NewFileTypeInfo: {},
      NewFileTypes_columns: [
        // { cellTemplate: "radif_1", caption: "ردیف", dataType: 'number', allowFiltering: false,width: 50, alignment: "center"},
        { cellTemplate: 'actions_1', caption: this.$i18n.t('file_types.dialog_new_grid_row_operaton'), allowFiltering: false, allowSorting: false },
        // { dataField: 'title', caption: 'عنوان', dataType: 'string' ,alignment:'center',format: { type: "fixedPoint",  precision: 0 }},
        { dataField: 'ext', caption: this.$i18n.t('file_types.dialog_new_grid_row_file_type'), dataType: 'string', alignment: 'center' },
        // { dataField: 'description', caption: 'توضیحات', dataType: 'string' ,alignment:'center' },

      ],
      NewFileTypes_Datasource: ds,
      fullFileTypeSelected: '',
      selectedMainFileTypeRow: {},
      NewCatagoryInfo: {},
      editMode: false,
      file_types_columns: [
        { cellTemplate: "radif", caption: this.$i18n.t('file_types.grid_row_no'), dataType: 'number', allowFiltering: false, width: 50, alignment: "center" },
        { cellTemplate: 'actions', caption: this.$i18n.t('file_types.grid_row_operation'), allowFiltering: false, allowSorting: false },
        { dataField: 'id', caption: 'شناسه', dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'title', caption: this.$i18n.t('file_types.grid_row_title'), dataType: 'string', alignment: 'center', format: { type: "fixedPoint", precision: 0 } },
        { dataField: 'description', caption: this.$i18n.t('file_types.grid_row_description'), dataType: 'string', alignment: 'center' },
        { dataField: 'fileTypes', caption: this.$i18n.t('file_types.grid_row_file_types'), dataType: 'string', alignment: 'center' },
      ],
      showDialogNew: false,
      showDeleteDialog: false,
      Dropactions: [
        { id: 1, text: this.$i18n.t('file_types.drop_down_toolbar_new_item'), icon: "user" },
        { id: 2, text: this.$i18n.t('file_types.drop_down_toolbar_new_reload'), icon: "refresh" },
        { id: 3, text: this.$i18n.t('file_types.drop_down_toolbar_new_back'), icon: "undo" },
        { id: 4, text: "تنظیمات1", icon: "preferences" },
        { id: 5, text: "تنظیمات2", icon: "preferences" },
        { id: 6, text: "تنظیمات3", icon: "preferences" },
      ],
      checkBoxesMode: themes.current().startsWith('material') ? 'always' : 'onClick',
    }
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      debugger
      const dataGrid = this.$refs.main_grid.instance;
      this.$refs.main_grid.columns[0].caption = this.$i18n.t('file_types.grid_row_no');
      this.$refs.main_grid.columns[1].caption = this.$i18n.t('file_types.grid_row_operation');
      // this.$refs.main_grid.columns[3].caption = this.$i18n.t('file_types.grid_userFilesRequiredEDMSDescription');
      this.$refs.main_grid.columns[3].caption = this.$i18n.t('file_types.grid_row_title');
      this.$refs.main_grid.columns[4].caption = this.$i18n.t('file_types.grid_row_description');
      this.$refs.main_grid.columns[5].caption = this.$i18n.t('file_types.grid_row_file_types');

      dataGrid.option('columns', dataGrid.option('columns'));

     
      if (this.$refs.gridNewFileTypeRef != null) {
        const dataGrid_fileType = this.$refs.gridNewFileTypeRef.instance;
        this.$refs.gridNewFileTypeRef.columns[0].caption = this.$i18n.t('file_types.dialog_new_grid_row_operaton');
        this.$refs.gridNewFileTypeRef.columns[1].caption = this.$i18n.t('file_types.dialog_new_grid_row_file_type');
        dataGrid_fileType.option('columns', dataGrid_fileType.option('columns'));
      }
    }
  },
  created() {
    this.make_state_bind('inputTitle', false, this.$i18n.t('file_types.validation_new_title_file_type'));
    this.reloadData();
  },
  methods: {
    saveNewFileType() {
      debugger
      if (this.isStateOkTotal() > 0) {
        let give_me_errors = this.getElementsWithErorrs();
        notify(give_me_errors, 'error', this.notifyTimeSet);
        return;
      }
      const holder_new_Date = {
        Title: this.NewFileTypeInfo.title,
        Description: this.NewFileTypeInfo.description
      }
      let ext_holder_array = [];

      this.NewFileTypes_Datasource._items.forEach(element => {
        debugger
        ext_holder_array.push(element.ext);
      });
      const ext_holder_text = ext_holder_array.join(',');
      holder_new_Date.FileTypes = ext_holder_text;
      this.store.dispatch('file_types/addNewFileTypes', holder_new_Date);
      ds.store()._array = []
      this.showDialogNew = false;
      setTimeout(() => {
        this.reloadData();
      }, this.reloadTimeSet);
    },
    addNewFileToList() {
      debugger
      if (this.NewFileTypeInfo.ext === undefined) {
        notify(this.$i18n.t('file_types.validation_new_filoe_to_list'), 'warning', this.notifyTimeSet);
        return;
      }
      const holder = {
        //title:this.NewFileTypeInfo.title,
        ext: this.NewFileTypeInfo.ext,
        //description:this.NewFileTypeInfo.description
      };
      ds.store().insert(holder).then(() => ds.reload());

      notify(this.$i18n.t('file_types.new_file_add_success_title') + 
      this.NewFileTypeInfo.title + 
      this.$i18n.t('file_types.new_file_add_success_body'), 'success', this.notifyTimeSet);
    },
    DeleteNewFileType() {
      debugger
      if (this.NewFileTypeSelected.ext === undefined) {
        return;
      }

      const dataGrid1 = this.$refs[this.gridNewFileType].instance;
      dataGrid1.deleteRow(this.selectedNewFileTypeRowIndex);
    },
    setEdit() {
      debugger
      if (this.selectedMainFileTypeRow === undefined || this.selectedMainFileTypeRow.title === null || this.selectedMainFileTypeRow.title === undefined) {
        notify(this.$i18n.t('file_types.set_edit'), 'warning', this.notifyTimeSet);
        return;
      }
      this.makeInitSize(600, 600);
      this.make_state('inputTitle', true);
      this.NewFileTypeInfo = this.selectedMainFileTypeRow;
      ds.store()._array = []
      this.selectedMainFileTypeRow.fileTypes.split(',').forEach(element => {
        debugger
        const holder = {
          ext: element,
        };
        ds.store().insert(holder).then(() => ds.reload());
      });


      this.editMode = true;
      this.showDialogNew = true;
    },
    delFileTypeM() {
      this.store.dispatch('file_types/deleteFileTypes', this.selectedMainFileTypeRow.id);
      this.showDeleteDialog = false;
      setTimeout(() => {
        this.reloadData();
      }, this.reloadTimeSet);
    },
    setDeleteDialog() {
      debugger
      if (this.selectedMainFileTypeRow.title === null || this.selectedMainFileTypeRow.title === undefined) {
        notify(this.$i18n.t('file_types.validate_pre_delete'), 'warning', this.notifyTimeSet);
        return;
      }
      this.showDeleteDialog = true;
    },
    editFileTypeInfo() {
      if (this.isStateOkTotal() > 0) {
        let give_me_errors = this.getElementsWithErorrs();
        notify(give_me_errors, 'error', this.notifyTimeSet);
        return;
      }
      debugger

      const holder_new_Date = {
        Id: this.selectedMainFileTypeRow.id,
        Title: this.NewFileTypeInfo.title,
        Description: this.NewFileTypeInfo.description
      }
      let ext_holder_array = [];

      this.NewFileTypes_Datasource._items.forEach(element => {
        debugger
        ext_holder_array.push(element.ext);
      });
      const ext_holder_text = ext_holder_array.join(',');
      holder_new_Date.FileTypes = ext_holder_text;

      this.store.dispatch('file_types/editFileTypes', holder_new_Date);
      this.showDialogNew = false;
      setTimeout(() => {
        this.reloadData();
      }, this.reloadTimeSet);
    },
    NewFileType() {
      this.makeInitSize(600, 750);
      this.editMode = false;
      this.make_state('inputTitle', false);
      this.NewCatagoryInfo = {};
      this.showDialogNew = true;
    },
    reloadData() {
      this.store.dispatch('file_types/getFileTypes');
    },
    onItemClick(e) {
      debugger
      switch (e.itemData.id) {
        case 1:
          this.NewFileType();
          break;
        case 2:
          this.reloadData();
          break;
        case 3:
          this.$router.go(-1);
          break;
        default:
          notify('خطای ناشناس در منو', 'warning', this.notifyTimeSet);
          break;
      }
    },
    onSelectionChangedNewFileType(selectedRowsData) {
      debugger
      this.NewFileTypeSelected = selectedRowsData.selectedRowsData[0];
      this.selectedNewFileTypeRowIndex = selectedRowsData.component.getRowIndexByKey(selectedRowsData.selectedRowKeys[0]);
    },
    onSelectionChanged(selectedRowsData) {
      debugger
      this.selectedMainFileTypeRow = selectedRowsData.selectedRowsData[0];
      if (this.selectedMainFileTypeRow != null || this.selectedMainFileTypeRow != undefined) {
        this.fullFileTypeSelected = this.selectedMainFileTypeRow.title;
      }

    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "btnRGroup",
      });
      e.toolbarOptions.items.unshift({
        location: "after",
        template: "btnGroup",
      });
    },
  },

}
</script>
<style lang="scss">
@import "@/dx-styles.scss";

.dx-popup-wrapper>.dx-overlay-content {
  background: #fff;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border-left-color: $popup_color;
  border-left-width: 9px;
}

#ps_Table {
  height: 60vh;
}
</style>