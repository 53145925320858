<template>
    <div>
      <h2 class="content-block">{{ $t('user_roles.title') }}</h2>
      <div class="content-block">
        <div class="dx-card responsive-paddings">          
                <DxDataGrid
                    :data-source="user_roles_datasource"
                    :columns="user_roles_columns"
                    

                    :show-row-lines="showRowLines"
                    :show-column-lines="showColumnLines"
                    :row-alternation-enabled="true"
                    :rtl-enabled="true"
                    :remote-operations="true"
                    :column-auto-width="true"
                    :allow-column-reordering="true"
                    :allow-column-resizing="true"
                    :hover-state-enabled="true"
                    :noDataText="checkData"

                    :selection="{ mode: 'single' }"

                    @toolbar-preparing="onToolbarPreparing"
                    @selection-changed="onSelectionChanged"

                    class="mt-3"
                    id="ps_Table"

                    key-expr="userName"
                    
                    ref="main_grid">
                    
                    <DxColumnChooser :enabled="true"/>
                    <DxExport
                      :enabled="allowExportDataGrid"
                      :allow-export-selected-data="allowExportSelectedDataGrid"
                    />

                    <DxHeaderFilter
                      :visible="showHeaderFilter"
                    />
                    <DxSearchPanel
                    :visible="true"
                    :width="190"
                    :placeholder="this.$i18n.t('user_roles.grid_search')"
                    class=""
                    /> 
                    
                    <DxSelection
                      :select-all-mode="allMode"
                      :show-check-boxes-mode="checkBoxesMode"
                      mode="multiple"
                    />

                    <DxScrolling mode="standard" :use-native="true"/> 
                    <DxPaging
                      :enabled="false"
                    /> 
                   
                    <template #btnGroup class="row">
                        <div class="d-lg-none">                   
                            <div class="mt-2">
                                <DxDropDownButton
                                    :items="Dropactions"
                                    :drop-down-options="{ width: 190 }"
                                    :text="this.$i18n.t('user_roles.grid_row_operation')"
                                    icon="save"
                                    @item-click="onItemClick"
                                />
                            </div>                
                        </div>
                      <div class="d-none d-lg-block">
                        <!-- <DxButton
                        class="ml-2 bg-success"
                        color="success"
                        size="sm"
                        icon="plus"
                        @click="NewPartPoput()">
                        </DxButton> -->
                        <DxButton
                        color="secondary"
                        class="ml-2"
                        size="sm"
                        icon="revert"
                        @click="$router.go(-1)">
                        </DxButton>
                        <DxButton
                        color="light"
                        size="sm"
                        icon="refresh"
                        @click="reloadData"
                    
                            >
                        </DxButton>
                        </div>
                     </template>


                     <template #btnRGroup class="p-0 m-0">                     
                        <div class="d-none d-lg-block">
                          <div class="row">
                              <div class="col-sm-12">
                                  <Button @click="setRoleToUsersDialog" class="btn w-100 btn-sm mr-1 bg-warning" size="sm"  >{{ $t('user_roles.grid_btn_user_assign_role') }}</Button> 
                              </div>                         
                          </div> 
                        </div>
                                  
                    </template>
                    <template #actions="{ data }">
                      <div>                        
                        <!-- <DxButton
                        class="bg-warning m-1"
                        icon="edit"
                        type="edit" 
                        title="ویرایش"
                        @click="setEdit(data)"> 
                        </DxButton>-->
                        <DxButton
                        class="bg-danger m-1"
                        icon="remove"
                        :title="this.$i18n.t('user_roles.grid_btn_delete')"
                        @click="setDeleteDialog"
                        >
                        </DxButton>
                      </div>
                </template>
                <template #radif="{ data }">
                  {{data.rowIndex + 1}}
                </template>
                <DxFilterRow :visible="true" />
                <DxLoadPanel :enabled="true" />
                <DxScrolling mode="standard" :use-native="true"/>
            </DxDataGrid>
        
        </div>
      </div>

      
      <!-- <DxPopup
      style="text-align: :center;"
      :width="600"
      :height="260"
      :show-title="true"
      title="تخصیص نقش به کاربران"
      alignment="center"
      :drag-enabled="true"
      :hide-on-outside-click="false"
      v-model:visible="showDialogSaveRoleToUser"
    >
      <template #content="{ data }">
        <DxScrollView
            width="100%"
            height="100%"
          >
        <div class="popup-property-details">

          <div class="card">
            <div class="card-body">              
                <form>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <font-awesome-icon icon="fas fa-user" class="m-1"/> </span>
                        </div>
                          <input v-model="NewPartName" 
                          @input="this.check_content($event,'inputName')"   
                          :class="{'border-danger-my':!this.isStateOk('inputName'),'border-success-my':this.isStateOk('inputName'),'rounded':true}"
                          placeholder="عنوان سازمان" type="text" >
                      </div>
                    </div>                  
                  </div>
      
                <button v-if="editMode" class="btn btn-success mt-3 me-1 ms-2" @click="editPartInfo($event)"><i class="fa fa-home"></i>اصلاح</button> 
                <button v-else class="btn btn-success mt-3 me-1 ms-2" @click="saveNewPart"><i class="fa fa-home"></i>ذخیره</button> 
                <button class="btn btn-warning mt-3" @click="this.showDialog = false;"><i class="fa fa-home"></i>بازگشت</button>
              </form>

              <div class="card-footer text-muted mt-2">لطفا فیلد های ضروری را به صورت کامل پر نمایید</div>
            </div>
          </div>
             
         </div>
       </DxScrollView>
    </template>
    </DxPopup> -->



    <DxPopup
      style="text-align: :center;"
      :width="this.showDialogNewWidth"
      :height="this.showDialogNewHeight"
      :show-title="true"
      :title="this.$i18n.t('user_roles.user_role_assign_form')"
      alignment="center"
      :drag-enabled="true"
      :hide-on-outside-click="false"
      v-model:visible="showDialogSaveRoleToUser"
    >
      <template #content="{ data }">
        <DxScrollView
            width="100%"
            height="100%"
          >
        <div class="popup-property-details">

          <div class="card">
            <!-- <h5 class="card-header">فرم اطلاعات کاربر</h5> -->
            <div class="card-body">
              
                <form>

                  <!-- Height-Width-Controller -->
                  <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                        <div class="d-flex flex-row">
                            <div class="">
                              <div class="form-group input-group">
                                  <div class="input-group-prepend">
                                      <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png" class="responseModels m-1"/>
                                  </div>
                              </div>                      
                            </div>
                            <div class="">
                              <div class="form-group input-group">
                                  <div class="input-group-prepend">
                                    <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png" class="responseModels m-1"/>
                                  </div>
                              </div>                      
                            </div>
                            <div class="">
                              <div class="form-group input-group">
                                  <div class="input-group-prepend">
                                    <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png" class="responseModels m-1"/>
                                  </div>
                              </div>                      
                            </div>
                        </div>
                      </div>
                  </div>
                  <!-- Height-Width-Controller -->

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <font-awesome-icon icon="fas fa-user" class="m-3"/> </span>
                        </div>
                          <DxDropDownBox
                              v-model:value="gridBoxValue"
                              v-model:opened="isDropDownBoxOpened"
                              :defer-rendering="false"
                              :display-expr="gridBoxDisplayExpr"
                              :show-clear-button="true"
                              :data-source="all_roles_datasource"
                              value-expr="id"
                              width="80%"
                              :placeholder="this.$i18n.t('user_roles.select_department_part')"
                            >
                              <template #content="{ data }">
                                <DxDataGrid
                                  :data-source="all_roles_datasource"
                                  :columns="RolesListColumns"
                                  :hover-state-enabled="true"
                                  v-model:selected-row-keys="gridBoxValue"
                                  @selection-changed="changeDropDownBoxValue($event)"
                                  height="100%"
                                  ref="grid_roles"
                                >
                                  <DxSelection mode="single"/>
                                  <DxPaging
                                    :enabled="false"
                                  />  
                                  <DxFilterRow :visible="true"/>
                                  <DxScrolling mode="standard" :use-native="true"/>
                                  <template #radif_1="{ data }">
                                    {{data.rowIndex + 1}}
                                  </template>
                                </DxDataGrid>
                              </template>
                            </DxDropDownBox>                           
                      </div>
                    </div>                    
                    <users_compo :title="this.$i18n.t('user_roles.select_user_info')" uid="" username="" @UserSelected="GetUserSelected"></users_compo>
                  </div>
                                
                <!-- <button class="btn btn-success mt-3 me-1 ms-2" @click="saveNewPart"><i class="fa fa-home"></i>ذخیره</button>  -->
                <button class="btn btn-warning mt-3" @click="setDialoSetRoleToUserClose"><i class="fa fa-home"></i>{{ $t('user_roles.btn_back') }}</button>
              </form>

              <div class="card-footer text-muted mt-2">{{ $t('user_roles.dialog_new_footer_label') }}</div>
            </div>
          </div>
             
         </div>
       </DxScrollView>
    </template>
    </DxPopup>



    


    <customDial :show="showDeleteDialog"
          :cancel="() => { this.showDeleteDialog=false;}"
          :confirm="delPartM"
          :title="this.$i18n.t('user_roles.delete_dialog_title')"
          :userPersonalInfo="RoleNameSelected"
          :description="this.$i18n.t('user_roles.delete_dialog_body')" />

    </div>
</template>
<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxForm, DxItem } from 'devextreme-vue/form';  
import { DxPopup } from 'devextreme-vue/popup';
import { DxDataGrid,DxSelection } from 'devextreme-vue/data-grid';
import { computed } from '@vue/reactivity';
import {dxGrid} from '@/components/dxGrid'
import DxButton from 'devextreme-vue/button';
import { onMounted, reactive,ref } from 'vue';
import { mapGetters } from 'vuex';
import {useStore} from "vuex";
import MyValidator from '@/components/MyValidator'
import notify from 'devextreme/ui/notify';
import customDial from '@/components/customDialog.vue'
import themes from 'devextreme/ui/themes';
import DxDropDownButton from 'devextreme-vue/drop-down-button';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { DxDropDownBox } from 'devextreme-vue/drop-down-box';
import users_compo from '@/components/users_compo.vue'
import responsives from '@/utils/responsives';


export default {
    setup(){
        const store = useStore();
        return{
            store,
            user_roles_datasource: computed(() => store.getters['roles/getUserRoles']),
            all_roles_datasource: computed(() => store.getters['roles/getBaseRoles']),
        }
    },
    mixins:[dxGrid,MyValidator,responsives],
    components: {
        DxButton,
        DxDataGrid,
        DxPopup,
        DxForm,
        DxSelectBox,
        DxDropDownButton,
        DxScrollView,
        DxDropDownBox,
        users_compo,
        customDial,
     },
    data(){
        return{   
          selectedDropDownRoleText:'',
          selectedDropDownRole:{},
          showDeleteDialog:false,
          RoleNameSelected:'',
          row_Roles_Selected:{},
          gridBoxValue: [3],
          RolesListColumns:[
                // { cellTemplate: "radif_1", caption: "ردیف", dataType: 'number', allowFiltering: false},
                { dataField: 'id', caption: this.$i18n.t('user_roles.grid_roles_id'), dataType: 'number',visible:false},
                { dataField: 'nameFa', caption: this.$i18n.t('user_roles.grid_roles_nameFa'), dataType: 'string'},
                { dataField: 'nameEn', caption: this.$i18n.t('user_roles.grid_roles_nameEn'), dataType: 'string'},
          ],
          isDropDownBoxOpened:false,
          selectedPart:{},
          selectedPartText:'',
          allMode: 'allPages',
          checkBoxesMode: themes.current().startsWith('material') ? 'always' : 'onClick',
          showDialogSaveRoleToUser:false,       
          user_roles_columns:[
            { cellTemplate: "radif", caption: this.$i18n.t('user_roles.grid_row_radif'), dataType: 'number', allowFiltering: false,width: 50, alignment: "center"},
            // { cellTemplate: 'actions', caption: 'عملیات', allowFiltering: false, allowSorting: false },
            { dataField: 'userId', caption: this.$i18n.t('user_roles.grid_row_userId'), dataType: 'string',alignment:'center',visible:false },
            { dataField: 'userInfo', caption: this.$i18n.t('user_roles.grid_row_userInfo'), dataType: 'string' ,alignment:'center',format: { type: "fixedPoint",  precision: 0 }},
            { dataField: 'userName', caption: this.$i18n.t('user_roles.grid_row_userName'), dataType: 'string' ,alignment:'center' },
            { dataField: 'roleId', caption: this.$i18n.t('user_roles.grid_row_roleId'), dataType: 'string',alignment:'center',visible:false },
            { dataField: 'roleName', caption: this.$i18n.t('user_roles.grid_row_roleName'), dataType: 'string',alignment:'center' },
            { dataField: 'roleNameFa', caption: this.$i18n.t('user_roles.grid_row_roleNameFa'), dataType: 'string',alignment:'center' },
            { dataField: 'permissionId', caption: this.$i18n.t('user_roles.grid_row_permissionId'), dataType: 'string',alignment:'center',visible:false },
            { dataField: 'permissionTitle', caption: this.$i18n.t('user_roles.grid_row_permissionTitle'), dataType: 'string',alignment:'center' },
            { dataField: 'permissionTitleEn', caption: this.$i18n.t('user_roles.grid_row_permissionTitleEn'), dataType: 'string',alignment:'center' },
            { dataField: 'RolePermissionId', caption: this.$i18n.t('user_roles.grid_row_RolePermissionId'), dataType: 'string',alignment:'center' },
          ],
          Dropactions:  [
                { id: 1, text: this.$i18n.t('user_roles.drop_down_toolbar_new_item'), icon: "user" },
                { id: 2, text: this.$i18n.t('user_roles.drop_down_toolbar_new_reload'), icon: "refresh" },
                { id: 3, text: this.$i18n.t('user_roles.drop_down_toolbar_new_back'), icon: "undo" },                
                { id: 4, text: "تنظیمات1", icon: "preferences" },
                { id: 5, text: "تنظیمات2", icon: "preferences" },
                { id: 6, text: "تنظیمات3", icon: "preferences" },
            ],
        }       
    },  
    watch: {
    '$i18n.locale': function (newVal, oldVal) {
      debugger

      const dataGrid = this.$refs.main_grid.instance;
      this.$refs.main_grid.columns[0].caption = this.$i18n.t('user_roles.grid_row_radif');
      this.$refs.main_grid.columns[1].caption = this.$i18n.t('user_roles.grid_row_userId');
      this.$refs.main_grid.columns[2].caption = this.$i18n.t('user_roles.grid_row_userInfo');
      this.$refs.main_grid.columns[3].caption = this.$i18n.t('user_roles.grid_row_userName');
      this.$refs.main_grid.columns[4].caption = this.$i18n.t('user_roles.grid_row_roleId');
      this.$refs.main_grid.columns[5].caption = this.$i18n.t('user_roles.grid_row_roleName');
      this.$refs.main_grid.columns[6].caption = this.$i18n.t('user_roles.grid_row_roleNameFa');
      this.$refs.main_grid.columns[7].caption = this.$i18n.t('user_roles.grid_row_permissionId');
      this.$refs.main_grid.columns[8].caption = this.$i18n.t('user_roles.grid_row_permissionTitle');
      this.$refs.main_grid.columns[9].caption = this.$i18n.t('user_roles.grid_row_permissionTitleEn');
      this.$refs.main_grid.columns[10].caption = this.$i18n.t('user_roles.grid_row_RolePermissionId');

      dataGrid.option('columns', dataGrid.option('columns'));




      if (this.$refs.grid_roles != null) {
        const grid_roles_holder = this.$refs.grid_roles.instance;
        this.$refs.grid_roles.columns[0].caption = this.$i18n.t('user_roles.grid_roles_id');
        this.$refs.grid_roles.columns[1].caption = this.$i18n.t('user_roles.grid_roles_nameFa');
        this.$refs.grid_roles.columns[2].caption = this.$i18n.t('user_roles.grid_roles_nameEn');
        grid_roles_holder.option('columns', dataGrid_customer_company.option('columns'));
      }
      
    }
  }, 
    created(){        
      this.reloadData();
    },
    methods:{
          GetUserSelected(data){
            debugger
            if(this.selectedDropDownRole === undefined || 
            this.selectedDropDownRole === null || this.selectedDropDownRole.id === undefined)
            {
              notify(this.$i18n.t('user_roles.validation_pre_user_assing_role'),'warning',this.notifyTimeSet);
              return;
            }
              debugger
              const holder_data = {
                UserId:data.id,
                RoleID:this.selectedDropDownRole.id
              }
              this.store.dispatch('roles/addRoleUser',holder_data);
          },
          setDialoSetRoleToUserClose(){
            this.showDialogSaveRoleToUser = false;
            this.reloadData();
          },  
          gridBoxDisplayExpr() {                
                return this.selectedDropDownRoleText;
          },         
          changeDropDownBoxValue(e){
            debugger
            this.selectedDropDownRole = e.currentSelectedRowKeys[0];
            this.isDropDownBoxOpened=false;
            if(this.selectedDropDownRole != undefined)
              this.selectedDropDownRoleText = this.selectedDropDownRole.nameFa;
          },
          setRoleToUsersDialog(){
            this.makeInitSize(600,560);
            this.reloadDataRoles();
            this.showDialogSaveRoleToUser=true;
          },
          reloadData(){
            const param = {
              uid : null,
              role_id: null,
              permission_id: null,
            }
            this.store.dispatch('roles/getUserRoles',param);
          },
          reloadDataRoles(){         
            this.store.dispatch('roles/getAllRoles');
          },
          onItemClick(e) {
            debugger          
              switch (e.itemData.id) {
                case 1:
                  this.showDialog=true;
                  break;
                case 2:
                  this.reloadData();
                  break;
                case 3:
                 this.$router.go(-1);
                  break;
                default:
                  notify('خطای ناشناس در منو','warning',this.notifyTimeSet);
                  break;
              }
            },
            onSelectionChanged (selectedRowsData)  { 
              debugger                                       
              this.row_Roles_Selected = selectedRowsData.selectedRowsData[0]; 
              if(this.row_Roles_Selected != undefined)
                this.RoleNameSelected = this.row_Roles_Selected.roleNameFa;             
            },
            onToolbarPreparing (e) {
                    e.toolbarOptions.items.unshift({
                        location: "before",
                        template: "btnRGroup",
                    });
                    e.toolbarOptions.items.unshift({
                        location: "after",
                        template: "btnGroup",
                    });                
              },
    },

}
</script>
<style lang="scss">
@import "@/dx-styles.scss";
.dx-popup-wrapper>.dx-overlay-content {
  background: #fff;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border-left-color: $popup_color;
  border-left-width: 9px;
}
#ps_Table {
  height: 60vh;
}
</style>