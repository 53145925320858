export default {
    data(){        
        return{
            showDialogNewWidth:600,
            showDialogNewHeight:670,
          
        }
    },    
    methods:{
        makeInitSize(width_sent,hieght_sent){
            this.showDialogNewWidth = width_sent
            this.showDialogNewHeight = hieght_sent;
        },
        makeLargeScreen(){
            debugger
            this.showDialogNewWidth = window.innerWidth-150;
            this.showDialogNewHeight = window.innerHeight-100;
        },
        makeMediumScreen(){
            debugger
            this.showDialogNewWidth = 1000;//window.innerWidth-1000;
            this.showDialogNewHeight = 600//window.innerHeight-150;
        },
        makeSmallScreen(){
            debugger
            this.showDialogNewWidth = 600;
            this.showDialogNewHeight = 500;
        }

    }
}