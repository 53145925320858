<template>
    <div class="main-saved-appoints dx-card ">
        <DxDataGrid :data-source="saved_reservations" :columns="gridColumns" :ref="dataGridRefUsers"
            :show-row-lines="showRowLines" :show-column-lines="showColumnLines" :row-alternation-enabled="true"
            :rtl-enabled="true" :remote-operations="true" :column-auto-width="true" :allow-column-reordering="true"
            :allow-column-resizing="true" :hover-state-enabled="true" :noDataText="checkData"
            :selection="{ mode: 'single' }" @toolbar-preparing="onToolbarPreparing" @selection-changed="onSelectionChanged"
            class="mt-3" id="ps_Table" key-expr="id">

            <DxColumnChooser :enabled="true" />
            <DxExport :enabled="allowExportDataGrid" :allow-export-selected-data="allowExportSelectedDataGrid" />
            <DxSelection mode="single" />

            <DxHeaderFilter :visible="showHeaderFilter" />
            <DxSearchPanel :visible="true" :width="190" :placeholder="this.$i18n.t('users.grid_search')" class="" />

            <DxSelection :select-all-mode="allMode" :show-check-boxes-mode="checkBoxesMode" mode="multiple" />

            <template #btnGroup class="row">
                <div class="d-lg-none">
                    <div class="mt-2">
                        <DxDropDownButton :items="Dropactions" :drop-down-options="{ width: 190 }"
                            :text="this.$i18n.t('users.drop_down_toolbar')" icon="save" @item-click="onItemClick" />
                    </div>
                </div>
                <div class="d-none d-lg-block">
                    <!-- <DxButton class="ml-2 bg-success" color="success" size="sm" icon="plus"
                :hint="this.$i18n.t('users.drop_down_toolbar_new_item')" @click="NewUserPopup()">
              </DxButton> -->
                    <DxButton text="نوبت جدید" type="default" :width="136" @click="gotoNewAppointment" />
                    <DxButton color="secondary" class="ml-2" size="sm" icon="revert"
                        :hint="this.$i18n.t('users.drop_down_toolbar_new_reload')" @click="$router.go(-1)">
                    </DxButton>
                    <DxButton color="light" size="sm" icon="refresh" @click="reloadData"
                        :hint="this.$i18n.t('users.drop_down_toolbar_new_back')">
                    </DxButton>
                </div>
            </template>


            <template #btnRGroup class="p-0 m-0">
                <div class="d-none d-lg-block">
                    <div class="row">
                        <div class="col-sm-4">
                            <button class="btn btn-sm mr-1 bg-warning" size="sm">{{ $t('users.drop_down_toolbar')
                            }}</button>
                        </div>
                        <div class="col-sm-4">
                            <button class="btn btn-sm mr-1 bg-dark text-white" size="sm">{{
                                $t('users.drop_down_toolbar')
                            }}</button>
                        </div>
                        <div class="col-sm-4">
                            <button class="btn btn-sm mr-1 bg-success" size="sm">{{ $t('users.drop_down_toolbar')
                            }}</button>
                        </div>
                    </div>
                </div>

            </template>

            <template #actions="{ data }">
                <div>
                    <DxButton class="bg-warning m-1" icon="edit" type="edit" hint="ویرایش" @click="setEdit(data)">
                    </DxButton>
                    <DxButton class="bg-danger m-1" icon="remove" hint="حذف" @click="showDeleteDialog(data)">
                    </DxButton>
                </div>
            </template>
            <template #radif="{ data }">
                {{ data.rowIndex + 1 }}
            </template>
            <DxPaging :enabled="false" />
            <DxFilterRow :visible="true" />
            <DxLoadPanel :enabled="true" />
            <DxScrolling mode="standard" :use-native="true" />
        </DxDataGrid>
        <!-- <DxDataGrid id="dataGrid" :data-source="savedAppontments" :columns="gridColumns" :allow-column-reordering="true"
            :column-auto-width="true" key-expr="id" :allowExportSelectedData="true" @exporting="onExporting">

            <DxColumnChooser :enabled="true" />
            <DxFilterRow :visible="true" />
            <DxSearchPanel :visible="true" />
            <DxGroupPanel :visible="true" />
            <DxSelection mode="multiple" />
            <DxSummary>
                <DxGroupItem summary-type="count" />
            </DxSummary>
            <DxToolbar>
                <DxItem name="groupPanel" />
                <DxItem location="after" template="button-template" />
                <DxItem name="addRowButton" show-text="always" />
                <DxItem name="exportButton" />
                <DxItem name="columnChooserButton" />
                <DxItem name="searchPanel" />
            </DxToolbar>

            <DxExport :enabled="true" :formats="['xlsx', 'pdf']" />
            <DxExport :enabled="true" />

            <template #button-template>
                <DxButton text="نوبت جدید" type="default" :width="136" @click="gotoNewAppointment" />
            </template>
        </DxDataGrid> -->

        <customDial :show="showUserDeleteDialog" :cancel="() => { this.showUserDeleteDialog = false; }"
            :confirm="delReservation" title="حذف نوبت"
            :userPersonalInfo="fullDeleteTitle" description="آیا برای حذف نوبت انتخاب شده با مشخصات بالا مطمئن هستید؟" />


            

    </div>
</template>

<script>
import {
    DxDataGrid, DxColumnChooser, DxFilterRow,
    DxSearchPanel, DxGroupPanel, DxSelection,
    DxSummary,
    DxGroupItem, DxToolbar,
    DxItem, DxExport

} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
// import { saveAs } from 'file-saver-es';
import { saveAs } from 'file-saver';

import { exportDataGrid } from 'devextreme/excel_exporter';
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';
import customDial from '@/components/customDialog.vue'
import { DxPopup } from 'devextreme-vue/popup';



export default {
    name: 'savedAppointments',
    setup() {
        const store = useStore();
        return {
            store,
            saved_reservations: computed(() => store.getters['reservations/getReservesList'])
        }
    },
    components: {
        DxDataGrid, DxColumnChooser, DxFilterRow,
        DxSearchPanel, DxGroupPanel, DxSelection,
        DxSummary,
        DxGroupItem, DxToolbar,
        DxItem, DxButton,
        DxExport,
        customDial,
        DxPopup
    },
    data() {
        return {
            selectedReservation: null,
            showUserDeleteDialog: false,
            fullDeleteTitle: '',

            gridColumns: [
                { cellTemplate: "radif", caption: 'ردیف', dataType: 'number', allowFiltering: false, width: 75, alignment: "center" },
                { cellTemplate: 'actions', caption: 'عملیات', allowFiltering: false, allowSorting: false },
                {
                    dataField: "id",
                    caption: "شناسه",
                    visible: false
                },
                {
                    dataField: "doctorInfo",
                    caption: "مشخصات دکتر"
                },
                {
                    dataField: "patientInfo",
                    caption: "مشخصات بیمار"
                },
                {
                    dataField: "title",
                    caption: "درمان"
                },
                {
                    dataField: "subWorkTitle",
                    caption: "زیر درمان"
                },
                {
                    dataField: "reservationDate",
                    caption: "تاریخ نوبت"
                },
                {
                    dataField: "reservationTime",
                    caption: "زمان نوبت"
                },
                {
                    dataField: "reservationDescription",
                    caption: "توضیحات",
                    visible: false
                },
                {
                    dataField: "reservationStatus",
                    caption: "وضعیت",
                    visible: false
                },
                {
                    dataField: "dateSaved",
                    caption: "زمان ذخیره",
                    visible: false
                },
            ],

        }
    },
    created() {
        this.reloadData();
    },
    methods: {
        delReservation() {
            debugger
            this.store.dispatch('reservations/deleteReservation', this.selectedReservation.id);
            this.showUserDeleteDialog=false
            setTimeout(() => {
                this.reloadData();
            }, 500)
        },
        setEdit(data_sent) {
            debugger
            this.selectedReservation = data_sent.data;
            this.$router.push({name:'newAppointmentEdit',params:{id:this.selectedReservation.id}})
        },
        showDeleteDialog(data_sent) {
            this.selectedReservation = data_sent.data;
            this.fullDeleteTitle = data_sent.data.patientInfo;
            this.showUserDeleteDialog = true;
        },
        reloadData() {
            this.store.dispatch('reservations/getReserveList');
        },
        gotoNewAppointment() {
            this.$router.push({ name: 'newAppointment' });
        },
        onToolbarPreparing(e) {
            e.toolbarOptions.items.unshift({
                location: "before",
                template: "btnRGroup",
            });
            e.toolbarOptions.items.unshift({
                location: "after",
                template: "btnGroup",
            });
        },
        onExporting(e) {
            if (e.format === 'xlsx') {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('Main sheet');
                exportDataGrid({
                    component: e.component,
                    worksheet: worksheet,
                    customizeCell: function (options) {
                        options.excelCell.font = { name: 'Arial', size: 12 };
                        options.excelCell.alignment = { horizontal: 'left' };
                    }
                }).then(function () {
                    workbook.xlsx.writeBuffer()
                        .then(function (buffer) {
                            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
                        });
                });
            }
            else if (e.format === 'pdf') {
                const doc = new jsPDF();
                exportDataGridToPdf({
                    jsPDFDocument: doc,
                    component: e.component
                }).then(() => {
                    doc.save('Companies.pdf');
                })
            }

        },
    }
}
</script>

<style lang="scss" scoped>
.main-saved-appoints {
    margin: 10px;
}
</style>