export default {
    setUser_edms_chart(state, data) {
        state.user_edms_chart = data;
    },
    setUser_uploads_chart(state, data) {
        state.user_uploads_chart = data;
    },
    setUser_uploads_states_chart(state, data) {
        state.user_uploads_states_chart = data;
    },
    setUser_comments_chart(state, data) {
        state.user_comments_chart = data;
    },
    //sale
    setTotal_uploads_chart(state, data) {
        state.total_uploads_chart = data;
    },
    setTotal_uploads_states_chart(state, data) {
        state.total_uploads_states_chart = data;
    },
    setTotal_comments_chart(state, data) {
        state.total_comments_chart = data;
    },
    setActive_Reminders(state, data) {
        state.active_reminders = data;
    },
    setActive_7Day_passed_reminders(state, data) {
        // debugger
        const holder_data = [
            { id: 1, text: "فایل های تاریخ نیاز : "+data.length, icon: "event",count:data.length },
            { id: 1, text: "فایل های بررسی نشده : 0", icon: "upload",count:0 },
            { id: 2, text: "پیغام ها : 0", icon: "email" ,count:0},
        ]

        // const holder_data = [
        //     { id: 1, text: this.i18n.t('notify_bar.date_expiring')+data.length, icon: "event",count:data.length },
        //     { id: 1, text: this.i18n.t('notify_bar.chart_edms_type_title'), icon: "upload",count:0 },
        //     { id: 2, text: this.i18n.t('notify_bar.chart_edms_type_title'), icon: "email" ,count:0},
        // ]

        state.active_7Day_passed_reminders_count = data.length;
        state.active_unread_messages_count=0;
        state.active_7Day_passed_reminders = data;
        state.active_7Day_passed_reminders_toolbar_items = holder_data;
    },
    setUsers_last(state,data)
    {
        state.users_last = data;
    }
}