<template>
    <div>
      <h2 class="content-block">{{ $t('company_parts.title') }}</h2>
      <div class="content-block">
        <div class="dx-card responsive-paddings">          
                <DxDataGrid
                    :data-source="parts_info"
                    :columns="parts_colomuns"

                    :show-row-lines="showRowLines"
                    :show-column-lines="showColumnLines"
                    :row-alternation-enabled="true"
                    :rtl-enabled="true"
                    :remote-operations="true"
                    :column-auto-width="true"
                    :allow-column-reordering="true"
                    :allow-column-resizing="true"
                    :hover-state-enabled="true"
                    :noDataText="checkData"

                    :selection="{ mode: 'single' }"

                    @toolbar-preparing="onToolbarPreparing"
                    @selection-changed="onSelectionChanged"

                    class="mt-3"
                    id="ps_Table"

                    ref="main_grid"

                    key-expr="id" >
                    
                    <DxColumnChooser :enabled="true"/>
                    <DxExport
                      :enabled="allowExportDataGrid"
                      :allow-export-selected-data="allowExportSelectedDataGrid"
                    />

                    <DxHeaderFilter
                      :visible="showHeaderFilter"
                    />
                    <DxSearchPanel
                    :visible="true"
                    :width="190"
                    :placeholder="this.$i18n.t('company_parts.grid_search')"
                    class=""
                    /> 
                    
                    <DxSelection
                      :select-all-mode="allMode"
                      :show-check-boxes-mode="checkBoxesMode"
                      mode="multiple"
                    />
                   
                    <template #btnGroup class="row">
                        <div class="d-lg-none">                   
                            <div class="mt-2">
                                <DxDropDownButton
                                    :items="Dropactions"
                                    :drop-down-options="{ width: 190 }"
                                    :text="this.$i18n.t('company_parts.drop_down_toolbar')"
                                    icon="save"
                                    @item-click="onItemClick"
                                />
                            </div>                
                        </div>
                      <div class="d-none d-lg-block">
                        <DxButton
                        class="ml-2 bg-success"
                        color="success"
                        size="sm"
                        icon="plus"
                        @click="NewPartPoput()">
                        </DxButton>
                        <DxButton
                        color="secondary"
                        class="ml-2"
                        size="sm"
                        icon="revert"
                        @click="$router.go(-1)">
                        </DxButton>
                        <DxButton
                        color="light"
                        size="sm"
                        icon="refresh"
                        @click="reloadData"
                    
                            >
                        </DxButton>
                        </div>
                     </template>


                     <template #btnRGroup class="p-0 m-0">                     
                        <div class="d-none d-lg-block">
                          <div class="row">
                              <div class="col-sm-12">
                                  <Button @click="accessUserParts" class="btn w-100 btn-sm mr-1 bg-warning" size="sm"  >{{ $t('company_parts.assign_users_to_departmetn') }}</Button> 
                              </div>
                              <!-- <div class="col-sm-4">
                                  <Button class="btn btn-sm mr-1 bg-dark text-white" size="sm"  >تنظیمات2</Button> 
                              </div>
                              <div class="col-sm-4">
                                  <Button class="btn btn-sm mr-1 bg-success" size="sm"  >تنظیمات3</Button> 
                              </div> -->
                          </div> 
                        </div>
                                  
                    </template>
                    <template #actions="{ data }">
                      <div>                        
                        <DxButton
                        class="bg-warning m-1"
                        icon="edit"
                        type="edit" 
                        :title="this.$i18n.t('company_parts.grid_row_btn_edit')"
                        @click="setEdit(data)">
                        </DxButton>
                        <DxButton
                        class="bg-danger m-1"
                        icon="remove"
                        :title="this.$i18n.t('company_parts.grid_row_btn_delete')"
                        @click="setDeleteDialog"
                        >
                        </DxButton>
                      </div>
                </template>
                <template #radif="{ data }">
                  {{data.rowIndex + 1}}
                </template>
                <DxFilterRow :visible="true" />
                <DxLoadPanel :enabled="true" />
                <DxScrolling mode="standard" :use-native="true"/>
            </DxDataGrid>
        
        </div>
      </div>

      
      <DxPopup
      style="text-align: :center;"
      :width="this.showDialogNewWidth"
      :height="this.showDialogNewHeight"
      :show-title="true"
      :title="this.$i18n.t('company_parts.parts_info_form')"
      alignment="center"
      :drag-enabled="true"
      :hide-on-outside-click="false"
      v-model:visible="showDialog"
    >
      <template #content="{ data }">
        <DxScrollView
            width="100%"
            height="100%"
          >
        <div class="popup-property-details">

          <div class="card">
            <!-- <h5 class="card-header">فرم اطلاعات کاربر</h5> -->
            <div class="card-body">              
                <form>

                   <!-- Height-Width-Controller -->
                   <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                        <div class="d-flex flex-row">
                            <div class="">
                              <div class="form-group input-group">
                                  <div class="input-group-prepend">
                                      <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png" class="responseModels m-1"/>
                                  </div>
                              </div>                      
                            </div>
                            <div class="">
                              <div class="form-group input-group">
                                  <div class="input-group-prepend">
                                    <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png" class="responseModels m-1"/>
                                  </div>
                              </div>                      
                            </div>
                            <div class="">
                              <div class="form-group input-group">
                                  <div class="input-group-prepend">
                                    <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png" class="responseModels m-1"/>
                                  </div>
                              </div>                      
                            </div>
                        </div>
                      </div>
                  </div>
                  <!-- Height-Width-Controller -->

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <font-awesome-icon icon="fas fa-user" class="m-1"/> </span>
                        </div>
                          <input v-model="NewPartName" 
                          @input="this.check_content($event,'inputName')"   
                          :class="{'border-danger-my':!this.isStateOk('inputName'),'border-success-my':this.isStateOk('inputName'),'rounded':true}"
                          :placeholder="this.$i18n.t('company_parts.deparment_title')" type="text" >
                      </div>
                    </div>                  
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <font-awesome-icon icon="fas fa-user" class="m-1"/> </span>
                        </div>
                          <input v-model="NewPartNameEn" 
                          @input="this.check_content($event,'inputNameEn')"   
                          :class="{'border-danger-my':!this.isStateOk('inputNameEn'),'border-success-my':this.isStateOk('inputNameEn'),'rounded':true}"
                          :placeholder="this.$i18n.t('company_parts.deparment_code')" type="text" >
                      </div>
                    </div>                  
                  </div>
      
                <button v-if="editMode" class="btn btn-success mt-3 me-1 ms-2" @click="editPartInfo($event)"><i class="fa fa-home"></i>{{ $t('company_parts.dialog_btn_edit') }}</button> 
                <button v-else class="btn btn-success mt-3 me-1 ms-2" @click="saveNewPart"><i class="fa fa-home"></i>{{ $t('company_parts.dialog_btn_save') }}</button> 
                <button class="btn btn-warning mt-3" @click="this.showDialog = false;"><i class="fa fa-home"></i>{{ $t('company_parts.dialog_btn_back') }}</button>
              </form>

              <div class="card-footer text-muted mt-2">{{ $t('company_parts.dialog_new_footer_label') }}</div>
            </div>
          </div>
             
         </div>
       </DxScrollView>
    </template>
    </DxPopup>



    <DxPopup
      style="text-align: :center;"
      :width="this.showDialogNewWidth"
      :height="this.showDialogNewHeight"
      :show-title="true"
      :title="this.$i18n.t('company_parts.User_separtment_Assignment_Dorm')"
      alignment="center"
      :drag-enabled="true"
      :hide-on-outside-click="false"
      v-model:visible="showDialogPartUser"
    >
      <template #content="{ data }">
        <DxScrollView
            width="100%"
            height="100%"
          >
        <div class="popup-property-details">

          <div class="card">
            <!-- <h5 class="card-header">فرم اطلاعات کاربر</h5> -->
            <div class="card-body">
              
                <form>

                   <!-- Height-Width-Controller -->
                   <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                        <div class="d-flex flex-row">
                            <div class="">
                              <div class="form-group input-group">
                                  <div class="input-group-prepend">
                                      <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png" class="responseModels m-1"/>
                                  </div>
                              </div>                      
                            </div>
                            <div class="">
                              <div class="form-group input-group">
                                  <div class="input-group-prepend">
                                    <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png" class="responseModels m-1"/>
                                  </div>
                              </div>                      
                            </div>
                            <div class="">
                              <div class="form-group input-group">
                                  <div class="input-group-prepend">
                                    <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png" class="responseModels m-1"/>
                                  </div>
                              </div>                      
                            </div>
                        </div>
                      </div>
                  </div>
                  <!-- Height-Width-Controller -->

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <font-awesome-icon icon="fas fa-user" class="m-3"/> </span>
                        </div>
                          <DxDropDownBox
                              v-model:value="gridBoxValue"
                              v-model:opened="isDropDownBoxOpened"
                              :defer-rendering="false"
                              :display-expr="gridBoxDisplayExpr"
                              :show-clear-button="true"
                              :data-source="partsListDataSource"
                              value-expr="id"
                              width="80%"
                              :placeholder="this.$i18n.t('company_parts.validation_part_select')"
                            >
                              <template #content="{ data }">
                                <DxDataGrid
                                  :data-source="partsListDataSource"
                                  :columns="partsListColumns"
                                  :hover-state-enabled="true"
                                  v-model:selected-row-keys="gridBoxValue"
                                  @selection-changed="changeDropDownBoxValue($event)"
                                  height="100%"
                                  ref="part_grid"
                                >
                                  <DxSelection mode="single"/>
                                  <DxPaging
                                    :enabled="false"
                                  /> 
                                  <DxFilterRow :visible="true"/>
                                  <DxScrolling mode="standard" :use-native="true"/>
                                  <DxPaging
                                    :enabled="false"
                                  /> 
                                  <template #radif_1="{ data }">
                                    {{data.rowIndex + 1}}
                                  </template>
                                </DxDataGrid>
                              </template>
                            </DxDropDownBox>                           
                      </div>
                    </div>                    
                    <users_compo :title="this.$i18n.t('company_parts.validation_user_selection')" uid="" username="" @UserSelected="GetUserSelected"></users_compo>
                  </div>
                                
                <!-- <button class="btn btn-success mt-3 me-1 ms-2" @click="saveNewPart"><i class="fa fa-home"></i>ذخیره</button>  -->
                <button class="btn btn-warning mt-3" @click="setDialoPartUserClose"><i class="fa fa-home"></i>{{ $t('company_parts.dialog_btn_back_2') }}</button>
              </form>

              <div class="card-footer text-muted mt-2">{{ $t('company_parts.dialog_new_footer_label_2') }}</div>
            </div>
          </div>
             
         </div>
       </DxScrollView>
    </template>
    </DxPopup>


    <customDial :show="showDeleteDialog"
          :cancel="() => { this.showDeleteDialog=false;}"
          :confirm="delPartM"
          :title="this.$i18n.t('company_parts.delete_dialog_title')"
          :userPersonalInfo="partNameSelected"
          :description="this.$i18n.t('company_parts.delete_dialog_body')" />

    </div>
</template>
<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxForm, DxItem } from 'devextreme-vue/form';  
import { DxPopup } from 'devextreme-vue/popup';
import { DxDataGrid,DxSelection } from 'devextreme-vue/data-grid';
import {dxGrid} from '@/components/dxGrid'
import DxButton from 'devextreme-vue/button';
import { mapGetters, useStore } from 'vuex';
import MyValidator from '@/components/MyValidator'
import notify from 'devextreme/ui/notify';
import customDial from '@/components/customDialog.vue'
import themes from 'devextreme/ui/themes';
import { computed } from '@vue/reactivity';
import DxDropDownButton from 'devextreme-vue/drop-down-button';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import users_compo from '@/components/users_compo.vue'
import { DxDropDownBox } from 'devextreme-vue/drop-down-box';
import responsives from '@/utils/responsives';


export default {
    setup(){
        const store = useStore();
        return{
            store,
            parts_info: computed(() => store.getters['parts/getParts']),
            company_info: computed(() => store.getters['company/getCompany']),
            partsListDataSource : computed( () => store.getters['parts/getParts']),
        }
    },
    mixins:[dxGrid,MyValidator,responsives],
    components: {
        DxButton,
        DxDataGrid,
        DxPopup,
        DxForm,
        DxItem,
        customDial,
        DxSelectBox,
        DxDropDownButton,
        DxScrollView,
        users_compo,
        DxDropDownBox,
     },
    data(){
        return{          
          gridBoxValue: [3],
          partsListColumns:[
                { cellTemplate: "radif_1", caption: this.$i18n.t('company_parts.grid_row_part_list_radif_1'), dataType: 'number', allowFiltering: false},
                { dataField: 'id', caption: this.$i18n.t('company_parts.grid_row_part_list_id'), dataType: 'number',visible:false},
                { dataField: 'partTitle', caption: this.$i18n.t('company_parts.grid_row_part_list_partTitle'), dataType: 'string'},
          ],
          isDropDownBoxOpened:false,
          selectedPart:{},
          selectedPartText:'',
          showDialogPartUser:false,
          editMode:false,
          partNameSelected:'',
          row_Part_Selected:{},
          showDeleteDialog:false,
          NewPartName:'',
          NewPartNameEn:'',
          showDialog:false,
          Dropactions:  [
                { id: 1, text: this.$i18n.t('company_parts.drop_down_toolbar_new_item'), icon: "user" },
                { id: 2, text: this.$i18n.t('company_parts.drop_down_toolbar_new_reload'), icon: "refresh" },
                { id: 3, text: this.$i18n.t('company_parts.drop_down_toolbar_new_back'), icon: "undo" },                
                { id: 4, text: this.$i18n.t('company_parts.drop_down_assign_user_to_departmetn'), icon: "preferences" },
                { id: 5, text: "تنظیمات2", icon: "preferences" },
                { id: 6, text: "تنظیمات3", icon: "preferences" },
            ],
            parts_colomuns:[
                    { cellTemplate: "radif", caption: this.$i18n.t('company_parts.grid_row_parts_radif'), dataType: 'number', allowFiltering: false},
                    { dataField: 'id', caption: this.$i18n.t('company_parts.grid_row_parts_id'), dataType: 'number',visible:false},
                    { dataField: 'partTitle', caption: this.$i18n.t('company_parts.grid_row_parts_partTitle'), dataType: 'string'},
                    { dataField: 'partTitleDesc', caption: this.$i18n.t('company_parts.grid_row_parts_partTitleDesc'), dataType: 'string'},
                    { dataField: 'count', caption: this.$i18n.t('company_parts.grid_row_parts_count'), dataType: 'string'},          
                    { cellTemplate: 'actions', caption: this.$i18n.t('company_parts.grid_row_parts_actions'), allowFiltering: false, allowSorting: false }
            ]
        }       
    },   
    watch: {
    '$i18n.locale': function (newVal, oldVal) {
      debugger

      const dataGrid = this.$refs.main_grid.instance;
      this.$refs.main_grid.columns[0].caption = this.$i18n.t('company_parts.grid_row_parts_radif');
      this.$refs.main_grid.columns[1].caption = this.$i18n.t('company_parts.grid_row_parts_id');
      this.$refs.main_grid.columns[2].caption = this.$i18n.t('company_parts.grid_row_parts_partTitle');
      this.$refs.main_grid.columns[3].caption = this.$i18n.t('company_parts.grid_row_parts_partTitleDesc');
      this.$refs.main_grid.columns[4].caption = this.$i18n.t('company_parts.grid_row_parts_count');
      this.$refs.main_grid.columns[5].caption = this.$i18n.t('company_parts.grid_row_parts_actions');


      dataGrid.option('columns', dataGrid.option('columns'));

      if (this.$refs.part_grid != null) {
        const part_grid_holder = this.$refs.part_grid.instance;
        this.$refs.part_grid.columns[0].caption = this.$i18n.t('company_parts.grid_row_part_list_radif_1');
        this.$refs.part_grid.columns[1].caption = this.$i18n.t('company_parts.grid_row_part_list_id');
        this.$refs.part_grid.columns[2].caption = this.$i18n.t('company_parts.grid_row_part_list_partTitle');
        part_grid_holder.option('columns', dataGrid_customer_company.option('columns'));
      }
      
    }
  },
    created(){        
        this.reloadData();
        this.reloadCompanyData();
        this.make_state_bind('inputName',false,this.$i18n.t('company_parts.validation_part_empty'));
        this.make_state_bind('inputNameEn',false,this.$i18n.t('company_parts.validation_part_title'));
    },
    methods:{
          setDialoPartUserClose(){
            this.showDialogPartUser = false;
            this.reloadData();
          },  
          gridBoxDisplayExpr() {                
                return this.selectedPartText;
          },         
          changeDropDownBoxValue(e){
            debugger
            this.selectedPart = e.currentSelectedRowKeys[0];
            this.isDropDownBoxOpened=false;
            if(this.selectedPart != undefined)
              this.selectedPartText = this.selectedPart.partTitle;
          },
         GetUserSelected (data){ 
            debugger   
            if(this.selectedPart === undefined)
            {
                notify(this.$i18n.t('company_parts.validation_part_selection'),'warning',this.notifyTimeSet);
                return;
            }
            const newData = {
              Company:this.company_info[0].id,
              CompanyPart:this.selectedPart.id,
              UserId:data.id
            }
            this.store.dispatch('parts/addNewPartUser',newData);  
            console.log('GetUserSelected',data)
          },
          accessUserParts(){   
            this.makeInitSize(600,600);
              this.showDialogPartUser=true;
          },
          editPartInfo(){
            debugger
            const holder_part={
              Id:this.row_Part_Selected.id,
              PartTitle:this.NewPartName,
              PartTitleDesc : this.NewPartNameEn
            }
            this.store.dispatch("parts/editPart",holder_part);
            this.showDialog=false;
            setTimeout(()=>{
              this.reloadData();
            },this.reloadTimeSet);
          },
          setEdit(data){
            debugger
            this.makeInitSize(600,380);
            if(this.row_Part_Selected === undefined || this.row_Part_Selected.id === undefined)
            {
              notify(this.$i18n.t('company_parts.validation_pre_edit'),'warning',this.notifyTimeSet);
              return;
            } 
            this.editMode=true;
            this.NewPartName=data.data.partTitle;
            this.NewPartNameEn=data.data.partTitleDesc;
            this.showDialog=true;
            this.make_state('inputName',true);
            this.make_state('inputNameEn',true);
          },
          delPartM(){
            debugger
            this.store.dispatch("parts/deletePart",this.row_Part_Selected.id);
            this.showDeleteDialog=false;
            setTimeout(()=>{
              this.reloadData();
            },this.reloadTimeSet);
          },
          setDeleteDialog(){   
            debugger
            if(this.row_Part_Selected === undefined || this.row_Part_Selected.id === undefined)
            {
              notify(this.$i18n.t('company_parts.validatiion_pre_delete'),'warning',this.notifyTimeSet);
              return;
            }         
            this.showDeleteDialog=true
          },
          saveNewPart(){
            debugger
            if(this.isStateOkTotal() > 0 )
              {      
                let give_me_errors = this.getElementsWithErorrs();        
                notify(give_me_errors,'error',this.notifyTimeSet);
                return;
              } 
            const holder_part={
              Id:null,
              PartTitle:this.NewPartName,
              PartTitleDesc : this.NewPartNameEn
            }
            this.store.dispatch("parts/addNewPart",holder_part);
            this.showDialog=false;
            setTimeout(()=>{
              this.reloadData();
            },this.reloadTimeSet);
          },
          onItemClick(e) {
            debugger          
              switch (e.itemData.id) {
                case 1:
                  this.showDialog=true;
                  break;
                case 2:
                  this.reloadData();
                  break;
                case 3:
                 this.$router.go(-1);
                  break;
                case 4:
                  this.showDialogPartUser = true;
                  break;
                default:
                  notify('خطای ناشناس در منو','warning',this.notifyTimeSet);
                  break;
              }
            },
            onSelectionChanged (selectedRowsData)  {  
              debugger                                      
              this.row_Part_Selected = selectedRowsData.selectedRowsData[0]; 
              this.partNameSelected = this.row_Part_Selected.partTitle;             
            },
            onToolbarPreparing (e) {
                    e.toolbarOptions.items.unshift({
                        location: "before",
                        template: "btnRGroup",
                    });
                    e.toolbarOptions.items.unshift({
                        location: "after",
                        template: "btnGroup",
                    });                
              },
        reloadData(){
            this.store.dispatch("parts/getPartsWithCount");
        },
        reloadCompanyData(){
          debugger
            this.store.dispatch("company/getCmopany");
        },    
        reloadDataParts(){          
            this.store.dispatch("parts/getParts");
        },
        NewPartPoput(){
          this.makeInitSize(600,380);
          this.showDialog=true;
        },
    },

}
</script>
<style lang="scss">
@import "@/dx-styles.scss";
.dx-popup-wrapper>.dx-overlay-content {
  background: #fff;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border-left-color: $popup_color;
  border-left-width: 9px;
}
#ps_Table {
  height: 60vh;
}
</style>