<template>
  <div class="p-1" style="font-family:Samim">
    <DxChart ref="lineChartRef" :data-source="dataSource" palette="Harmony Light" :title="ChartTitle" class=" p-1">

      <DxTitle :text="ChartTitle">
        <DxFont family="Samim" color="darkblue" />
      </DxTitle>
      <DxCommonSeriesSettings family="Samim" argument-field="complaint">
        <DxLabel family="Samim" :visible="true">
          <DxFont family="Samim" color="darkblue" />
        </DxLabel>
      </DxCommonSeriesSettings>
      <DxSeries name=" " value-field="count" axis="frequency" type="bar" color="#ff8b38"
        family="Samim">
        <DxLabel family="Samim" :visible="true" :customize-text="customizeText">
          <DxFont family="Samim" color="white" />
        </DxLabel>
      </DxSeries>  

      <DxArgumentAxis>
        <DxLabel overlapping-behavior="stagger">
          <DxFont family="Samim" color="darkblue" />
        </DxLabel>
      </DxArgumentAxis>

      <DxValueAxis :tick-interval="300" name="frequency" position="left" family="Samim">
        <DxLabel family="Samim" :visible="true">
          <DxFont family="Samim" color="blue" />
        </DxLabel>
      </DxValueAxis>
      <DxValueAxis :tick-interval="20" :show-zero="true" :value-margins-enabled="false" name="percentage"
        position="right">
        <DxLabel :customize-text="customizePercentageText">
          <DxFont family="Samim" color="red" />
        </DxLabel>
        <DxConstantLine :value="80" :width="2" color="#fc3535" dash-style="dash">
          <DxLabel :visible="false">
            <DxFont family="Samim" color="red" />
          </DxLabel>
        </DxConstantLine>
      </DxValueAxis>

      <DxTooltip :enabled="true" :shared="true" :customize-tooltip="customizeTooltip" />

      <DxLegend vertical-alignment="top" horizontal-alignment="center" family="Samim">
        <DxLabel :visible="false">
          <DxFont family="Samim" color="red" />
        </DxLabel>
      </DxLegend>
    </DxChart>
  </div>
</template>
<script>
import DxChart, {
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxLabel,
  DxLegend,
  DxSeries,
  DxTooltip,
  DxValueAxis,
  DxConstantLine,
  DxTitle,
  DxFont
} from "devextreme-vue/chart";

import { line_dataSource } from "./data_line";

export default {
  components: {
    DxChart,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxLabel,
    DxLegend,
    DxSeries,
    DxTooltip,
    DxValueAxis,
    DxConstantLine,
    DxTitle,
    DxFont
  },
  props: {
    ChartTitle: String,
    ChartData: Array,
  },
  data() {
    // const data = line_dataSource.sort((a, b) => b.count - a.count);
    // const totalCount = data.reduce(
    //   (prevValue, item) => prevValue + item.count,
    //   0
    // );
    // let cumulativeCount = 0;

    // const dataSource = data.map((item) => {
    //   cumulativeCount += item.count;
    //   return {
    //     complaint: item.complaint,
    //     count: item.count,
    //     cumulativePercentage: Math.round((cumulativeCount * 100) / totalCount),
    //   };
    // });

    return {
      dataSource: [],
    };
  },
  created() {
    this.calcData();
  },
  methods: {
    refreshChart() {
      debugger
      if (this.$refs.lineChartRef != undefined) {
        this.$refs.lineChartRef.instance.render();
      }
      this.$forceUpdate();
    },
    calcData() {

      if (this.ChartData === null ||
        this.ChartData === undefined ||
        this.ChartData.length <= 0 && this.dataSource.length === 0) {
        setTimeout(() => {
          this.calcData()
        }, 1000)
        return;
      }
      //debugger
      let cumulativeCount = 0;

      cumulativeCount =
        this.ChartData[0].unUploaded +
        this.ChartData[0].inProgress +
        this.ChartData[0].accepted_Holder +
        this.ChartData[0].denied;
      this.dataSource.push({
        // complaint: 'الزام جدید',
        complaint: this.$i18n.t('chart_line.edms_new'),
        count: this.ChartData[0].unUploaded,
        cumulativePercentage: Math.round((this.ChartData[0].unUploaded * 100) / cumulativeCount),
      });

      this.dataSource.push({
        // complaint: 'در حال بررسی',
        complaint: this.$i18n.t('chart_line.edms_in_progress'),
        count: this.ChartData[0].inProgress,
        cumulativePercentage: Math.round((this.ChartData[0].inProgress * 100) / cumulativeCount),
      });

      this.dataSource.push({
        // complaint: 'تایید شده',
        complaint: this.$i18n.t('chart_line.edms_approved'),
        count: this.ChartData[0].accepted_Holder,
        cumulativePercentage: Math.round((this.ChartData[0].accepted_Holder * 100) / cumulativeCount),
      });

      this.dataSource.push({
        // complaint: 'رد شده',
        complaint: this.$i18n.t('chart_line.edms_rejected'),
        count: this.ChartData[0].denied,
        cumulativePercentage: Math.round((this.ChartData[0].denied * 100) / cumulativeCount),
      });

      // const dataSource = this.ChartData.map((item) => {
      //   cumulativeCount += item.count;
      //   return {
      //     complaint: item.complaint,
      //     count: item.count,
      //     cumulativePercentage: Math.round((cumulativeCount * 100) / totalCount),
      //   };
      // });
    },
    customizeText(pointInfo) {
      //return `${pointInfo.argument}: ${pointInfo.value}`;   
      return pointInfo.value;
    },
    customizeTooltip(pointInfo) {
      return {
        html: `<div><div class='tooltip-header'>${pointInfo.argumentText}</div><div class='tooltip-body'><div class='series-name'><span class='top-series-name'>${pointInfo.points[0].seriesName}</span>: </div><div class='value-text'><span class='top-series-value'>${pointInfo.points[0].valueText}</span></div>`
      };
    },

    customizePercentageText({ valueText }) {
      return `${valueText}%`;
    },
  },
};
</script>
<style>
/* #chart {
    height: 440px;
  } */

.chartFont {
  font-family: Samim;
}

.tooltip-header {
  font-family: Samim;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 1px solid #c5c5c5;
}

.tooltip-body {
  font-family: Samim;
  width: 170px;
}

.tooltip-body .series-name {
  font-family: Samim;
  font-weight: normal;
  opacity: 0.6;
  display: inline-block;
  line-height: 1.5;
  padding-right: 10px;
  width: 126px;
}

.tooltip-body .value-text {
  font-family: Samim;
  display: inline-block;
  line-height: 1.5;
  width: 30px;
}
</style>
  