import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "./views/home-page";
import Profile from "./views/profile-page";
import Tasks from "./views/tasks-page";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";
import simpleLayoutLogin from "./layouts/single-card-login";
import simpleLayoutLoginAdmin from "./layouts/single-card-login-admin.vue"
import users from "./views/admin/users";
import company_parts from "./views/admin/company_parts";
import user_roles from "./views/admin/user_roles";
import role_permissions from "./views/admin/role_permissions";
import menu_access from "./views/admin/menu_access";
import doctor_list from "./views/sale/doctor_list";
import patient_list from './views/sale/patient_list'
import customer_consult from "./views/sale/customer_consult";
import customer_providers from "./views/sale/customer_providers";

import subWorkingcatagory from "./views/sale/sub_working_catagory";
import workingcatagory from "./views/sale/working_catagory";
import file_types from "./views/sale/file_types";
import sale_dashboard from "./views/sale/sale_dashboard";

import ortodencyInfo from "./views/ortodencyInfo"
import newAppointment from "./views/newAppointment.vue"
import newAppointmentEdit from "./views/newAppointmentEdit.vue"
import savedAppointments from "./views/savedAppointments.vue"

import userNewAppointment from "./views/userNewAppointment.vue"
import UserNewAppointmentEdit from "./views/UserNewAppointmentEdit.vue"
import userSavedAppointments from "./views/userSavedAppointments.vue"


function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`);
}

const router = new createRouter({
  routes: [
    {
      //صفحه اصلی-خانه
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: false,
        layout: defaultLayout,
      },
      component: Home,
    },
    // سمت کاربر
    {
      path: "/userSavedAppointments",
      name: "userSavedAppointments",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      title: "نوبت های ذخیره شده",
      component: userSavedAppointments

    },
    {
      path: "/userNewAppointmentEdit/:id",
      name: "userNewAppointmentEdit",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UserNewAppointmentEdit,
      props: true
    },
    {
      path: "/userNewAppointment",
      name: "userNewAppointment",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: userNewAppointment
    },
    // سمت کاربر



    // سمت ادمین

    {
      path: "/savedAppointments",
      name: "savedAppointments",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      title: "نوبت های ذخیره شده",
      component: savedAppointments

    },
    {
      path: "/newAppointmentEdit/:id",
      name: "newAppointmentEdit",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: newAppointmentEdit,
      props: true
    },
    {
      path: "/newAppointment",
      name: "newAppointment",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: newAppointment
    },
    {
      path: "/ortodency",
      name: "ortodency",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ortodencyInfo
    },

    {
      //داشبورد کلی فروش
      path: "/sale/sale_dashboard",
      name: "sale-dashboard",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: sale_dashboard,
    },

    {
      //اطلاعات انواع فایل ثبت شده
      path: "/sale/file_types",
      name: "file_types",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: file_types,
    },
    {
      //اطلاعات ریز حوزه تخصصی ثبت شده
      path: "/sale/sub_working_catagory",
      name: "sub_working_catagory",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: subWorkingcatagory,
    },
    {
      //اطلاعات حوزه تخصصی ثبت شده
      path: "/sale/working_catagory",
      name: "working_catagory",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: workingcatagory,
    },
    {
      //اطلاعات بیمار های ثبت شده
      path: "/sale/patient_list",
      name: "patient_list",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: patient_list,
    },
    {
      //اطلاعات دکتر های ثبت شده
      path: "/sale/doctor_list",
      name: "doctor_list",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: doctor_list,
    },
    {
      //اطلاعات مشاور های ثبت شده
      path: "/sale/customer_consult",
      name: "consult",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: customer_consult,
    },
    {
      //اطلاعات تامین کننده های ثبت شده
      path: "/sale/customer_providers",
      name: "providers",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: customer_providers,
    },


    //این قسمت به پایین مربوط به فریم ورک و عملیات های اصلی و ادمین سایت می باشد
    {
      //صفحه مربوط به بخش بندی سازمان
      path: "/admin/company_parts",
      name: "CompanyParts",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: company_parts,
    },
    {
      //صفحه مربوط به اطلاعات کلی کاربران-عملیات های مربوطه
      path: "/admin/users",
      name: "users",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      // component:()=>{import('./views/admin/users.vue')}
      component: users,
    },
    {
      //صفحه مربوط به نقش ها و دسترسی ها کاربران-اختصاص نقش
      path: "/admin/user_roles",
      name: "user_roles",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: user_roles,
    },
    {
      //صفحه مربوط به تخصیص دسترسی به نقش ها - داشبورد نقش ها و دسترسی ها
      path: "/admin/role_permissions",
      name: "role_permissions",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: role_permissions,
    },
    {
      //داشبورد کلی نقش ها بهمراه دسترسی به منو و زیر منو ها - عملیات های مربوطه
      path: "/admin/menu_access",
      name: "menu_access",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: menu_access,
    },
    {
      //پنل کاربری برای کاربر لاگین شده
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Profile,
    },
    {
      //صفحه فعالیت ها که در اینجا استفاده نشده است
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Tasks,
    },
    {
      //صفحه پرسنل ورود به سایت
      path: "/login-form-admin",
      name: "login-form-admin",
      meta: {
        requiresAuth: false,
        layout: simpleLayoutLoginAdmin, //simpleLayout,
        // title: "Sign In"
      },
      component: loadView("login-form-admin"),
    },
    {
      //صفحه ورود به سایت
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayoutLogin, //simpleLayout,
        // title: "Sign In"
      },
      component: loadView("login-form"),
    },
    {
      //صفحه فراموشی رمز عبور
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        // title: "ارسال رمز عبور",
        // description: "لطفا آدرس ایمیل ثبت شده خود را در کادر زیر وارد و بر روی گزینه ارسال رمز کلیک نمایید"
      },
      component: loadView("reset-password-form"),
    },
    {
      //الگوی ساخت حساب کاربری که در اینجا استفاده نشده است
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        // title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      //تغییر رمز عبور
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        // title: "Change Password"
      },
      component: loadView("change-password-form"),
    },
    {
      //صفحه اصلی-خانه
      path: "/",
      redirect: "/home",
    },
    {
      path: "/recovery",
      redirect: "/home",
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home",
    },
  ],
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {

  if (to.title === undefined) {
    document.title = "کلینیک راد";
  }
  else {
    document.title = to.title;
  }

  //در صورتی که توکن کاربر اعتبار داشته باشد ارجاع به صفحه ورود نیاز نیست
  if (to.name === "login-form" && auth.loggedIn()) {
    next({ name: "home" });
  }

  //اگر صفحه درخواست شده دارای تگ
  //requiresAuth
  //باشد ، توکن کاربر قبل از ارجاع بررسی و در صورت داشتن اعتبار اجازه دسترسی داده میشود
  //در غیر این صورت به صفحه ورود کاربر ارجاع داده میشود
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-form",
        //query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
