<template>
  <div>
    <h2 class="content-block"> {{ $t('working_catagory.title') }}</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <DxDataGrid :data-source="working_catagory_datasource" :columns="working_catagory_columns"
          :show-row-lines="showRowLines" :show-column-lines="showColumnLines" :row-alternation-enabled="true"
          :rtl-enabled="true" :remote-operations="true" :column-auto-width="true" :allow-column-reordering="true"
          :allow-column-resizing="true" :hover-state-enabled="true" :noDataText="checkData"
          :selection="{ mode: 'single' }" @toolbar-preparing="onToolbarPreparing"
          @selection-changed="onSelectionChanged" class="mt-3" id="ps_Table" key-expr="id" ref="main_grid">

          <DxColumnChooser :enabled="true" />
          <DxExport :enabled="allowExportDataGrid" :allow-export-selected-data="allowExportSelectedDataGrid" />

          <DxHeaderFilter :visible="showHeaderFilter" />
          <DxSearchPanel :visible="true" :width="190" :placeholder="this.$i18n.t('working_catagory.grid_search')"
            class="" />

          <DxSelection :select-all-mode="SelectionAllMode" :show-check-boxes-mode="checkBoxesMode" mode="multiple" />

          <template #btnGroup class="row">
            <div class="d-lg-none">
              <div class="mt-2">
                <DxDropDownButton :items="Dropactions" :drop-down-options="{ width: 190 }"
                  :text="this.$i18n.t('working_catagory.grid_row_operation')" icon="save" @item-click="onItemClick" />
              </div>
            </div>
            <div class="d-none d-lg-block">
              <DxButton class="ml-2 bg-success" color="success" size="sm" icon="plus" @click="NewCatagory()">
              </DxButton>
              <DxButton color="secondary" class="ml-2" size="sm" icon="revert" @click="$router.go(-1)">
              </DxButton>
              <DxButton color="light" size="sm" icon="pulldown" @click="reloadData">
              </DxButton>
            </div>
          </template>


          <template #btnRGroup class="p-0 m-0">
            <div class="d-none d-lg-block">
              <div class="row">
                <div class="col-sm-12">
                  <button class="btn w-100 btn-sm mr-1 bg-warning" size="sm"> {{
                      $t('working_catagory.grid_row_operation')
                  }}</button>
                </div>
              </div>
            </div>

          </template>
          <template #actions="{ data }">
            <div>
              <DxButton class="bg-warning m-1" icon="edit" type="edit"
                :title="this.$i18n.t('working_catagory.grid_row_edit')" @click="setEdit(data)">
              </DxButton>
              <DxButton class="bg-danger m-1" icon="remove" :title="this.$i18n.t('working_catagory.grid_row_delete')"
                @click="setDeleteDialog(data)">
              </DxButton>
            </div>
          </template>
          <template #radif="{ data }">
            {{ data.rowIndex + 1 }}
          </template>
          <DxFilterRow :visible="true" />
          <DxLoadPanel :enabled="true" />
          <DxScrolling mode="standard" :use-native="true"/>
        </DxDataGrid>

      </div>
    </div>

    <!--       
      <DxPopup
      style="text-align: :center;"
      :width="600"
      :height="260"
      :show-title="true"
      title="فرم اطلاعات حوزه های کاری ثبت شده"
      alignment="center"
      :drag-enabled="true"
      :hide-on-outside-click="false"
      v-model:visible="NewshowDialog"
    >
      <template #content="{ data }">
        <DxScrollView
            width="100%"
            height="100%"
          >
        <div class="popup-property-details">

          <div class="card">
            <div class="card-body">              
                <form>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <font-awesome-icon icon="fas fa-user" class="m-1"/> </span>
                        </div>
                          <input v-model="NewPartName" 
                          @input="this.check_content($event,'inputName')"   
                          :class="{'border-danger-my':!this.isStateOk('inputName'),'border-success-my':this.isStateOk('inputName'),'rounded':true}"
                          placeholder="عنوان سازمان" type="text" >
                      </div>
                    </div>                  
                  </div>
      
                <button v-if="editMode" class="btn btn-success mt-3 me-1 ms-2" @click="editPartInfo($event)"><i class="fa fa-home"></i>اصلاح</button> 
                <button v-else class="btn btn-success mt-3 me-1 ms-2" @click="saveNewPart"><i class="fa fa-home"></i>ذخیره</button> 
                <button class="btn btn-warning mt-3" @click="this.NewshowDialog = false;"><i class="fa fa-home"></i>بازگشت</button>
              </form>

              <div class="card-footer text-muted mt-2">لطفا فیلد های ضروری را به صورت کامل پر نمایید</div>
            </div>
          </div>
             
         </div>
       </DxScrollView>
    </template>
    </DxPopup> -->



    <!-- اطلاعات حوزه کاری جدید-اصلاح    -->
    <DxPopup style="text-align: :center;" :width="this.showDialogNewWidth" :height="this.showDialogNewHeight"
      :show-title="true" :title="this.$i18n.t('working_catagory.dialog_new_header')" alignment="center"
      :drag-enabled="true" :hide-on-outside-click="false" v-model:visible="showDialogNew">
      <template #content="{ data }">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">

            <div class="card">
              <!-- <h5 class="card-header">فرم اطلاعات کاربر</h5> -->
              <div class="card-body">
                <form>


                  <!-- Height-Width-Controller -->
                  <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                      <div class="d-flex flex-row">
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Height-Width-Controller -->

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewCatagoryInfo.title" @input="this.check_content($event, 'inputTitle')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputTitle'), 'border-success-my': this.isStateOk('inputTitle'), 'rounded': true }"
                          :placeholder="this.$i18n.t('working_catagory.dialog_new_title_working_cat')" type="text">
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <textarea v-model="NewCatagoryInfo.description" rows="3" class="form-control"
                          :placeholder="this.$i18n.t('working_catagory.dialog_new_desc_working_cat')" type="text" />
                      </div>
                    </div>
                  </div>

                  <button v-if="editMode" class="btn btn-success mt-3 me-1 ms-2" @click="editCatagoryInfo"><i
                      class="fa fa-home"></i> {{ $t('working_catagory.dialog_new_btn_edit') }}</button>
                  <button v-else class="btn btn-success mt-3 me-1 ms-2" @click="saveNewCatagory"><i
                      class="fa fa-home"></i>{{ $t('working_catagory.dialog_new_btn_save') }}</button>
                  <button class="btn btn-warning mt-3" @click="this.showDialogNew = false;"><i class="fa fa-home"></i>{{
                      $t('working_catagory.dialog_new_btn_back')
                  }}</button>
                </form>

                <div class="card-footer text-muted mt-2">{{ $t('working_catagory.dialog_new_footer_label') }}</div>
              </div>
            </div>

          </div>
        </DxScrollView>
      </template>
    </DxPopup>




    <customDial :show="showDeleteDialog" :cancel="() => { this.showDeleteDialog = false; }" :confirm="delCatagoryM"
      :title="this.$i18n.t('working_catagory.delete_dialog_title')" :userPersonalInfo="fullCatagorySelected"
      :description="this.$i18n.t('working_catagory.delete_dialog_body')" />

  </div>
</template>
<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxForm, DxItem } from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import { DxDataGrid, DxSelection } from 'devextreme-vue/data-grid';
import { dxGrid } from '@/components/dxGrid'
import DxButton from 'devextreme-vue/button';
import { mapGetters, useStore } from 'vuex';
import MyValidator from '@/components/MyValidator'
import notify from 'devextreme/ui/notify';
import customDial from '@/components/customDialog.vue'
import themes from 'devextreme/ui/themes';
import { computed } from '@vue/reactivity';
import DxDropDownButton from 'devextreme-vue/drop-down-button';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import users_compo from '@/components/users_compo.vue'
import { DxDropDownBox } from 'devextreme-vue/drop-down-box';
import responsives from '@/utils/responsives';


export default {
  setup() {
    const store = useStore();
    return {
      store,
      working_catagory_datasource: computed(() => store.getters['working_catagory/getWorkingCatagory']),

    }
  },
  mixins: [dxGrid, MyValidator, responsives],
  components: {
    DxButton,
    DxDataGrid,
    DxPopup,
    DxForm,
    DxSelectBox,
    DxDropDownButton,
    DxScrollView,
    DxDropDownBox,
    customDial
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      debugger
      const dataGrid = this.$refs.main_grid.instance;
      this.$refs.main_grid.columns[0].caption = this.$i18n.t('working_catagory.grid_row_no');
      this.$refs.main_grid.columns[1].caption = this.$i18n.t('working_catagory.grid_row_operation');
      // this.$refs.main_grid.columns[3].caption = this.$i18n.t('file_types.grid_userFilesRequiredEDMSDescription');
      this.$refs.main_grid.columns[3].caption = this.$i18n.t('working_catagory.grid_row_title');
      this.$refs.main_grid.columns[4].caption = this.$i18n.t('working_catagory.grid_row_description');

      dataGrid.option('columns', dataGrid.option('columns'));
    }
  },
  data() {
    return {
      fullCatagorySelected: '',
      selectedCatagoryRow: {},
      NewCatagoryInfo: {},
      editMode: false,
      working_catagory_columns: [
        { cellTemplate: "radif", caption: this.$i18n.t('working_catagory.grid_row_no'), dataType: 'number', allowFiltering: false, width: 80, alignment: "center" },
        { cellTemplate: 'actions', caption: this.$i18n.t('working_catagory.grid_row_operation'), allowFiltering: false, allowSorting: false },
        { dataField: 'id', caption: 'شناسه', dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'title', caption: this.$i18n.t('working_catagory.grid_row_title'), dataType: 'string', alignment: 'center', format: { type: "fixedPoint", precision: 0 } },
        { dataField: 'description', caption: this.$i18n.t('working_catagory.grid_row_description'), dataType: 'string', alignment: 'center' },
      ],
      showDialogNew: false,
      showDeleteDialog: false,
      Dropactions: [
        { id: 1, text: this.$i18n.t('working_catagory.drop_down_toolbar_new_item'), icon: "user" },
        { id: 2, text: this.$i18n.t('working_catagory.drop_down_toolbar_new_reload'), icon: "refresh" },
        { id: 3, text: this.$i18n.t('working_catagory.drop_down_toolbar_new_back'), icon: "undo" },
        { id: 4, text: "تنظیمات1", icon: "preferences" },
        { id: 5, text: "تنظیمات2", icon: "preferences" },
        { id: 6, text: "تنظیمات3", icon: "preferences" },
      ],
      checkBoxesMode: themes.current().startsWith('material') ? 'always' : 'onClick',
    }
  },
  created() {
    this.make_state_bind('inputTitle', false, this.$i18n.t('working_catagory.validation_new_title'));
    this.reloadData();
  },
  methods: {
    setEdit(data_sent) {
      debugger
      this.selectedCatagoryRow = data_sent.data;
      if (this.selectedCatagoryRow.title === null || this.selectedCatagoryRow.title === undefined) {
        notify(this.$i18n.t('working_catagory.validate_pre_edit'), 'warning', this.notifyTimeSet);
        return;
      }
      this.makeInitSize(600, 360);
      this.make_state('inputTitle', true);
      this.NewCatagoryInfo = this.selectedCatagoryRow;
      this.editMode = true;
      this.showDialogNew = true;
    },
    delCatagoryM() {
      this.store.dispatch('working_catagory/deleteWorkingCatagory', this.selectedCatagoryRow.id);
      this.showDeleteDialog = false;
      setTimeout(() => {
        this.reloadData();
      }, this.reloadTimeSet);
    },
    setDeleteDialog(data_sent) {
      debugger
      this.selectedCatagoryRow = data_sent.data;
      this.fullCatagorySelected = this.selectedCatagoryRow.title;
      if (this.selectedCatagoryRow.title === null || this.selectedCatagoryRow.title === undefined) {
        notify(this.$i18n.t('working_catagory.validate_pre_delete'), 'warning', this.notifyTimeSet);
        return;
      }
      this.showDeleteDialog = true;
    },
    editCatagoryInfo() {
      if (this.isStateOkTotal() > 0) {
        let give_me_errors = this.getElementsWithErorrs();
        notify(give_me_errors, 'error', this.notifyTimeSet);
        return;
      }
      debugger
      const NewCatagory = {
        Id: this.selectedCatagoryRow.id,
        Title: this.NewCatagoryInfo.title,
        Description: this.NewCatagoryInfo.description
      }
      this.store.dispatch('working_catagory/editWorkingCatagory', NewCatagory);
      this.showDialogNew = false;
      setTimeout(() => {
        this.reloadData();
      }, this.reloadTimeSet);
    },
    saveNewCatagory() {
      if (this.isStateOkTotal() > 0) {
        let give_me_errors = this.getElementsWithErorrs();
        notify(give_me_errors, 'error', this.notifyTimeSet);
        return;
      }
      const NewCatagory = {
        Title: this.NewCatagoryInfo.title,
        Description: this.NewCatagoryInfo.description
      }
      this.store.dispatch('working_catagory/addNewWorkingCatagory', NewCatagory);
      this.showDialogNew = false;
      setTimeout(() => {
        this.reloadData();
      }, this.reloadTimeSet);
    },
    NewCatagory() {
      this.makeInitSize(600, 440);
      this.editMode = false;
      this.make_state('inputTitle', false);
      this.NewCatagoryInfo = {};
      this.showDialogNew = true;
    },
    reloadData() {
      this.store.dispatch('working_catagory/getWorkingCatagory');
    },
    onItemClick(e) {
      debugger
      switch (e.itemData.id) {
        case 1:
          this.NewCatagory();
          break;
        case 2:
          this.reloadData();
          break;
        case 3:
          this.$router.go(-1);
          break;
        default:
          notify('خطای ناشناس در منو', 'warning', this.notifyTimeSet);
          break;
      }
    },
    onSelectionChanged(selectedRowsData) {
      debugger
      this.selectedCatagoryRow = selectedRowsData.selectedRowsData[0];
      if (this.selectedCatagoryRow != null || this.selectedCatagoryRow != undefined) {
        this.fullCatagorySelected = this.selectedCatagoryRow.title;
      }

    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "btnRGroup",
      });
      e.toolbarOptions.items.unshift({
        location: "after",
        template: "btnGroup",
      });
    },
  },

}
</script>
<style lang="scss">
@import "@/dx-styles.scss";

.dx-popup-wrapper>.dx-overlay-content {
  background: #fff;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border-left-color: $popup_color;
  border-left-width: 9px;
}

#ps_Table {
  height: 60vh;
}
</style>