<template>
    <div class="content-block">
        <div class="dx-card gallery-frame appointemnt-frame">

            <div class="appointment-info">
                <div class="dx-card my-resp" >
                    <div class="img-title">
                        <img class="img-title" src="@/assets/img/date-48.png" alt="Patient" />
                        <p>تاریخ / زمان</p>
                    </div>
                    <CustomDatePicker v-model="date_to_pick" placeholder="تاریخ نوبت را انتخاب نمایید"
                        format="jYYYY/jMM/jDD HH:mm" type="datetime">
                        <!-- min="1402/07/10 12:00" max="1404/08/10 20:00" -->
                    </CustomDatePicker>
                </div>
                <div class="dx-card my-resp" >
                    <div class="img-title">
                        <img src="@/assets/img/doctor-94.png" alt="Doctor" />
                        <p>مشخصات دکتر</p>
                    </div>
                    <DxDropDownBox v-model:value="gridBoxValueDoctors" v-model:opened="isGridBoxOpenedDoctors"
                        :input-attr="{ 'aria-label': 'Owner' }" :defer-rendering="false"
                        :display-expr="gridBoxDisplayExprDoctors" :show-clear-button="true"
                        :data-source="doctor_list_datasource" value-expr="ID" placeholder="مشخصات دکتر را انتخاب نمایید">
                        <template #content="{ data }">
                            <DxDataGrid :data-source="doctor_list_datasource" :columns="gridColumnsDoctors"
                                :hover-state-enabled="true" v-model:selected-row-keys="gridBoxValueDoctors"
                                @selection-changed="onGridSelectionChangedDoctors($event)" height="100%">
                                <DxSelection mode="single" />
                                <DxPaging :enabled="true" :page-size="10" />
                                <DxFilterRow :visible="true" />
                                <DxScrolling mode="virtual" />
                            </DxDataGrid>
                        </template>
                    </DxDropDownBox>
                </div>
                <div class="dx-card my-resp">
                    <div class="img-title">
                        <img class="img-title" src="@/assets/img/tooth-48.png" alt="Patient" />
                        <p>مشخصات بیمار</p>
                    </div>
                    <DxDropDownBox v-model:value="gridBoxValuePatient" v-model:opened="isGridBoxOpenedPatient"
                        :input-attr="{ 'aria-label': 'Owner' }" :defer-rendering="false"
                        :display-expr="gridBoxDisplayExprPatient" :show-clear-button="true"
                        :data-source="patient_list_datasource" value-expr="ID" placeholder="مشخصات بیمار را انتخاب نمایید">
                        <template #content="{ data }">
                            <DxDataGrid :data-source="patient_list_datasource" :columns="gridColumns"
                                :hover-state-enabled="true" v-model:selected-row-keys="gridBoxValuePatient"
                                @selection-changed="onGridPatientSelectionChanged($event)" height="100%">
                                <DxSelection mode="single" />
                                <DxPaging :enabled="true" :page-size="10" />
                                <DxFilterRow :visible="true" />
                                <DxScrolling mode="virtual" />
                            </DxDataGrid>
                        </template>
                    </DxDropDownBox>
                </div>
                <div class="dx-card my-resp">
                    <div class="img-title">
                        <img class="img-title" src="@/assets/img/tooth-heal-64.png" alt="Treatment" />
                        <p>نوع درمان</p>
                    </div>
                    <DxDropDownBox v-model:value="gridBoxValueTreatment" v-model:opened="isGridBoxOpenedTreatment"
                        :input-attr="{ 'aria-label': 'Owner' }" :defer-rendering="false"
                        :display-expr="gridBoxDisplayExprTreatment" :show-clear-button="true"
                        :data-source="sub_working_catagory_datasource" value-expr="ID"
                        placeholder="نوع درمان را از لیست انتخاب نمایید">
                        <template #content="{ data }">
                            <DxDataGrid :data-source="sub_working_catagory_datasource" :columns="gridColumnsTreatment"
                                :hover-state-enabled="true" v-model:selected-row-keys="gridBoxValueTreatment"
                                @selection-changed="onGridTreatmentSelectionChanged($event)" height="100%">
                                <DxSelection mode="single" />
                                <DxPaging :enabled="true" :page-size="10" />
                                <DxFilterRow :visible="true" />
                                <DxScrolling mode="virtual" />
                            </DxDataGrid>
                        </template>
                    </DxDropDownBox>
                    <!-- <DxSelectBox :items="sub_working_catagory_datasource" :input-attr="{ 'aria-label': 'Simple Product' }"
                        :show-clear-button="true" placeholder="نوع درمان را از لیست انتخاب نمایید"
                        @value-changed="onTreatmentTypeChanged" /> -->
                </div>
                <div class="dx-card my-resp">
                    <div class="img-title">
                        <img class="img-title" src="@/assets/img/note-100.png" alt="Description" />
                        <p>توضیحات نوبت</p>
                    </div>
                    <DxTextBox label="توضیحات نوبت بیمار در این قسمت وارد می شود" label-mode="floating"
                        :show-clear-button="true" v-model="reservationDescription" />
                </div>
                <div class="dx-card my-resp">
                    <DxButton text="ثبت نوبت" styling-mode="Contained" type="success" icon="add" class="my-btn"
                        @click="saveAppointment" />
                    <DxButton text="بازگشت" styling-mode="Contained" type="default" icon="back" class="my-btn"
                        @click="goBack" />
                </div>
            </div>

            <div class="appointment-img">
                <img src="@/assets/logo.png" />
            </div>
        </div>
    </div>
</template>

<script>
// import 'devextreme/dist/css/dx.light.css';
import { DxTextBox } from 'devextreme-vue/text-box';
import DxDropDownBox from 'devextreme-vue/drop-down-box';
import {
    DxDataGrid, DxSelection, DxPaging, DxFilterRow, DxScrolling,
} from 'devextreme-vue/data-grid';
import DxSelectBox from 'devextreme-vue/select-box';
import notify from 'devextreme/ui/notify';
import DxButton from 'devextreme-vue/button';
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';


export default {
    name: "newAppointment",
    components: {
        DxTextBox,
        DxDropDownBox,
        DxDataGrid, DxSelection, DxPaging, DxFilterRow, DxScrolling,
        DxSelectBox,
        notify,
        DxButton
    },
    watch: {
        saved_reservation(newVal, oldVal) {
            debugger
            this.gridBoxValuePatient = this.patient_list_datasource.filter(x=> x.id == this.saved_reservation[0].patientId)[0];
            this.gridBoxValueDoctors = this.doctor_list_datasource.filter(x => x.id == this.saved_reservation[0].doctorId)[0];
            this.gridBoxValueTreatment = this.sub_working_catagory_datasource.filter(x => x.id == this.saved_reservation[0].subWorkId)[0];
            this.date_to_pick = this.saved_reservation[0].reservationDate + ' '+this.saved_reservation[0].reservationTime;
            this.reservationDescription = this.saved_reservation[0].reservationDescription;
        },
        sub_working_catagory_datasource()
        {
            this.reloadData();
        }
    },
    setup() {
        const store = useStore();
        return {
            store,
            doctor_list_datasource: computed(() => store.getters['doctor_list/getBaseDoctorList']),
            patient_list_datasource: computed(() => store.getters['patient_list/getPatientList']),
            sub_working_catagory_datasource: computed(() => store.getters['sub_working_catagory/getBaseSubWorkingCatagory']),
            saved_reservation: computed(() => store.getters['reservations/getBaseReservesList']),

        }
    },
    created() {
       
        this.getDoctorsBaseInfo();
        this.getPatientsBaseInfo();
        this.reloadDataSubWorkingCatagory();
    },
    data() {
        return {
            reservationDescription: null,
            simpleProducts: [
                'ادامه درمان',
                'معاینه اولیه',
                'پیاده سازی',
            ],

            gridBoxValuePatient: null,
            isGridBoxOpenedPatient: false,

            date_to_pick: null,

            isGridBoxOpenedDoctors: false,
            gridBoxValueDoctors: null,

            gridColumnsDoctors: [
                {
                    dataField: "id",
                    caption: "شناسه",
                    visible: false
                },
                {
                    dataField: "name",
                    caption: "نام"
                },
                {
                    dataField: "family",
                    caption: "نام خانوادگی"
                }
            ],

            gridBoxValueTreatment: null,
            isGridBoxOpenedTreatment: false,
            gridColumnsTreatment: [
                {
                    dataField: "id",
                    caption: "شناسه",
                    visible: false
                },
                {
                    dataField: "workingCatagory",
                    caption: "حوزه اصلی"
                },
                {
                    dataField: "subWorkingCatagory",
                    caption: "حوزه زیرمجموعه"
                }
            ],

            isGridBoxOpened: false,
            gridBoxValue: null,
            gridColumns: [
                {
                    dataField: "id",
                    caption: "شناسه",
                    visible: false
                },
                {
                    dataField: "name",
                    caption: "نام"
                },
                {
                    dataField: "family",
                    caption: "نام خانوادگی"
                }
            ],

        }
    },
    methods: {
        reloadData() {
            this.store.dispatch('reservations/getSingleReservation', this.$route.params.id);
        },
        reloadDataSubWorkingCatagory() {
            this.store.dispatch('sub_working_catagory/getBaseSubWorkingCatagory');
            
        },
        getPatientsBaseInfo() {
            this.store.dispatch('patient_list/getPatientList');
        },
        getDoctorsBaseInfo() {
            this.store.dispatch('doctor_list/getBaseDoctorList');
        },
        goBack() {
            // this.$router.go({ name: 'newAppointment' });
            debugger
            this.$router.push('/savedAppointments');
        },
        saveAppointment() {
            debugger
            let dataHolder = {
                Id:this.$route.params.id,
                PatientId: this.gridBoxValuePatient.id,
                DoctorId: this.gridBoxValueDoctors.id,
                SubWorkId: this.gridBoxValueTreatment.id,
                ReservationDate: this.date_to_pick.substr(0, 10),
                ReservationTime: this.date_to_pick.substr(10),
                ReservationDescription: this.reservationDescription,
                ReservationStatus: 100,
                UserSaved: localStorage.getItem('userId'),
            }
            this.store.dispatch('reservations/editReservation', dataHolder);
            this.$router.push({ path: '/savedAppointments' });
            // notify(`نوبت ذخیره شده - آزمایشی`, 'info');
        },
        onTreatmentTypeChanged(e) {
            notify(`نوع درمان انتخاب شده : "${e.value}"`, 'warning');
        },
        gridBoxDisplayExprPatient(item) {
            if (this.gridBoxValuePatient != undefined)
                notify(`بیمار انتخاب شده : "${this.gridBoxValuePatient.name + ' ' + this.gridBoxValuePatient.family}"`, 'warning');
            return this.gridBoxValuePatient.name + ' ' + this.gridBoxValuePatient.family;
        },
        gridBoxDisplayExprTreatment(item) {
            if (this.gridBoxValueTreatment != undefined)
                notify(`درمان انتخاب شده : "${this.gridBoxValueTreatment.workingCatagory + ' ' + this.gridBoxValueTreatment.subWorkingCatagory}"`, 'warning');
            return this.gridBoxValueTreatment.workingCatagory + ' ' + this.gridBoxValueTreatment.subWorkingCatagory;
        },
        gridBoxDisplayExprDoctors(item) {
            debugger
            if (this.gridBoxValueDoctors != undefined)
                notify(`دکتر انتخاب شده : "${this.gridBoxValueDoctors.name + ' ' + this.gridBoxValueDoctors.family}"`, 'warning');
            return `${this.gridBoxValueDoctors.name + ' ' + this.gridBoxValueDoctors.family}`;
        },
        onGridPatientSelectionChanged(evento) {
            this.gridBoxValuePatient = evento.selectedRowKeys[0];
            this.isGridBoxOpenedPatient = false;
        },
        onGridTreatmentSelectionChanged(evento) {
            this.gridBoxValueTreatment = evento.selectedRowKeys[0];
            this.isGridBoxOpenedTreatment = false;
        },
        onGridSelectionChangedDoctors(evento) {
            debugger
            this.gridBoxValueDoctors = evento.selectedRowKeys[0];
            this.isGridBoxOpenedDoctors = false;
        },

        gridBoxDisplayExpr(item) {
            if (this.gridBoxValue != undefined)
                notify(`بیمار انتخاب شده : "${this.gridBoxValue.Assignee}"`, 'warning');
            return `${this.gridBoxValue.Assignee}`;
        },

        onGridSelectionChanged(evento) {
            debugger
            this.gridBoxValue = evento.selectedRowKeys[0];
            this.isGridBoxOpened = false;
        }

    }
}
</script>

<style lang="scss" scoped>
.appointemnt-frame {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    transition: transform .3s ease-in-out;
    padding: 10px;

    .my-resp {
        padding: 1%;
    }

    .appointment-info {
        width: 80%;
        transition: transform .3s ease-in-out;
    }

    .appointment-img {
        width: 20%;
        position: absolute;
        left: 5px;
        min-width: 150px;
        // transition: transform .3s ease-in-out ;
        transition: transform .3s ease-out;
        animation: MyfadeIn 6s;
        -webkit-animation: MyfadeIn 6s;
        -moz-animation: MyfadeIn 6s;
        -o-animation: MyfadeIn 6s;
        -ms-animation: MyfadeIn 6s;

        @keyframes MyfadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        &:hover {
            transform: scale(1.2) translateY(+10px) rotate(-40deg);
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            border-radius: 40px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.img-title {
    display: flex;

    p {}

    img {
        width: 45px;
        height: auto;
        object-fit: contain;
    }
}

.my-btn {
    margin: 5px;
}

@media screen and (max-width: 700px) {
    .appointment-img {
        display: none !important;
        width: 0% !important;
    }

    .appointment-info {
        width: 100% !important;
    }
}
</style>