//import notify from ''


export default {
    data(){        
        return{
            state_changed_ok : [],
            state_changed_wrong : false,
        }
    },    
    methods:{
        clear_states(){
            this.state_changed_ok = [];
        },
        make_all_fields_ok(){
            this.state_changed_ok.forEach((element) => {
                element.val_seted=true;    
            })  
        },
        make_all_fields_wrong(){
            this.state_changed_ok.forEach((element) => {
                element.val_seted=false;    
            })  
        },        
        isStateOk(offsetter){   
            //debugger  
            //console.log('isStateOk => ',offsetter);       
            // if(offsetter === undefined || this.state_changed_ok.length < offsetter  || offsetter === 0)
            if(offsetter === undefined  || offsetter === 0)
            {
                return false;
            }

            if(this.state_changed_ok.length > 0 )
            {
                const holder_obj = this.state_changed_ok.filter( x => x.typeName === offsetter)[0];    
                if(holder_obj != undefined)            
                    return holder_obj.val_seted;
                else    
                {                    
                    return false;
                }                    
            }
            else 
            {
                return true;
            }
        },
        getElementsWithErorrs(){
            debugger
            let holder_input_names = [];
            this.state_changed_ok.forEach((element) => {
                debugger
                if(element.val_seted === false)
                {
                    holder_input_names.push(element.element_name);
                }
            })            
            let holder_text = 'موارد زیر دارای مقادیر نامعتبر می باشند'+
            '\n-----------------------------------------------\n';
            holder_text += holder_input_names.join('\n');
            holder_text += '\n-----------------------------------------------\n';
            return holder_text;
        },
        isStateOkTotal(){   
            //debugger         
            const count_error = this.state_changed_ok.filter( x => x.val_seted === false).length;    
            return count_error;
        },
        make_state_bind(type_op,value_seted,element)
        {
            //console.log('make_state_bind : '+type_op+' - '+value_seted);
            //debugger
            if(this.error_check_exister(type_op))
            {
                let holder_index = this.state_changed_ok.findIndex(x => x.typeName === type_op);
                this.state_changed_ok[holder_index].val_seted = value_seted;
            }
            else{
                this.state_changed_ok.push({typeName:type_op,val_seted:value_seted,element_name:element});
            }
        },
        make_state(type_op,value_seted)
        {
            //debugger
            if(this.error_check_exister(type_op))
            {
                let holder_index = this.state_changed_ok.findIndex(x => x.typeName === type_op);
                this.state_changed_ok[holder_index].val_seted = value_seted;
            }
            else{
                this.state_changed_ok.push({typeName:type_op,val_seted:value_seted});
            }
        },
        error_check_exister(type_op){
            //debugger

            if(this.state_changed_ok.filter(x => x.typeName === type_op).length > 0)
                return true;
            else 
                return false;

        }
        ,
        check_content(content,type_op,min,max)
        {        
            //  debugger   
            if(type_op.toString().startsWith('vselect'))
            {                
                if(content != null && content != undefined)
                {
                    if(content === '')
                    {
                        this.state_changed_wrong = false;
                        this.make_state(type_op,false);
                        return;
                    }
                    else 
                    {
                        this.state_changed_wrong = false;
                        this.make_state(type_op,true);                 
                        //console.log('empty');
                        return;
                    }
                }
                else 
                {               
                    this.state_changed_wrong = false;
                    this.make_state(type_op,false);
                    //console.log('empty');
                    return;
                }
                return;                
            }


            if(type_op.toString().startsWith('num'))
            {
                debugger
                if(content != null && content != undefined)
                {
                    if(content === null || content === '')
                    {
                        this.state_changed_wrong = false;
                        this.make_state(type_op,false);
                        return;
                    }
                    else 
                    {
                        this.state_changed_wrong = false;
                        this.make_state(type_op,true);                 
                        //console.log('empty');
                        return;
                    }
                }
                else 
                {               
                    this.state_changed_wrong = false;
                    this.make_state(type_op,false);
                    //console.log('empty');
                    return;
                }
                return;                
            }

            if(type_op.toString().startsWith('file'))
            {
                if(content != null && content != undefined)
                {
                    if(content === '')
                    {
                        this.state_changed_wrong = false;
                        this.make_state(type_op,false);
                        return;
                    }
                    else 
                    {
                        this.state_changed_wrong = false;
                        this.make_state(type_op,true);                 
                        return;
                    }
                }
                else 
                {               
                    this.state_changed_wrong = false;
                    this.make_state(type_op,false);
                    return;
                }
            }

            if(type_op.toString().startsWith('date'))
            {
                if(content != null && content != undefined)
                {
                    if(content === '')
                    {
                        this.state_changed_wrong = false;
                        this.make_state(type_op,false);
                        return;
                    }
                    else 
                    {
                        this.state_changed_wrong = false;
                        this.make_state(type_op,true);                 
                        return;
                    }
                }
                else 
                {               
                    this.state_changed_wrong = false;
                    this.make_state(type_op,false);
                    return;
                }
            }

            if(content === null || content === undefined)
            {
                this.state_changed_wrong = false;
                this.make_state(type_op,false);
                //console.log('empty');
                return;
            }
            //debugger
            let data_holder = null;
            
            let element_type = content.inputType;
            let element = content.srcElement;
            data_holder = content.target.value;

            //console.log('this is from check_content : ',data_holder)

            if(data_holder != null)
            {
                if(data_holder === '')
                {
                    this.state_changed_wrong = false;
                    // this.state_changed_ok=false;
                    this.make_state(type_op,false);
                    //element.classList.add("border-danger");
                    //element.classList.remove("border-success");
                    return;
                }
                else 
                {
                    this.state_changed_wrong = false;
                    this.make_state(type_op,true);
                }
            }
            else 
            {               
                this.state_changed_wrong = false;
                this.make_state(type_op,false);
                //console.log('empty');
                return;
            }

            switch (type_op) {
                case 2:                    
                    if(isNaN(data_holder))
                    {
                        if(1)
                        {
                            this.state_changed_wrong = true;
                            this.make_state(type_op,false);
                            //console.log('not a number fisrst');                    

                        }                       
                    
                    }
                    else 
                    {
                        if(1)
                        {
                            this.state_changed_wrong = false;
                            this.make_state(type_op,true);
                            //console.log('number first !');
                        }                      
                    
                    }
                    break;
                case 3:
                    //Min
                    if(isNaN(data_holder))
                    {
                        if(1)
                        {
                            this.state_changed_wrong = true;
                            this.make_state(type_op,false);
                            //console.log('not a number fisrst');                    
                        }                        
                    
                    }
                    else 
                    {
                        if(data_holder < min)
                        {
                            if(1)
                            {
                                this.state_changed_wrong = true;
                                this.make_state(type_op,false);                                          
                                //console.log('not a number fisrst');  
                            }
                                
                        } 
                        else 
                        {
                            if(1)
                            {
                                this.make_state(type_op,true);
                                this.state_changed_wrong = false;                                                     
                                //console.log('number first !');
                            } 
                        }                                                                 
                    }
                    break;
                case 4:
                    //Max
                    if(isNaN(data_holder))
                    {
                        if(1)
                        {
                            this.state_changed_wrong = true;
                            this.make_state(type_op,false);                                                  
                            //console.log('not a number fisrst');                    
                        }                        
                    
                    }
                    else 
                    {
                        if(data_holder < min)
                        { 
                            if(1)
                            {
                                this.state_changed_wrong = true;
                                this.make_state(type_op,false);                                                 
                                //console.log('not a number fisrst'); 
                                return; 
                            }
                                
                        } 
                        else 
                        {
                            if(1)
                            {
                                this.make_state(type_op,true);
                                this.state_changed_wrong = false;                                                     
                                //console.log('number first !');
                            } 
                        } 
                        if(data_holder > max)
                        {
                            if(1)
                            {
                                this.state_changed_wrong = true;
                                this.make_state(type_op,false);                                                     
                                //console.log('not a number fisrst');  
                            }
                                
                        } 
                        else 
                        {
                            if(1)
                            {
                                this.make_state(type_op,true);
                                this.state_changed_wrong = false;                                                    
                                //console.log('number first !');
                            } 
                        }                                                                 
                    }
                    break;
                default:
                    //console.log('unknown type operation . . .',data_holder)
                    break;
            }
         
        }
    }    
}