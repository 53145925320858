import axios from 'axios'
import notify from 'devextreme/ui/notify';
import errorHandler from '@/components/errorHandler';

export default {
    getUserPart({commit},data){
        // debugger
        axios
        .get('CompanyParts/GetUserPart?user_id='+data)
        .then((response)=>{
            // debugger                                                
            commit('setUser_part',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    getSinglePartByTitleDesc({commit},data){
        // debugger
        axios
        .get('CompanyParts/GetPartByTitleDesc?part_company_des='+data)
        .then((response)=>{
            // debugger                                                
            commit('setSinglePart',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    getPartsWithCount({commit},data){                        
        axios
        .get('CompanyParts/GetPartsByCount')
        .then((response)=>{
            debugger                                                
            commit('setParts',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    getParts({commit},data){ 
        debugger               
        axios
        .get('CompanyParts/GetParts')
        .then((response)=>{                                    
            debugger
            commit('setBaseParts',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);

        })
    },
    getPartsById({commit},data){        
        axios
        .get('CompanyParts/GetPartById')
        .then((response)=>{            
            commit('setParts',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    addNewPart({commit},data){
        debugger
        axios
        .post('CompanyParts/AddNewPart',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    addNewPartUser({commit},data){
        debugger
        axios
        .post('CompanyPartUsers/AddNewCompanyPartUser',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    editPart({commit},data){
        debugger
        axios
        .post('CompanyParts/UpdatePart',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    deletePart({commit},data){ 
        axios
        .post('CompanyParts/DeletePart?id='+data)
        .then((response)=>{            
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            } 
        })
        .catch(error => {
            debugger
            errorHandler.methods.handleError(error);
        })
    },

}