export default [
  {
    text: "صفحه اصلی",
    path: "/home",
    icon: "home"
  },
  {
    text: "پنل ادمین",
    icon: "home",
    items:[
      {
        text:"کاربران",
        icon:"group",
        path:"/admin/users"
      },
      {
        text:"بخش بندی سازمان",
        icon:"fields",
        path:"/admin/company_parts"
      },
      {
        text:"دسترسی کاربران",
        icon:"imgarlock",
        path:"/admin/user_roles"
      },
      {
        text:"تنظیمات دسترسی",
        icon:"warning",
        path:"/admin/role_permissions"
      },
      {
        text:"تنظیمات منوها",
        icon:"menu",
        path:"/admin/menu_access"
      }
    ]
  },
  // {
  //   text: "Examples",
  //   icon: "folder",
  //   items: [
  //     {
  //       text: "Profile",
  //       path: "/profile"
  //     },
  //     {
  //       text: "Tasks",
  //       path: "/tasks"
  //     }
  //   ]
  // }
  ];
