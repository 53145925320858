import axios from 'axios'
import notify from 'devextreme/ui/notify';
import errorHandler from '@/components/errorHandler';

export default {
    getBaseSubWorkingCatagory({commit},data){   
        // debugger                     
        axios
        .get('SubWorking/GetBaseSubWorkingCatagoryList')
        .then((response)=>{
            // debugger                                                
            commit('setBaseSubWorkingCatagory',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },

    getSubWorkingCatagory({commit},data){   
        debugger                     
        axios
        .get('SubWorking/GetSubWorkingCatagoryList')
        .then((response)=>{
            debugger                                                
            commit('setSubWorkingCatagory',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    addNewSubWorkingCatagory({commit},data){
        debugger
        axios
        .post('SubWorking/AddNewSubWorkingCatagory',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    editSubWorkingCatagory({commit},data){
        debugger
        axios
        .post('SubWorking/UpdateSubWorkingCatagory',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    deleteSubWorkingCatagory({commit},data){ 
        axios
        .post('SubWorking/DeleteSubWorkingCatagory?id='+data)
        .then((response)=>{            
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            } 
        })
        .catch(error => {
            debugger
            errorHandler.methods.handleError(error);
        })
    },

}