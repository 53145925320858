import state from "../state";

export default {
    getUser_edms_chart : state => {                
        return state.user_edms_chart;
    },
    getUser_uploads_chart : state => {
        return state.user_uploads_chart;
    },
    getUser_uploads_states_chart : state => {
        return state.user_uploads_states_chart;
    },
    getUser_comments_chart : state => {
        return state.user_comments_chart;
    },
    // for sale 
    getTotal_uploads_chart : state => {
        return state.total_uploads_chart;
    },
    getTotal_uploads_states_chart : state => {
        return state.total_uploads_states_chart;
    },
    getTotal_comments_chart : state => {
        return state.total_comments_chart;
    },
    getActive_Reminders : state => {
        return state.active_reminders;
    },
    getActive_7Day_passed_reminders_toolbar_items : state => {
        return state.active_7Day_passed_reminders_toolbar_items;
    },
    getActive_7Day_passed_reminders : state => {
        return state.active_7Day_passed_reminders;
    },
    getActive_7Day_passed_reminders_count : state => {
        return state.active_7Day_passed_reminders_count;
    },
    getActive_unread_messages_count : state => {
        return state.active_unread_messages_count;
    },
    getUsers_last:state => {
        return state.users_last;
    }
}