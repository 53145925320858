import { createStore } from 'vuex'

import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

import user from './user/index'
import parts from './parts/index'
import company from './company'
import roles from './roles'
import menus from './menus'
import working_catagory from './working_catagory'
import sub_working_catagory from './sub_working_catagory'
import doctor_list from './doctor_list'
import patient_list from './patient_list'
import user_reminders from './user_reminders'
import reservations from './reservations'

import file_types from './file_types'

import charts from './charts'

export default createStore({
  // state: {
  // },
  // getters: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  state,
  mutations,
  actions,
  getters,
  modules: {
    user,
    parts,
    company,
    roles,
    menus,
    working_catagory,
    sub_working_catagory,
    doctor_list,
    patient_list,
    user_reminders,
    reservations,

    file_types,

    charts,
    
    
  }
})
