import axios from 'axios'
import notify from 'devextreme/ui/notify';
import errorHandler from '@/components/errorHandler';

export default {

    // get Last User Infos
    // ***********************************
    getUserLastLogs({ commit }, data) {
        debugger
        axios
            .get('Charts/getUserLastLogs?user_id='+data)
            .then((response) => {
                // debugger                                                
                commit('setUsers_last', response.data);
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
            
    },

    // Total Items For Sale Dashboard
    // ***********************************
    setUnSeen_7Passed_Reminders({ commit }, data) {
        debugger
        axios
            .post('EdmsReminder/UpdateEdmsReminderUnseen?edms_reminder_id='+data)
            .then((response) => {
                debugger                                                
                if(response.status === 200)
                {
                    notify(response.data.message, "success", this.notifyTimeSet);
                }   
                else 
                {
                    notify(response.data.message, "error", this.notifyTimeSet);
                } 
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
    },
    setSeen_7Passed_Reminders({ commit }, data) {
        debugger
        axios
            .post('EdmsReminder/UpdateEdmsReminder?edms_reminder_id='+data)
            .then((response) => {
                debugger                                                
                if(response.status === 200)
                {
                    notify(response.data.message, "success", this.notifyTimeSet);
                }   
                else 
                {
                    notify(response.data.message, "error", this.notifyTimeSet);
                } 
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
    },
    GetActive_7Passed_Reminders({ commit }, data) {
        // debugger
        axios
            .get('Charts/GetActive7DaysPassedReminders')
            .then((response) => {
                // debugger                                                
                commit('setActive_7Day_passed_reminders', response.data);
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
    },
    GetActive_Reminders({ commit }, data) {
        debugger
        axios
            .get('Charts/GetActiveReminders')
            .then((response) => {
                debugger                                                
                commit('setActive_Reminders', response.data);
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
    },
    GetTotal_uploads_chart({ commit }, data) {
        //debugger
        axios
            .get('Charts/GetTotalFileUploads')
            .then((response) => {
                //debugger                                                
                commit('setTotal_uploads_chart', response.data);
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
    },



    GetTotal_uploads_states_chart_by_filter({ commit }, data) {
        //debugger
        axios
            .post('Charts/GetUserFileUploadsThisYearWithDetails_ByFilter' , data)
            .then((response) => {
                //debugger                                                
                commit('setTotal_uploads_states_chart', response.data);
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
    },
    // Total Items For Sale Dashboard
    // ***********************************

    GetUser_top_comments_by_filter({ commit }, data) {
        //debugger
        axios
            .post('Charts/GetUserChartTopCountUserMessagesByFilter', data)
            .then((response) => {
                //debugger                                                
                commit('setUser_comments_chart', response.data);
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
    },
    GetUser_uploads_chart_filter({ commit }, data) {
        //debugger
        axios
            .post('Charts/GetUserFileUploadsByFilter' , data)
            .then((response) => {
                //debugger                                                
                commit('setUser_uploads_chart', response.data);
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
    },


    GetTotal_uploads_states_chart({ commit }, data) {
        //debugger
        axios
            .get('Charts/GetUserFileUploadsThisYearWithDetails?user_id=' + data)
            .then((response) => {
                //debugger                                                
                commit('setTotal_uploads_states_chart', response.data);
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
    },
    // Total Items For Sale Dashboard
    // ***********************************



    GetUser_top_comments({ commit }, data) {
        //debugger
        axios
            .get('Charts/GetUserChartTopCountUserMessages?user_id=' + data)
            .then((response) => {
                //debugger                                                
                commit('setUser_comments_chart', response.data);
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
    },
    GetUser_uploads_chart({ commit }, data) {
        //debugger
        axios
            .get('Charts/GetUserFileUploads?user_id=' + data)
            .then((response) => {
                //debugger                                                
                commit('setUser_uploads_chart', response.data);
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
    },
    GetUser_uploads_states_chart({ commit }, data) {
        //debugger
        axios
            .get('Charts/GetUserFileUploadsThisYearWithDetails?user_id=' + data)
            .then((response) => {
                //debugger                                                
                commit('setUser_uploads_states_chart', response.data);
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
    },



}