<template>
  <dx-scroll-view height="100%" width="100%" class="login-bg with-footer single-card">
    <div class="dx-card content">
      <div class="header">
        <div class="title" @click="gotoHome" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">کلینیک
          دندان پزشکی راد</div>
        <hr>
        <div class="description" data-aos="zoom-in-down" data-aos-easing="linear" data-aos-duration="1500">لطفا اطلاعات حساب
          خود را وارد نمایید</div>
      </div>
      <slot />
    </div>
  </dx-scroll-view>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";

import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';

export default {
  components: {
    DxScrollView
  },
  setup() {
    const route = useRoute();

    const title = ref(route.meta.title);
    const description = ref("");

    watch(() => route.path,
      () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
      }
    )
    return {
      title,
      description
    }
  },
  methods: {
    gotoHome() {
      this.$router.push({ name: 'home' })
    }
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.login-bg {

  // background-image: url(~@/assets/img/bg_6.jpg);
  background-image: url(~@/assets/img/m7.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.single-card {
  // width: 100%;
  // height: 100%;
  font-family: dana;


  .dx-card {
    width: 330px;
    margin: auto auto;
    padding: 40px;
    flex-grow: 0;
    // position: absolute;
    // left: 15%;
    // top: 10%;
    border-radius: 10px;
    font-family: dana;
    opacity: 0.95;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;

      .title {
        box-shadow: 1px solid #311e1261;
        padding: 5px;
        color: $base-text-color;
        line-height: 28px;
        font-weight: 500;
        font-size: 20px;
        font-family: dana;
        margin-bottom: 7px;
        text-align: center;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 16px;
        font-family: dana;
      }
    }
  }

}

@media only screen and (max-width: 700px) {
  .dx-card {
    width: 100vw !important;
    // margin: 0 auto !important;
    padding: 40px;
    flex-grow: 0;
    // margin: -57px !important;
    // right: 15%;
    // top: 10%;
  }
}
</style>
