import axios from 'axios'
import notify from 'devextreme/ui/notify';
import errorHandler from '@/components/errorHandler';

export default {

    getFileTypes({commit},data){   
        debugger                     
        axios
        .get('FileTypes/GetFileTypeList')
        .then((response)=>{
            debugger                                                
            commit('setFileTypes',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    addNewFileTypes({commit},data){
        debugger
        axios
        .post('FileTypes/AddNewFileType',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    editFileTypes({commit},data){
        debugger
        axios
        .post('FileTypes/UpdateFileType',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    deleteFileTypes({commit},data){ 
        axios
        .post('FileTypes/DeleteFileType?id='+data)
        .then((response)=>{            
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            } 
        })
        .catch(error => {
            debugger
            errorHandler.methods.handleError(error);
        })
    },

}