export default {
    getMenus : state => {                
        return state.menus;
    },
    getSubMenus : state => {                
        return state.submenus;
    },
    getTotalMenus : state => {                
        return state.totalmenus;
    },
    getRootMenus : state => {                
        return state.rootmenus;
    }
}