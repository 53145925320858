export default {   
    setMenus(state,data)
    {                      
        state.parts=data;
    },
    setSubMenus(state,data)
    {                      
        state.submenus=data;
    },
    setTotalMenus(state,data)
    {                      
        state.totalmenus=data;
    },
    setRootMenus(state,data)
    {
        state.rootmenus = data;
    }
}