<template>
  <div class="content-block">
    <footer class="footer">
      <div class="footer-container">
        <div class="footer-links">
          <div class="footer-row">
            <img src="@/assets/img/address-48.png" />
            <p>تلفن تماس : 33288833 - 33288844</p>
          </div>
          <div class="footer-row">
            <img src="@/assets/img/phone-94.png" />
            <p>آدرس : میدان آزادی-ابتدای خیابان فلسطین ، روبروی آموزش و پروش ناحیه 1</p>
          </div>
          <span class="copy-year">
            <div class="footer-row">
              <img src="@/assets/img/copyright-48.png" />
              <p>Copyright © {{ new Date().getFullYear() }}</p>
            </div>
          </span>
          <div class="footer-row">
            <img src="@/assets/img/source-code-48.png" />
            <p>طراح:<a class="my-web" href="https://agrincoder.ir" target="_blank" rel="noreferrer">آروین خاوریان مقدم</a>
            </p>
          </div>
          <!-- {{this.$appInfo.title}}  -->
          <div class="footer-row">
            <img src="@/assets/img/warning-48.png" />
            <p>تمامی حقوق مادی و معنوی این سایت متعلق به <a @click="gotoLoginAdmin">کلینیک راد</a> می باشد</p>
          </div>
        </div>

        <div class="footer-logo">
          <img src="@/assets/logo.png" />
        </div>
      </div>

    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    gotoLoginAdmin(){
      this.$router.push({name:'login-form-admin'});
    },
    gotoMyWeb() {
      window.open("www.agrincoder.ir", "_blank")
    }
  }
}
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.footer {
  display: block;
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  padding-bottom: 24px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;
  border-radius: 10px;

  .footer-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;

    .footer-logo {
      position: absolute;
      top: -20%;
      left: 2%;
      width: 15%;
      transition: transform .5s ease-in-out;


      &:hover {
        transform: translateX(+10px);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }



    .footer-links {

      .footer-row {
        display: flex;
        align-items: center;
        width: 100%;

        p {
          padding-top: 15px;
        }

        img {
          width: 25px;
          height: auto;
          object-fit: contain;
          margin-left: 6px;
          opacity: .6;
        }
      }
    }
  }



  .copy-year {
    color: #123123;
    font-weight: 500;
  }

  .my-web {
    padding: 3px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    transition: transform .3s ease-in-out;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }

  @media screen and (max-width:720px) {
    .footer-logo {
      top: 0% !important;
    }
  }
}
</style>
