export default { 
    setBasePermissions(state,data)
    {
        debugger
        state.BasePermissions = data;
    },
    setRolesWithPermissions(state,data)
    {
        state.RolesWithPermissions=data;
    },
    setBaseRoles(state,data)
    {
        state.BaseRoles=data;
    },
    setUserRoles(state,data)
    {                      
        state.UserRoles=data;
    },
    setMenuRolesDashboard(state,data)
    {                      
        state.MenuRolesDashboard=data;
    },
    setUserRolesPermissions(state,data)
    {
        state.UserRolesPermissions=data;
    },
    setUserCanUpdateEdmsStatus(state,data)
    {
        state.UserCanUpdateEdmsStatus=data;
    }
}