import axios from 'axios'
import notify from 'devextreme/ui/notify';
import errorHandler from '@/components/errorHandler';

export default {

    getBaseDoctorList({commit},data){   
        // debugger                     
        axios
        .get('Doctors/GetBaseDoctorList')
        .then((response)=>{
            // debugger                                                
            commit('setBaseDoctorList',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    getDoctorList({commit},data){   
        // debugger                     
        axios
        .get('Doctors/GetDoctorList')
        .then((response)=>{
            // debugger                                                
            commit('setDoctorList',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    addNewDoctor({commit},data){
        debugger
        axios
        .post('Doctors/AddNewDoctor',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    editDoctor({commit},data){
        debugger
        axios
        .post('Doctors/UpdateDoctor',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    deleteDoctor({commit},data){ 
        debugger
        axios
        .post('Doctors/DeleteDoctor?id='+data)
        .then((response)=>{            
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            } 
        })
        .catch(error => {
            debugger
            errorHandler.methods.handleError(error);
        })
    },

}