import axios from 'axios'
import notify from 'devextreme/ui/notify';
import errorHandler from '@/components/errorHandler';

export default {

    addRoleUser({commit},data){   
        debugger                     
        axios
        .post('UserRoles/AddNewRoletoUser',data)
        .then((response)=>{
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }  
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    }, 
    addNewRolePermission({commit},data){   
        debugger                     
        axios
        .post('RolePermission/AddNewRolePermission',data)
        .then((response)=>{
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }  
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    }, 
    addNewRole({commit},data){   
        debugger                     
        axios
        .post('RolePermission/AddNewRole',data)
        .then((response)=>{
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }  
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    }, 
    deleteRole({commit},data){   
        debugger                     
        axios
        .post('RolePermission/DeleteRoleWithPermissions?role_id='+data)
        .then((response)=>{
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }  
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    }, 
    getAllRolesBindWithMenus({commit},data){   
        debugger                     
        axios
        .get('RolePermission/GetRolesMenuConnectionDashboard',data)
        .then((response)=>{
            debugger                                                
            commit('setMenuRolesDashboard',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },   
    getUserPermissionForInProgress({commit},data){                 
        const operation_holder = 'Per:UserUploadEDMSController:setFileUserUploadState'               
        axios
        .get("RolePermission/IsUserAllowedForOperation?user_id=" + localStorage.getItem('userId')
        + "&operation="+operation_holder)
        .then((response)=>{
            debugger                                     
            if(response.status === 200)
            {
                commit('setUserCanUpdateEdmsStatus',true);
            }   
            else 
            {
                commit('setUserCanUpdateEdmsStatus',false);
                notify(response.data.message, "warning", this.notifyTimeSet);
            }        
            
        })
        .catch(error => {
            debugger
            errorHandler.methods.handleError(error);
        })
    }, 
    getAllPermissions({commit},data){   
        debugger                     
        axios
        .get('RolePermission/GetAllPermissions',data)
        .then((response)=>{
            debugger                                                
            commit('setBasePermissions',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },  
    getUserRoles({commit},data){   
        debugger                             
        axios
        .get('UserRoles/GetUsersWithRolePermissions',data)
        .then((response)=>{   
            debugger                                                         
            commit('setUserRoles',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },  
    getUserRolesByUserId({commit},data){   
        debugger                             
        axios
        .get('UserRoles/GetUserWithRolePermissionsById?uid='+data)
        .then((response)=>{   
            debugger                                                         
            commit('setUserRolesPermissions',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },  
    getAllRoles({commit},data){        
        axios
        .get('RolePermission/GetAllRoles',data)
        .then((response)=>{        
            debugger                                                    
            commit('setBaseRoles',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    }, 
    getRolesWithPermissions({commit},data){
        debugger
        axios
        .get('RolePermission/GetRolesWithPermissions',data)
        .then((response)=>{
            debugger                                                
            commit('setRolesWithPermissions',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },  
    addNewUserRoles({commit},data){
        debugger
        axios
        .post('UserRoles/AddNewCompany',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    editUserRole({commit},data){
        debugger
        axios
        .patch('UserRoles/UpdateCompany',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    deleteUserRoleByUserIdRoleId({commit},data){ 
        debugger
        axios
        .post('UserRoles/DeleteUserRoleByUserIdRoleId?uid='+data.uid+'&role_id='+data.role_id)
        .then((response)=>{            
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            } 
        })
        .catch(error => {
            debugger
            errorHandler.methods.handleError(error);
        })
    },
    deleteUserRole({commit},data){ 
        axios
        .post('UserRoles/DeleteCompany?id='+data)
        .then((response)=>{            
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            } 
        })
        .catch(error => {
            debugger
            errorHandler.methods.handleError(error);
        })
    },

}