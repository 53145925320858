<template>
  <div>
    <h2 class="content-block">حساب کاربری</h2>

    <div class="content-block dx-card responsive-paddings">
      <div class="form-avatar">
        <!-- <img :src="imageSrc" /> -->
        <img src="@/assets/img/businessman.png" />
      </div>
      <span>{{ formData.Notes }}</span>
    </div>

    <div class="content-block dx-card responsive-paddings">
      <dx-form
        id="form"
        label-location="top"
        :form-data="formData"
        :colCountByScreen="colCountByScreen"
      />
    </div>
  </div>
</template>

<script>
import DxForm from "devextreme-vue/form";

export default {
  props: {
    picture: String
  },
  setup() {
    
    const imageSrc = '../../assets/img/businessman.png';//`https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/${picture}`;
    const formData = {
      ID: localStorage.getItem('userId'),
      UserName: localStorage.getItem('userName'),
      UserInfo: localStorage.getItem('userInfo'),
      Part : localStorage.getItem('userPart'),
      // LastName: '',
      // Prefix: "Mrs.",
      // Position: "Controller",
      // Picture: picture,
      // BirthDate: new Date("1974/11/5"),
      // HireDate: new Date("2005/05/11"),
      Notes:
        "اطلاعات اولیه حساب کاربری شما در این قسمت قابل مشاهده می باشد " +"\n"+
        "لطفا در صورت نیاز برای اصلاح آن با اپراتور تماس حاصل نمایید" ,      
    };
    const colCountByScreen = {
      xs: 1,
      sm: 2,
      md: 3,
      lg: 4
    }

    return {
      imageSrc,
      formData,
      colCountByScreen
    };
  },
  components: {
    DxForm
  }
};
</script>

<style lang="scss">
.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}
</style>
