export default {
    getTitle : state => {
        return state.title;
    },
    getUsers : state => {        
        return state.users;
    },
    getUser_menu_roles : state =>{
        return state.user_menu_roles;
    },
    getUsers_in_part: state =>{        
        return state.users_in_part;
    }
}