import notify from "devextreme/ui/notify"

export default {
    data(){
        return{

        }
    },
    methods:{
        handleError(data){
            // debugger
            // notify(data,"error",this.notifyTimeSet);

            if(data.message.includes('401'))
            {
                notify('کاربر گرامی ، شما دسترسی برای انجام این عملیات را ندارید.در صورت نیاز برای رفع مشکل به مدیریت سیستم مراجعه فرمایید'
                +'\n'+data.response.data.message,"error",this.notifyTimeSet);
            }
            else if(data.message.includes('405'))
            {
                notify('کاربر گرامی ، شما دسترسی برای انجام این عملیات را ندارید.در صورت نیاز برای رفع مشکل به مدیریت سیستم مراجعه فرمایید'
                +'\n405\n'+data.response.data.message,"error",this.notifyTimeSet);
            }
            else  if(data.message.includes('Network Error'))
            {
                notify('کاربر گرامی ، ارتباط با سرور قطع می باشد.در صورت نیاز برای رفع مشکل به مدیریت سیستم مراجعه فرمایید',"error",this.notifyTimeSet);
            }
            else {
                notify(data,"error",this.notifyTimeSet);
            }

            // if(data.response.data.message != null && data.response.data.message != undefined)
            // {
            //     notify(data.response.data.message,"error",this.notifyTimeSet);
            // }
            console.log('Error => ',data)
        }
    }, 
}