import axios from 'axios'
import notify from 'devextreme/ui/notify';
import errorHandler from '@/components/errorHandler';


// axios.defaults.baseURL = 'https://api.rad-clinic.ir/api/';

// axios.defaults.baseURL = 'http://192.168.1.16:8002/api/';


axios.defaults.baseURL = 'https://localhost:7005/api/';



axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.delete['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';




export default {

    //callTitleMain({dispatch,commit}){
    loginUserNormal({ commit }, data) {
        try {
            debugger
            commit('setLoginLoading', true);
            axios
                .post('Authenticate/LoginNormalUser', data)
                .then(response => {
                    debugger
                    if (response && response.status === 200) {
                        debugger
                        commit('setLoginLoading', false);
                        if (response.data.status === 200) {
                            debugger
                            localStorage.setItem('userId', response.data.userId);
                            localStorage.setItem('userName', response.data.userName);
                            localStorage.setItem('userInfo', response.data.userInfo);
                            localStorage.setItem('token', response.data.token);
                            localStorage.setItem('expiration', response.data.expiration);
                            localStorage.setItem('roles', response.data.savedRoles);
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;
                            commit('setUserInfo', response.data);
                        }
                        else {
                            notify(response.data.message, "error", this.notifyTimeSet);
                        }
                    }


                })
                .catch(error => {
                    if (error.message === "Network Error") {
                        notify('ارتباط با سرور قطع می باشد ، لطفا با اپراتور سیستم اطلاع رسانی نمایید', 'error', this.notifyTimeSet);
                    }
                    else {
                        notify(error.message, 'error', this.notifyTimeSet);
                    }
                    commit('setLoginLoading', false);
                })
        } catch (error) {
            this.errorHandler(error);
        }


    },
    loginUser({ commit }, data) {
        try {
            debugger
            commit('setLoginLoading', true);
            axios
                .post('Authenticate/login', data)
                .then(response => {
                    debugger
                    if (response && response.status === 200) {
                        debugger
                        commit('setLoginLoading', false);
                        if (response.data.status === 200) {
                            debugger
                            
                            localStorage.setItem('userId', response.data.userId);
                            localStorage.setItem('userName', response.data.userName);
                            localStorage.setItem('userInfo', response.data.userInfo);
                            localStorage.setItem('token', response.data.token);
                            localStorage.setItem('expiration', response.data.expiration);
                            localStorage.setItem('roles', response.data.savedRoles);
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;
                            commit('setUserInfo', response.data);
                        }
                        else {
                            notify(response.data.message, "error", this.notifyTimeSet);
                        }
                    }


                })
                .catch(error => {
                    if (error.message === "Network Error") {
                        notify('ارتباط با سرور قطع می باشد ، لطفا با اپراتور سیستم اطلاع رسانی نمایید', 'error', this.notifyTimeSet);
                    }
                    else {
                        notify(error.message, 'error', this.notifyTimeSet);
                    }
                    commit('setLoginLoading', false);
                })
        } catch (error) {
            this.errorHandler(error);
        }


    },

    callTitleMain({ commit }) {

        commit('setTitleMain', 'this is new data for title Main!');
    },

    callApi({ commit }) {
        axios
            .get('https://jsonplaceholder.typicode.com/todos/1')
            .then((response) => {
                debugger
                commit('setTitleMain', response.data.title);
            })
            .catch(error => {
                this.errorHandler(error);
            })
    },

}