<template>
  <div>
    <h2 class="content-block">{{ $t('role_permission.title') }}</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <DxDataGrid :data-source="roles_permissions_datasource" :columns="roles_permissions_datasource_columns"
          :show-row-lines="showRowLines" :show-column-lines="showColumnLines" :row-alternation-enabled="true"
          :rtl-enabled="true" :remote-operations="true" :column-auto-width="true" :allow-column-reordering="true"
          :allow-column-resizing="true" :hover-state-enabled="true" :noDataText="checkData"
          :selection="{ mode: 'single' }" @toolbar-preparing="onToolbarPreparing"
          @selection-changed="onSelectionChanged" class="mt-3" id="ps_Table"
          ref="main_grid" key-expr="rolesId">

          <DxColumnChooser :enabled="true" />
          <DxExport :enabled="allowExportDataGrid" :allow-export-selected-data="allowExportSelectedDataGrid" />

          <DxHeaderFilter :visible="showHeaderFilter" />
          <DxSearchPanel :visible="true" :width="190" :placeholder="this.$i18n.t('role_permission.grid_search')"
            class="" />

          <!-- <DxSelection
                      :select-all-mode="allMode"
                      :show-check-boxes-mode="checkBoxesMode"
                      mode="multiple"
                    /> -->

          <DxScrolling mode="standard" :use-native="true"/>
          <DxPaging :enabled="false" />

          <template #btnGroup class="row">
            <div class="d-lg-none">
              <div class="mt-2">
                <DxDropDownButton :items="Dropactions" :drop-down-options="{ width: 190 }"
                  :text="this.$i18n.t('role_permission.grid_operation')" icon="save" @item-click="onItemClick" />
              </div>
            </div>
            <div class="d-none d-lg-block">
              <DxButton class="ml-2 bg-primary" color="success" size="sm" icon="menu" @click="showRoleMenus()">
              </DxButton>
              <DxButton color="secondary" class="ml-2" size="sm" icon="revert" @click="$router.go(-1)">
              </DxButton>
              <DxButton color="light" size="sm" icon="refresh" @click="reloadMain()">
              </DxButton>
            </div>
          </template>


          <template #btnRGroup class="p-0 m-0">
            <div class="d-none d-lg-block">
              <div class="row roles_buttons">
                <div class="col-sm-5">
                  <button @click="setNewRoleDialog" class="btn w-100 btn-sm mr-1 bg-warning" size="sm">{{
                      $t('role_permission.new_role_definition')
                  }}</button>
                </div>
                <div class="col-sm-7">
                  <button @click="setRolePermissionsDialog" class="btn w-100 btn-sm mr-1 bg-warning" size="sm">{{
                      $t('role_permission.assing_role_access')
                  }}</button>
                </div>
              </div>
            </div>

          </template>
          <template #actions="{ data }">
            <div>
              <!-- <DxButton
                        class="bg-warning m-1"
                        icon="edit"
                        type="edit" 
                        title="ویرایش"
                        @click="setEdit(data)"> 
                        </DxButton>-->
              <DxButton class="bg-danger m-1" icon="remove" :title="this.$i18n.t('role_permission.grid_btn_delete_1')"
                @click="setDeleteDialog">
              </DxButton>
            </div>
          </template>
          <template #radif="{ data }">
            {{ data.rowIndex + 1 }}
          </template>
          <DxFilterRow :visible="true" />
          <DxLoadPanel :enabled="true" />
          <DxScrolling mode="standard" :use-native="true"/>
        </DxDataGrid>

      </div>
    </div>

    <!-- فرم اختصاص دسترسی ها به نقش -->
    <DxPopup style="text-align: :center;" :width="this.showDialogNewWidth" :height="this.showDialogNewHeight"
      :show-title="true" :title="this.$i18n.t('role_permission.role_access_assingment_form')" alignment="center"
      :drag-enabled="true" :hide-on-outside-click="false" v-model:visible="showDialogSavePermissionToRole">
      <template #content="{ data }">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">

            <div class="card">
              <!-- <h5 class="card-header">فرم اطلاعات کاربر</h5> -->
              <div class="card-body">

                <form>


                  <!-- Height-Width-Controller -->
                  <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                      <div class="d-flex flex-row">
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Height-Width-Controller -->

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-3" />
                          </span>
                        </div>
                        <DxDropDownBox v-model:value="gridBoxValue" v-model:opened="isDropDownBoxOpened"
                          :defer-rendering="false" :display-expr="gridBoxDisplayExpr" :show-clear-button="true"
                          :data-source="all_roles_datasource" value-expr="id" width="80%"
                          :placeholder="this.$i18n.t('role_permission.part_selection')">
                          <template #content="{ data }">
                            <DxDataGrid :data-source="roles_datasource" :columns="rolesListColumns"
                              :hover-state-enabled="true" v-model:selected-row-keys="gridBoxValue"
                              @selection-changed="changeDropDownBoxValue($event)" height="100%" ref="grid_roles">
                              <DxSelection mode="single" />
                              <DxPaging :enabled="false" />
                              <DxFilterRow :visible="true" />
                              <DxScrolling mode="standard" :use-native="true"/>
                              <template #radif_1="{ data }">
                                {{ data.rowIndex + 1 }}
                              </template>
                            </DxDataGrid>
                          </template>
                        </DxDropDownBox>
                      </div>
                    </div>

                    <DxDataGrid :data-source="permissions_datasource" :columns="permissions_datasource_columns"
                      :show-row-lines="showRowLines" :show-column-lines="showColumnLines"
                      :row-alternation-enabled="true" :rtl-enabled="true" :remote-operations="true"
                      :column-auto-width="true" :allow-column-reordering="true" :allow-column-resizing="true"
                      :hover-state-enabled="true" :noDataText="checkData" :selection="{ mode: 'single' }"
                      @selection-changed="onSelectionChangedPermission" class="mt-3" id="ps_Table" key-expr="id"
                      ref="permissions_grid">


                      <DxHeaderFilter :visible="showHeaderFilter" />
                      <DxSearchPanel :visible="true" :width="190"
                        :placeholder="this.$i18n.t('role_permission.grid_permissin_search')" class="" />

                      <DxSelection :select-all-mode="allMode" :show-check-boxes-mode="checkBoxesMode" mode="multiple" />

                      <DxScrolling mode="standard" :use-native="true"/> <!-- or "virtual" | "infinite" -->


                      <template #action_permission="{ data }">
                        <div>
                          <DxButton class="bg-success m-1" icon="add"
                            :title="this.$i18n.t('role_permission.grid_permissin_assign')"
                            @click="savePermmissionToRole">
                          </DxButton>
                        </div>
                      </template>
                      <template #radif_Role="{ data }">
                        {{ data.rowIndex + 1 }}
                      </template>
                      <DxFilterRow :visible="true" />
                      <DxLoadPanel :enabled="true" />
                      <DxScrolling mode="standard" :use-native="true"/>
                      <DxPaging :enabled="false" />
                    </DxDataGrid>

                  </div>

                  <button class="btn btn-success mt-3 me-1 ms-2" @click="saveNewPermissionsToRole"><i
                      class="fa fa-home"></i>{{ $t('role_permission.btn_save') }}</button>
                  <button class="btn btn-warning mt-3" @click="setRolePermissionsDialogClose"><i
                      class="fa fa-home"></i>{{ $t('role_permission.btn_back') }}</button>
                </form>

                <div class="card-footer text-muted mt-2">{{ $t('role_permission.dialog_new_footer_label') }}</div>
              </div>
            </div>

          </div>
        </DxScrollView>
      </template>
    </DxPopup>

    <DxPopup style="text-align: :center;" :width="this.showDialogNewWidth" :height="this.showDialogNewHeight"
      :show-title="true" :title="this.$i18n.t('role_permission.new_role_definition_2')" alignment="center"
      :drag-enabled="true" :hide-on-outside-click="false" v-model:visible="showDialogSaveNewRole">
      <template #content="{ data }">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">

            <div class="card">
              <div class="card-body">
                <form>


                  <!-- Height-Width-Controller -->
                  <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                      <div class="d-flex flex-row">
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Height-Width-Controller -->

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewRoleTitle" @input="this.check_content($event, 'inputName')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputName'), 'border-success-my': this.isStateOk('inputName'), 'rounded': true }"
                          :placeholder="this.$i18n.t('role_permission.new_role_title_fa')" type="text">
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewRoleTitleEn" @input="this.check_content($event, 'inputNameEn')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputNameEn'), 'border-success-my': this.isStateOk('inputNameEn'), 'rounded': true }"
                          :placeholder="this.$i18n.t('role_permission.new_role_title_en')" type="text">
                      </div>
                    </div>
                  </div>

                  <button v-if="editMode" class="btn btn-success mt-3 me-1 ms-2" @click="editPartInfo($event)"><i
                      class="fa fa-home"></i>{{ $t('role_permission.new_role_edit') }}</button>
                  <button v-else class="btn btn-success mt-3 me-1 ms-2" @click="saveNewRole"><i
                      class="fa fa-home"></i>{{ $t('role_permission.new_role_save') }}</button>
                  <button class="btn btn-warning mt-3" @click="this.showDialogSaveNewRole = false;"><i
                      class="fa fa-home"></i>{{ $t('role_permission.new_role_back') }}</button>
                </form>

                <div class="card-footer text-muted mt-2">{{ $t('role_permission.dialog_new_role_footer_label') }}</div>
              </div>
            </div>

          </div>
        </DxScrollView>
      </template>
    </DxPopup>




    <DxPopup style="text-align: :center;" :width="this.showDialogNewWidth" :height="this.showDialogNewHeight"
      :show-title="true" :title="titlePartForUser" alignment="center" :drag-enabled="true"
      :hide-on-outside-click="false" v-model:visible="showDialogRoleMenus">
      <template #content="{ data }">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">

            <div class="card">
              <div class="card-body">

                <form>


                  <!-- Height-Width-Controller -->
                  <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                      <div class="d-flex flex-row">
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Height-Width-Controller -->

                  <div class="row">
                    <div class="col-sm-12">
                      <DxDataGrid :data-source="roles_menu_dashboard" :columns="roles_menu_columns"
                        ref="role_menu_grid" :show-row-lines="showRowLines" :show-column-lines="showColumnLines"
                        :row-alternation-enabled="true" :rtl-enabled="true" :remote-operations="true"
                        :column-auto-width="true" :allow-column-reordering="true" :allow-column-resizing="true"
                        :hover-state-enabled="true" :noDataText="checkData" :selection="{ mode: 'single' }" class="mt-3"
                        id="ps_Table" key-expr="roleId" >

                        <DxColumnChooser :enabled="true" />
                        <DxExport :enabled="allowExportDataGrid"
                          :allow-export-selected-data="allowExportSelectedDataGrid" />


                        <DxHeaderFilter :visible="showHeaderFilter" />
                        <DxSearchPanel :visible="true" :width="190"
                          :placeholder="this.$i18n.t('role_permission.grid_roles_menu_search')" class="" />

                        <!-- <DxSelection
                            :select-all-mode="allMode"
                            :show-check-boxes-mode="checkBoxesMode"
                            mode="multiple"
                          /> -->



                        <template #radif_menu="{ data }">
                          {{ data.rowIndex + 1 }}
                        </template>
                        <DxPaging :enabled="false" />
                        <DxFilterRow :visible="true" />
                        <DxLoadPanel :enabled="true" />
                        <DxScrolling mode="standard" :use-native="true"/>
                      </DxDataGrid>
                    </div>
                  </div>

                  <!-- <button class="btn btn-success mt-3 me-1 ms-2" @click="saveNewPartForUser"><i class="fa fa-home"></i>ذخیره</button>  -->
                  <button class="btn btn-warning mt-3" @click="showDialogRoleMenus = false"><i class="fa fa-home"></i>{{
                      $t('role_permission.grid_roles_btn_back')
                  }}</button>
                </form>

                <div class="card-footer text-muted mt-2">{{ $t('role_permission.grid_roles_footer_label') }}</div>
              </div>
            </div>

          </div>
        </DxScrollView>
      </template>
    </DxPopup>







    <customDial :show="showDeleteDialog" :cancel="() => { this.showDeleteDialog = false; }" :confirm="delRole"
      :title="this.$i18n.t('role_permission.delete_dialog_title')" :userPersonalInfo="RoleTitleSelected"
      :description="this.$i18n.t('role_permission.delete_dialog_body')" />

  </div>
</template>
<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxForm, DxItem } from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import { DxDataGrid, DxSelection } from 'devextreme-vue/data-grid';
import { computed } from '@vue/reactivity';
import { dxGrid } from '@/components/dxGrid'
import DxButton from 'devextreme-vue/button';
import { onMounted, reactive, ref } from 'vue';
import { mapGetters } from 'vuex';
import { useStore } from "vuex";
import MyValidator from '@/components/MyValidator'
import notify from 'devextreme/ui/notify';
import customDial from '@/components/customDialog.vue'
import themes from 'devextreme/ui/themes';
import DxDropDownButton from 'devextreme-vue/drop-down-button';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { DxDropDownBox } from 'devextreme-vue/drop-down-box';
import users_compo from '@/components/users_compo.vue'
import responsives from '@/utils/responsives';

export default {
  setup() {
    const store = useStore();
    return {
      store,
      roles_permissions_datasource: computed(() => store.getters['roles/getRolesWithPermissions']),
      roles_datasource: computed(() => store.getters['roles/getBaseRoles']),
      permissions_datasource: computed(() => store.getters['roles/getBasePermissions']),
      roles_menu_dashboard: computed(() => store.getters['roles/getMenuRolesDashboard']),

    }
  },
  mixins: [dxGrid, MyValidator, responsives],
  components: {
    DxButton,
    DxDataGrid,
    DxPopup,
    DxForm,
    DxSelectBox,
    DxDropDownButton,
    DxScrollView,
    DxDropDownBox,
    customDial,
    users_compo
  },
  data() {
    return {
      roles_menu_columns: [
        { dataField: 'roleId', caption: this.$i18n.t('role_permission.grid_row_roles_roleId'), dataType: 'number', visible: false },
        { dataField: 'roleNameEn', caption: this.$i18n.t('role_permission.grid_row_roles_roleNameEn'), dataType: 'string' },
        { dataField: 'roleNameFa', caption: this.$i18n.t('role_permission.grid_row_roles_roleNameFa'), dataType: 'string' },
        { dataField: 'mainMenuId', caption: this.$i18n.t('role_permission.grid_row_roles_mainMenuId'), dataType: 'string', visible: false },
        { dataField: 'mainMenuText', caption: this.$i18n.t('role_permission.grid_row_roles_mainMenuText'), dataType: 'string' },
        { dataField: 'subMenuId', caption: this.$i18n.t('role_permission.grid_row_roles_subMenuId'), dataType: 'string', visible: false },
        { dataField: 'menuText', caption: this.$i18n.t('role_permission.grid_row_roles_menuText'), dataType: 'string' },
      ],
      showDialogRoleMenus: false,
      permissions_datasource_columns: [
        // { cellTemplate: "radif_permission", caption: "ردیف", dataType: 'number', allowFiltering: false,width: 50, alignment: "center"},
        // { cellTemplate: 'action_permission', caption: 'عملیات', allowFiltering: false, allowSorting: false },
        { dataField: 'id', caption: this.$i18n.t('role_permission.grid_permisison_row_id'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'permissionTitle', caption: this.$i18n.t('role_permission.grid_permisison_row_permissionTitle'), dataType: 'string', alignment: 'center' },
        { dataField: 'permissionTitleEn', caption: this.$i18n.t('role_permission.grid_permisison_row_permissionTitleEn'), dataType: 'string', alignment: 'center' },
      ],
      gridBoxValue: [3],
      rolesListColumns: [
        // { cellTemplate: "radif_1", caption: "ردیف", dataType: 'number', allowFiltering: false},
        { dataField: 'id', caption: this.$i18n.t('role_permission.grid_roles_row_id'), dataType: 'number', visible: false },
        { dataField: 'nameFa', caption: this.$i18n.t('role_permission.grid_roles_row_nameFa'), dataType: 'string' },
        { dataField: 'nameEn', caption: this.$i18n.t('role_permission.grid_roles_row_nameEn'), dataType: 'string' },
      ],
      isDropDownBoxOpened: false,
      selectedRole: {},
      selectedRoleText: '',
      showDialogSavePermissionToRole: false,
      showDeleteDialog: false,
      row_permission_Selected: [],
      PermissionTitleSelected: '',
      row_Role_Selected: {},
      RoleTitleSelected: '',
      showDialogSaveNewRole: false,
      NewRoleTitle: '',
      NewRoleTitleEn: '',
      roles_permissions_datasource_columns: [
        { cellTemplate: "radif", caption: this.$i18n.t('role_permission.grid_role_permission_row_radif'), dataType: 'number', allowFiltering: false, width: 50, alignment: "center" },
        { cellTemplate: 'actions', caption: this.$i18n.t('role_permission.grid_role_permission_row_actions'), allowFiltering: false, allowSorting: false },
        { dataField: 'rolesId', caption: this.$i18n.t('role_permission.grid_role_permission_row_rolesId'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'roleName', caption: this.$i18n.t('role_permission.grid_role_permission_row_roleName'), dataType: 'string', alignment: 'center', format: { type: "fixedPoint", precision: 0 } },
        { dataField: 'roleNameFa', caption: this.$i18n.t('role_permission.grid_role_permission_row_roleNameFa'), dataType: 'string', alignment: 'center' },
        { dataField: 'permissionsId', caption: this.$i18n.t('role_permission.grid_role_permission_row_permissionsId'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'permissionTitle', caption: this.$i18n.t('role_permission.grid_role_permission_row_permissionTitle'), dataType: 'string', alignment: 'center' },
        { dataField: 'permissionTitleEn', caption: this.$i18n.t('role_permission.grid_role_permission_row_permissionTitleEn'), dataType: 'string', alignment: 'center' },
        { dataField: 'permissionRoleId', caption: this.$i18n.t('role_permission.grid_role_permission_row_permissionRoleId'), dataType: 'string', alignment: 'center', visible: false },
      ],
      Dropactions: [
        // { id: 1, text: "تعریف بخش جدید", icon: "user" },
        { id: 2, text: this.$i18n.t('role_permission.drop_down_toolbar_new_reload'), icon: "refresh" },
        { id: 3, text: this.$i18n.t('role_permission.grid_role_permission_row_permissionTitle'), icon: "undo" },
        { id: 4, text: this.$i18n.t('role_permission.drop_down_toolbar_new_role'), icon: "preferences" },
        { id: 5, text: this.$i18n.t('role_permission.drop_down_toolbar_role_access'), icon: "preferences" },
      ],
      allMode: 'allPages',
      checkBoxesMode: themes.current().startsWith('material') ? 'always' : 'onClick',
    }
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      debugger
     
      const dataGrid = this.$refs.main_grid.instance;
      this.$refs.main_grid.columns[0].caption = this.$i18n.t('role_permission.grid_role_permission_row_radif');
      this.$refs.main_grid.columns[1].caption = this.$i18n.t('role_permission.grid_role_permission_row_actions');
      this.$refs.main_grid.columns[2].caption = this.$i18n.t('role_permission.grid_role_permission_row_rolesId');
      this.$refs.main_grid.columns[3].caption = this.$i18n.t('role_permission.grid_role_permission_row_roleName');
      this.$refs.main_grid.columns[4].caption = this.$i18n.t('role_permission.grid_role_permission_row_roleNameFa');
      this.$refs.main_grid.columns[5].caption = this.$i18n.t('role_permission.grid_role_permission_row_permissionsId');
      this.$refs.main_grid.columns[6].caption = this.$i18n.t('role_permission.grid_role_permission_row_permissionTitle');
      this.$refs.main_grid.columns[7].caption = this.$i18n.t('role_permission.grid_role_permission_row_permissionTitleEn');
      this.$refs.main_grid.columns[8].caption = this.$i18n.t('role_permission.grid_role_permission_row_permissionRoleId');

      dataGrid.option('columns', dataGrid.option('columns'));

      if (this.$refs.grid_roles != null) {
        const grid_roles_holder = this.$refs.grid_roles.instance;
        this.$refs.grid_roles.columns[0].caption = this.$i18n.t('role_permission.grid_roles_row_id');
        this.$refs.grid_roles.columns[1].caption = this.$i18n.t('role_permission.grid_roles_row_nameFa');
        this.$refs.grid_roles.columns[2].caption = this.$i18n.t('role_permission.grid_roles_row_nameEn');
        grid_roles_holder.option('columns', dataGrid_customer_company.option('columns'));
      }

      
      if (this.$refs.permissions_grid != null) {
        const permissions_grid_holder = this.$refs.permissions_grid.instance;
        this.$refs.permissions_grid.columns[0].caption = this.$i18n.t('role_permission.grid_permisison_row_id');
        this.$refs.permissions_grid.columns[1].caption = this.$i18n.t('role_permission.grid_permisison_row_permissionTitle');
        this.$refs.permissions_grid.columns[2].caption = this.$i18n.t('role_permission.grid_permisison_row_permissionTitleEn');
        permissions_grid_holder.option('columns', dataGrid_customer_company.option('columns'));
      }


      

      if (this.$refs.role_menu_grid != null) {
        const role_menu_grid_holder = this.$refs.role_menu_grid.instance;
        this.$refs.role_menu_grid.columns[0].caption = this.$i18n.t('role_permission.grid_row_roles_roleId');
        this.$refs.role_menu_grid.columns[1].caption = this.$i18n.t('role_permission.grid_row_roles_roleNameEn');
        this.$refs.role_menu_grid.columns[2].caption = this.$i18n.t('role_permission.grid_row_roles_roleNameFa');
        this.$refs.role_menu_grid.columns[3].caption = this.$i18n.t('role_permission.grid_row_roles_mainMenuId');
        this.$refs.role_menu_grid.columns[4].caption = this.$i18n.t('role_permission.grid_row_roles_mainMenuText');
        this.$refs.role_menu_grid.columns[5].caption = this.$i18n.t('role_permission.grid_row_roles_subMenuId');
        this.$refs.role_menu_grid.columns[6].caption = this.$i18n.t('role_permission.grid_row_roles_menuText');
        role_menu_grid_holder.option('columns', dataGrid_customer_company.option('columns'));
      }

      
      

    }
  },
  created() {
    this.reloadData();
    this.make_state_bind('inputName', false, this.$i18n.t('role_permission.validation_role_name_fa_empty'));
    this.make_state_bind('inputNameEn', false, this.$i18n.t('role_permission.validation_role_name_en_empty'));
  },
  methods: {
    showRoleMenus() {
      debugger
      this.makeInitSize(600, 600);
      this.store.dispatch('roles/getAllRolesBindWithMenus');
      this.showDialogRoleMenus = true;
    },
    saveNewPermissionsToRole() {
      if (this.selectedRole === undefined || this.selectedRole === null) {
        notify(this.$i18n.t('role_permission.validation_assing_access_to_role'), 'warning', this.notifyTimeSet);
        return;
      }
      this.row_permission_Selected.forEach(element => {
        debugger
        const role_id_holder = this.selectedRole.id;
        const holder_role_permission = {
          PermissionId: element.id,
          RoleId: role_id_holder
        }
        this.store.dispatch('roles/addNewRolePermission', holder_role_permission);
      });
    },
    gridBoxDisplayExpr() {
      return this.selectedRoleText;
    },
    changeDropDownBoxValue(e) {
      this.selectedRole = e.currentSelectedRowKeys[0];
      this.isDropDownBoxOpened = false;
      if (this.selectedRole != undefined)
        this.selectedRoleText = this.selectedRole.nameFa;
    },
    setRolePermissionsDialogClose() {
      this.showDialogSavePermissionToRole = false;
      this.reloadData();
    },
    setRolePermissionsDialog() {
      debugger
      this.makeInitSize(600, 650);
      this.reloadDataPermissions();
      this.reloadDataRoles();
      this.showDialogSavePermissionToRole = true;
    },
    setDeleteDialog() {
      this.showDeleteDialog = true;
    },
    delRole() {
      debugger
      this.store.dispatch('roles/deleteRole', this.row_Role_Selected.rolesId);
      this.showDeleteDialog = false;
      setTimeout(() => {
        this.reloadMain();
        //this.reloadData();
      }, this.reloadTimeSet);
    },
    saveNewRole() {
      debugger
      if (this.isStateOkTotal() > 0) {
        let give_me_errors = this.getElementsWithErorrs();
        notify(give_me_errors, 'error', this.notifyTimeSet);
        return;
      }
      const new_role_holder = {
        Id: null,
        Name: this.NewRoleTitle,
        NormalizedName: null,
        ConcurrencyStamp: this.NewRoleTitleEn
      }
      this.store.dispatch('roles/addNewRole', new_role_holder);
      this.showDialogSaveNewRole = false;
      setTimeout(() => {
        this.reloadMain();
        //this.reloadData();
      }, this.reloadTimeSet);
    },
    setNewRoleDialog() {
      this.makeInitSize(600, 350);
      this.showDialogSaveNewRole = true;
    },
    reloadMain() {
      this.reloadData();
      this.reloadDataRoles();
      this.reloadDataPermissions();
    },
    reloadData() {
      this.store.dispatch('roles/getRolesWithPermissions');
    },
    reloadDataRoles() {
      this.store.dispatch('roles/getAllRoles');
    },
    reloadDataPermissions() {
      this.store.dispatch('roles/getAllPermissions');
    },
    onItemClick(e) {
      debugger
      switch (e.itemData.id) {
        case 1:
          this.showDialog = true;
          break;
        case 2:
          this.reloadMain();
          //this.reloadData();
          break;
        case 3:
          this.$router.go(-1);
          break;
        case 4:
          this.setNewRoleDialog();
          break;
        case 5:
          this.setRolePermissionsDialog();
          break;
        default:
          notify('خطای ناشناس در منو', 'warning', this.notifyTimeSet);
          break;
      }
    },
    onSelectionChangedPermission(selectedRowsData) {
      this.row_permission_Selected = selectedRowsData.selectedRowsData;
      //this.PermissionTitleSelected = this.row_Role_Selected.roleNameFa;             
    },
    onSelectionChanged(selectedRowsData) {
      debugger
      this.row_Role_Selected = selectedRowsData.selectedRowsData[0];
      this.RoleTitleSelected = this.row_Role_Selected.roleNameFa;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "btnRGroup",
      });
      e.toolbarOptions.items.unshift({
        location: "after",
        template: "btnGroup",
      });
    },
  },

}
</script>
<style lang="scss" scoped>
@import "@/dx-styles.scss";

.roles_buttons{
  width: 24vw;
}

.dx-popup-wrapper>.dx-overlay-content {
  background: #fff;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border-left-color: $popup_color;
  border-left-width: 9px;
}

#ps_Table {
  height: 60vh;
}
</style>