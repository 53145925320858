export default {
    getTitleMain : state => {
        return state.titleMain;
    },
    getUserId : state => {
        return state.userId;
    },
    getUserName : state => {
        return state.userName;
    },
    getUserToken : state => {
        return state.token;
    },
    getLoginLoading : state => {
        return state.login_loading;
    },
    getTokenExpires : state => {
        return state.toktokenExpires;
    },
    getUserInfo : state => {
        return state.userInfo;
    },
    getUserIsLogged : state => {
        return state.userIsLogged;
    },
}