<template>
    <div class="content-block">
        <div class="dx-card gallery-frame">
            <introBannerDoctors></introBannerDoctors>
        </div>
    </div>
</template>

<script>
import introBannerDoctors from "@/components/introBannerDoctors"
export default {
    components: {
        introBannerDoctors
    },
    data() {

    },
    methods: {

    }
}
</script>

<style lang="scss" scoped></style>