<template>
  <div class="p-1">
    <DxPieChart id="pie" ref="pieChartRef" v-show="loaded" :data-source="dataSource" palette="Bright"
      :title="ChartTitle">
      <DxTitle :text="ChartTitle">
        <DxFont family="Samim" color="black" />
      </DxTitle>
      <DxSeries argument-field="typeOp" value-field="valueOp">
        <DxLabel :visible="true" :customize-text="formatLabel" format="fixedPoint">
          <DxFont family="Samim" color="black" />
          <DxConnector :visible="true" :width="0.5" />
        </DxLabel>
        <DxSmallValuesGrouping :threshold="4.5" mode="smallValueThreshold" />
      </DxSeries>
      <DxLegend horizontal-alignment="center" vertical-alignment="bottom">
        <DxLabel :visible="true" :customize-text="formatLabel" format="fixedPoint">
          <DxFont family="Samim" color="black" />
        </DxLabel>
      </DxLegend>
      <DxExport :enabled="true" />
    </DxPieChart>
  </div>
</template>
  
<script>
import DxPieChart, {
  DxSeries,
  DxLabel,
  DxConnector,
  DxSmallValuesGrouping,
  DxLegend,
  DxExport,
  DxTitle,
  DxFont
} from "devextreme-vue/pie-chart";

// import { pie_dataSource } from "./data_line";

export default {
  components: {
    DxPieChart,
    DxSeries,
    DxLabel,
    DxConnector,
    DxSmallValuesGrouping,
    DxLegend,
    DxExport,
    DxTitle,
    DxFont
  },
  props: {
    ChartTitle: String,
    // ChartArgument:String,
    // ChartValue:Number,
    ChartData: Array
  },
  created() {
    //debugger
    console.log("chart pie : ", this.ChartData);
    this.calcData();

  },
  watch: {
    ChartData() {
      console.log('ChartData 1 => ' + this.ChartData);
      // this.dataSource = [];

      // this.dataSource.push({
      //   typeOp: 'الزام جدید',
      //   valueOp: Number(this.ChartData[0].unUploaded),
      // });

      // this.dataSource.push({
      //   typeOp: 'در حال بررسی',
      //   valueOp: Number(this.ChartData[0].inProgress),
      // });

      // this.dataSource.push({
      //   typeOp: 'تایید شده',
      //   valueOp: Number(this.ChartData[0].accepted_Holder),
      // });

      // this.dataSource.push({
      //   typeOp: 'رد شده',
      //   valueOp: Number(this.ChartData[0].denied),
      // });

      console.log('ChartData 2 => ' + this.ChartData);
      debugger

    }
  },
  data() {
    return {
      loaded: false,
      dataSource: [
        {
          typeOp: 'الزامات تعریف شده',
          valueOp: 55.5,
        }, {
          typeOp: 'در حال بررسی',
          valueOp: 12.8,
        }, {
          typeOp: 'تایید شده',
          valueOp: 24.6,
        }, {
          typeOp: 'رد شده',
          valueOp: 5.0,
        }
      ],
    };
  },
  methods: {
    refreshChart() {
      debugger
      if (this.$refs.pieChartRef != undefined) {
        this.$refs.pieChartRef.instance.render();
      }
      this.$forceUpdate();
    },
    calcData() {
      // debugger
      if (this.ChartData === null ||
        this.ChartData === undefined ||
        this.ChartData.length <= 0 && this.dataSource.length === 0) {
        // if (!this.loaded) {
        //   setTimeout(() => {
        //     this.calcData()
        //   }, 1000)
        return;
      }

      // };
      // debugger

      this.dataSource = [];

      const total_sum = Number(this.ChartData[0].unUploaded)+
      +Number(this.ChartData[0].inProgress)
      +Number(this.ChartData[0].inProgress)
      +Number(this.ChartData[0].accepted_Holder);

      this.dataSource.push({
        typeOp: this.$i18n.t('chart_line.edms_new'),
        valueOp: (Number(this.ChartData[0].unUploaded)/total_sum)*100,
      });

      this.dataSource.push({
        typeOp: this.$i18n.t('chart_line.edms_in_progress'),
        valueOp: (Number(this.ChartData[0].inProgress)/total_sum)*100,
      });

      this.dataSource.push({
        typeOp: this.$i18n.t('chart_line.edms_approved'),
        valueOp: (Number(this.ChartData[0].accepted_Holder)/total_sum)*100,
      });

      this.dataSource.push({
        typeOp: this.$i18n.t('chart_line.edms_rejected'),
        valueOp: (Number(this.ChartData[0].denied)/total_sum)*100,
      });
      setTimeout(() => {
        this.loaded = true;
        this.$emit('DataLoaded');
      }, 1000);

      //this.$forceUpdate();
      // if (this.$refs.pieChartRef != undefined) {
      //   this.$refs.pieChartRef.instance.render();
      // }
      //this.refreshChart();
    },
    refreshChart() {
      debugger
      this.calcData();
      this.$forceUpdate();
      if (this.$refs.pieChartRef != undefined) {
        this.$refs.pieChartRef.instance.render();
      }
    },
    formatLabel(pointInfo) {
      return `${pointInfo.argumentText}: ${pointInfo.valueText}%`;
    },
  },
};
</script>
  
<style>
/* #pie {
    height: 440px;
  } */
</style>
  