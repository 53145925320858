<template>
    <div class="main-reivew-frame" v-if="list_loaded">
        <div class="left">
            <DxList :data-source="dataSource" :grouped="true" :search-enabled="true" :selected-item-keys="[currentHotel.Id]"
                class="list" selection-mode="single" @selection-changed="listSelectionChanged" ref="doctorsList">
                <DxSearchEditorOptions placeholder="جستجوی اطلاعات دکترها" :width="300" />
                <template #item="{ data: item }">
                    <div>
                        <div class="hotel">
                            <div class="name">{{ item.Service_Name }}</div>
                            <div class="address">{{ item.workingDays }}</div>
                            <div :class="item.Hotel_Class.toLowerCase()" class="type" />
                        </div>
                        <div class="price-container">
                            <div class="price">{{ item.Expert }}</div>
                            <!-- <div class="caption">حوزه<br>تخصصی</div> -->
                        </div>
                    </div>
                </template>
                <template #group="{ data: item }">
                    <div class="city">{{ item.key }}</div>
                </template>
            </DxList>
        </div>
        <div class="right">
            <div class="header">
                <div class="name-container">
                    <div class="name">{{ currentHotel.Service_Name }}</div>
                    <div :class="currentHotel.Hotel_Class.toLowerCase()" class="type" />
                </div>
                <div class="price-container">
                    <!-- <div class="price">{{ currency(currentHotel.Price) }}</div> -->
                    <div class="price">{{ currentHotel.Price }}</div>
                    <div class="caption">امتیاز<br>کسب شده</div>
                </div>
            </div>
            <DxTileView :data-source="currentHotel.Images" :height="224" :base-item-height="100" :base-item-width="137"
                :item-margin="12" class="tile" no-data-text="">
                <template #item="{ data: item }">
                    <img class="tile-image" :alt="item.FileName" :src="require(`@/assets/img/${item.FileName}`)">
                </template>
            </DxTileView>
            <div class="address">شماره نظام پزشکی : {{ currentHotel.LiscenceNo }}</div>
            <div class="description">{{ currentHotel.Description }}
            </div>
            <div>
                <CustomDatePicker v-model="date_to_pick"></CustomDatePicker>
            </div>
        </div>
    </div>
</template>
<script>

import DxTileView from 'devextreme-vue/tile-view';
import DxList, {
    DxSearchEditorOptions
} from 'devextreme-vue/list';
import ArrayStore from 'devextreme/data/array_store';
import { data } from '@/store/sample.js'

import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';


const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export default {
    components: {
        DxTileView, DxList, DxSearchEditorOptions
    },
    setup() {
        const store = useStore();
        return {
            store,
            doctor_list_datasource: computed(() => store.getters['doctor_list/getDoctorList']),
        }
    },
    watch: {
        doctor_list_datasource() {
            // debugger
            let docotrs_List = [];
            const self = this;
            this.doctor_list_datasource.forEach(element => {
                // debugger
                self.dataSource.store._array.push(
                    {
                        Id: element.id,
                        Service_Name: 'Doki ' + element.name + ' ' + element.family,
                        LiscenceNo: element.liscneceNo,
                        workingDays: element.businessDays,
                        Address: 'Steindamm 99',
                        Postal_Code: '20359',
                        Description: 'در این قسمت اطلاعات مربوط به دکتر انتخاب شده نمایش داده میشود-شامل آدرس-رزومه و روزهای کاری',
                        Hotel_Class: 'Platinum',
                        Expert: element.workingCatagoryTitle + '-' + element.subWorkingCatagoryTitle,
                        Price: 399,
                        Images: [{
                            FileName: 'appointment-64.png',
                        }, {
                            FileName: 'appointment-91.png',
                        }, {
                            FileName: 'user-62.png',
                            widthRatio: 2,
                            heightRatio: 2,
                        }, {
                            FileName: 'user-96.png',
                            widthRatio: 2,
                            heightRatio: 2,
                        }, {
                            FileName: 'medical-services-64.png',
                        }, {
                            FileName: 'home-64.png',
                        },
                        ],
                    }
                );
            });
            // debugger
            if (this.$refs.doctorsList != undefined) {
                let holderRef = this.$refs.doctorsList.instance;
                holderRef.reload();
            }


            this.list_loaded = true;
            // this.dataSource = {
            //     store: new ArrayStore({
            //         docotrs_List,
            //         key: 'Id',
            //     }),
            //     group: 'Expert',
            //     searchExpr: ['Service_Name', 'Expert', 'Address'],
            // };
        }
    },

    created() {
        this.getDoctorsBaseInfo();
    },
    data() {
        return {
            list_loaded: false,
            date_to_pick: null,
            dataHolder: [],
            currentHotel: data[0],
            dataSource: {
                store: new ArrayStore({
                    data,
                    key: 'Id',
                }),
                group: 'Expert',
                searchExpr: ['Service_Name', 'Expert', 'Address'],
            },
        };
    },
    methods: {
        getDoctorsBaseInfo() {
            this.store.dispatch('doctor_list/getDoctorList');
        },
        currency(d) {
            return currencyFormatter.format(d);
        },
        listSelectionChanged(e) {
            this.currentHotel = e.addedItems[0];
        },
    },
};
</script>
<style lang="scss" scoped>
.main-reivew-frame {
    .left {
        float: right;
        width: 330px;
        height: 470px;
        padding: 20px;
        background: rgba(191, 191, 191, 0.15);
        margin-left: 30px;
    }

    .right {
        overflow: hidden;

        // display: flex;
        // flex-wrap: wrap;

        .dx-item .dx-tile {
            img {
                width: 100%;
                height: 100%;
                object-fit: contain !important;
                background: red;
            }
        }
    }
}


.left .list .dx-list-group-header {
    color: #f05b41;
    font-weight: normal;
    font-size: 18px;
}

.left .list .hotel {
    float: left;
}

.left .list .hotel .name {
    font-weight: bold;
}

.right .header {
    height: 70px;
}

.right .header .name-container {
    float: left;
}

.right .header .name {
    font-size: 30px;
    font-weight: bold;
}

.right .header .price-container {
    padding-top: 27px;
}

.right .name-container .type {
    margin-top: 0;
}

.right .tile {
    margin: 0 -12px 0 -12px;
}

.right .tile-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.right .address {
    padding-top: 30px;
    font-size: 18px;
    opacity: 0.7;
}

.right .description {
    margin: 10px 0;
}

.type {
    margin: 3px 0 5px 0;
    height: 14px;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2218px%22%20height%3D%2214px%22%20%3E%3Cpolyline%20fill%3D%22%23F05B41%22%20points%3D%227.5%2C0%209.972%2C4.399%2014.999%2C5.348%2011.5%2C9.015%2012.135%2C14%207.5%2C11.866%202.865%2C14%203.5%2C9.015%200.001%2C5.348%205.028%2C4.399%207.5%2C0%20%22%2F%3E%3C%2Fsvg%3E');
    background-size: 18px 14px;
}

.type.gold {
    width: 54px;
}

.type.platinum {
    width: 72px;
}

.type.diamond {
    width: 90px;
}

.price-container {
    float: right;
    padding-top: 13px;
}

.price-container>div {
    display: inline-block;
}

.price-container .price {
    font-size: 20px;
    font-weight: 650;
    color: #c8290d;
}

.price-container .caption {
    font-size: 11px;
    line-height: 12px;
    padding-left: 6px;
    opacity: 0.7;
}
</style>
  