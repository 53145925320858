import axios from 'axios'
import notify from 'devextreme/ui/notify';
import errorHandler from '@/components/errorHandler';

export default {
    getSingleReservation({commit},data){   
        debugger                     
        axios
        .get('Reservation/GetSingleReservationList?id='+data)
        .then((response)=>{
            debugger                                                
            commit('setBaseReservesList',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    getBaseReserveList({commit},data){   
        debugger                     
        axios
        .get('Reservation/GetBaseReservationList')
        .then((response)=>{
            debugger                                                
            commit('setBaseReservesList',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    getReserveList({commit},data){   
        debugger                     
        axios
        .get('Reservation/GetReservationList')
        .then((response)=>{
            debugger                                                
            commit('setReservesList',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    addNewReservation({commit},data){
        debugger
        axios
        .post('Reservation/AddNewReservation',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    editReservation({commit},data){
        debugger
        axios
        .post('Reservation/UpdateReservation',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    deleteReservation({commit},data){ 
        debugger
        axios
        .post('Reservation/DeleteReservation?id='+data)
        .then((response)=>{            
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            } 
        })
        .catch(error => {
            debugger
            errorHandler.methods.handleError(error);
        })
    },

}