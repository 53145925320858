<template>
    <div class="basic-line-ex" >
        <div class="team-boxed">
            <div class="container">
                <div class="intro">
                    <h2 class="text-center" >کلینیک راد</h2>
                    <p class="text-center">دپارتمان دندان پزشکی-خدمات پر بازدید</p>
                </div>
                <div class="row people">
                    <div class="col-md-4 col-lg-4 item" 
                    @click="gotoOrtodency"
                    >
                        <div class="box"><img class="rounded-circle" src="@/assets/img/health-care-64-ok.png">
                            <h3 class="name">خدمات ارتودنسی</h3>
                            <p class="title">کلینیک راد</p>
                            <p class="description">نوبت دهی - پیگیری درمان </p>
                            <div class="social"><a href="#"><i class="fa fa-facebook-official"></i></a><a href="#"><i
                                        class="fa fa-twitter"></i></a><a href="#"><i class="fa fa-instagram"></i></a></div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 item" >
                        <div class="box"><img class="rounded-circle" src="@/assets/img/assistance-64.png">
                            <h3 class="name">خدمات زیبایی</h3>
                            <p class="title">کلینیک راد</p>
                            <p class="description">کامپوزیت-لمینت-بلیچینگ-روشک</p>
                            <div class="social"><a href="#"><i class="fa fa-facebook-official"></i></a><a href="#"><i
                                        class="fa fa-twitter"></i></a><a href="#"><i class="fa fa-instagram"></i></a></div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 item" >
                        <div class="box"><img class="rounded-circle" src="@/assets/img/medical-services-64.png">
                            <h3 class="name">خدمات عمومی</h3>
                            <p class="title">کلینیک راد</p>
                            <p class="description">عصب کشی-ترمیم-کشیدن دندان</p>
                            <div class="social"><a href="#"><i class="fa fa-facebook-official"></i></a><a href="#"><i
                                        class="fa fa-twitter"></i></a><a href="#"><i class="fa fa-instagram"></i></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import AOS from 'aos';

export default {
    name: "introBar",
    components:{
        // AOS
    },
    data() {
        return {

        }
    },
    created(){
        // setTimeout((
        //     AOS.refresh();
        // )=>{},500);
    },
    methods: {
        gotoOrtodency(){
            this.$router.push({name:'ortodency'})
        }
    }
}
</script>

<style lang="scss" scoped>
.basic-line-ex {
    font-family: dana;

    .team-boxed {
        color: #313437;
        background-color: #eef4f7;
    }

    .team-boxed p {
        color: #7d8285;
    }

    .team-boxed h2 {
        font-weight: bold;
        margin-bottom: 40px;
        padding-top: 40px;
        color: inherit;
    }

    // @media (max-width:767px) {
    //     .team-boxed h2 {
    //         margin-bottom: 25px;
    //         padding-top: 25px;
    //         font-size: 24px;
    //     }
    // }

    .team-boxed .intro {
        font-size: 16px;
        max-width: 500px;
        margin: 0 auto;
    }

    .team-boxed .intro p {
        margin-bottom: 0;
    }

    .team-boxed .people {
        padding: 50px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .team-boxed .item {
        text-align: center;
        width: 30%;
        min-width: 300px;
        margin: 10px;
        cursor: pointer;
        transition: transform .3s ease-in-out;

        &:hover{
            transform: rotate(4deg) translateY(-15px);
        }
    }

    .team-boxed .item .box {
        text-align: center;
        padding: 30px;
        background-color: #fff;
        margin-bottom: 30px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 10px;
        cursor: pointer;
        transition: transform .3s ease-in-out;

        &:hover {
            transform: translateY(-10px);
        }
    }

    .team-boxed .item .name {
        font-weight: bold;
        margin-top: 28px;
        margin-bottom: 8px;
        color: inherit;
    }

    .team-boxed .item .title {
        text-transform: uppercase;
        font-weight: bold;
        color: #d0d0d0;
        letter-spacing: 2px;
        font-size: 13px;
    }

    .team-boxed .item .description {
        font-size: 15px;
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .team-boxed .item img {
        max-width: 160px;
    }

    .team-boxed .social {
        font-size: 18px;
        color: #a2a8ae;
    }

    .team-boxed .social a {
        color: inherit;
        margin: 0 10px;
        display: inline-block;
        opacity: 0.7;
    }

    .team-boxed .social a:hover {
        opacity: 1;
    }


}
</style>