export default {
    getBasePermissions : state =>{
        debugger
        return state.BasePermissions;
    },
    getRolesWithPermissions : state => {
        return state.RolesWithPermissions;
    },
    getBaseRoles : state => {   
        debugger             
        return state.BaseRoles;
    },
    getUserRoles : state => {                
        return state.UserRoles;
    },
    getMenuRolesDashboard : state => {
        return state.MenuRolesDashboard;
    },
    getUserRolesPermissions : state => {
        return state.UserRolesPermissions;
    },
    getUserCanUpdateEdmsStatus : state => {
        return state.UserCanUpdateEdmsStatus;
    }
}