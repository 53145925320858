<template>
  <div class="dx-swatch-additional side-navigation-menu" @click="forwardClick">
    <slot />
    <div class="menu-container">
      <!-- -->
      <!-- <dx-tree-view     
        v-if="loaded"
        ref="treeViewRef"  
        :items="itemsList"
        key-expr="path"
        selection-mode="single"
        :focus-state-enabled="false"
        expand-event="click"
        @item-click="handleItemClick"
        width="100%"
      /> -->
      <dx-tree-view v-if="loaded" ref="treeViewRef" :items="itemsList" key-expr="path" selection-mode="single"
        :focus-state-enabled="false" expand-event="click" @item-click="handleItemClick" width="100%" />
    </div>
  </div>
</template>

<script>
import DxTreeView from "devextreme-vue/ui/tree-view";
import { sizes } from '../utils/media-query';
import navigation from '../app-navigation';
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";
import { computed, reactive } from "@vue/reactivity";
import axios from 'axios'
import errorHandler from '@/components/errorHandler'
import notify from "devextreme/ui/notify";


export default {
  props: {
    compactMode: Boolean
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const isLargeScreen = sizes()['screen-large'];

    let itemsList = reactive([]);

    const treeViewRef = ref(null);

    function forwardClick(...args) {
      context.emit("click", args);
    }

    function handleItemClick(e) {
      if (!e.itemData.path || props.compactMode) {
        return;
      }
      router.push(e.itemData.path);

      const pointerEvent = e.event;
      pointerEvent.stopPropagation();
    }

    function updateSelection() {
      if (!treeViewRef.value || !treeViewRef.value.instance) {
        return;
      }

      treeViewRef.value.instance.selectItem(route.path);
      treeViewRef.value.instance.expandItem(route.path);
    }

    const getRootMenu = () => {
      // debugger
      // store.dispatch('menus/getRootMenus');


    }

    onMounted(() => {
      updateSelection();

      if (props.compactMode) {
        // debugger
        if (treeViewRef.value != undefined) {
          treeViewRef.value.instance.collapseAll();
        }
      }
      getRootMenu();
    });


    watch(
      () => route.path,
      () => {
        updateSelection();
      },
    );

    watch(
      () => props.compactMode,
      () => {
        // debugger
        if (props.compactMode) {
          // setTimeout(() => {
          //   debugger
          //   if (this.loaded != undefined) {
          //     this.loaded = false;
          //     this.loaded = true;
          //   }
          // }, 500);

          if (treeViewRef.value != undefined) {
            treeViewRef.value.instance.collapseAll();
          }

        } else {
          updateSelection();
        }
      }
    );

    return {
      treeViewRef,
      itemsList,
      forwardClick,
      handleItemClick,
      updateSelection,
      getRootMenu,
      isLargeScreen,
    };
  },
  components: {
    DxTreeView
  },
  created() {
    this.createChildren();
  },
  data() {
    return {
      loaded: false,
      base_menu: [
        // {
        //   text: "صفحه اصلی",
        //   path: "/home",
        //   icon: "home"
        // },
        {
          text: "پنل ادمین",
          icon: "toolbox",
          items: [
            {
              text: "کاربران",
              icon: "group",
              path: "/admin/users"
            },
            {
              text: "قسمت بندی",
              icon: "fields",
              path: "/admin/company_parts"
            },
            {
              text: "دسترسی کاربران",
              icon: "imgarlock",
              path: "/admin/user_roles"
            },
            {
              text: "تنیمات دسترسی",
              icon: "warning",
              path: "/admin/role_permissions"
            },
            {
              text: "دسترسی منو ها",
              icon: "menu",
              path: "/admin/menu_access"
            },
            // {
            //   text: "FileTypes",
            //   icon: "docfile",
            //   path: "/sale/file_types"
            // }
          ]
        },
      ],
    }
  },
  methods: {

    createChildren() {
      // debugger
      if (localStorage.getItem('userId') == null) {
        let holder_data = [];
        holder_data.push({
          text: "صفحه اصلی",
          path: "/home",
          icon: "home",
          isExpanded: false,
        });
        holder_data.push({
          text: "نوبت دهی",
          icon: require("@/assets/img/appointment-64.png"),
          items: [
            {
              text: "ثبت نوبت",
              path: "/userNewAppointment",
              icon: "add"
            },
            {
              text: "نوبت های ثبت شده",
              path: "/userSavedAppointments",
              icon: "comment"
            }
          ]
        });
        holder_data.push({
          text: "مشاوره آنلاین",
          icon: require("@/assets/img/request-48.png"),
          items: [
            {
              text: "ثبت خواست",
              path: "/newRequest",
              icon: "help"
            },
            {
              text: "درخواست های ثبت شده",
              path: "/savedRequests",
              icon: "download"
            }
          ]
        });
        holder_data.push({
          text: "گزارشات",
          icon: require("@/assets/img/assistance-64.png"),
          items: [
            {
              text: "مالی",
              path: "/profile1",
              icon: "money"
            },
            {
              text: "گزارشات",
              path: "/tasks1",
              icon: "percent"
            }
          ]
        });
        const menu_array = [...holder_data];
        this.itemsList = menu_array;
        this.loaded = true;
        return;
      }
      const user_id_logged = localStorage.getItem('userId');
      if (user_id_logged === null || user_id_logged === undefined) {
        notify('اطلاعات کاربری برای اتصال به منو مذکور یافت نشد ، لطفا دوباره وارد حساب خود شویدس', 'warning', this.notifyTimeSet);
        return;
      }
      // debugger
      axios
        //.get('Menus/GetRootMenus')
        .get('Menus/GetMenuAccssedByUserId?uid=' + user_id_logged)
        .then((response) => {
          // debugger
          let holder_data = [];
          holder_data.push({
            text: "صفحه اصلی",
            path: "/home",
            icon: require("@/assets/img/home-64.png"),
            isExpanded: false,
          });
          holder_data.push({
            text: "نوبت دهی",
            path: "/userNewAppointment",
            icon: require("@/assets/img/appointment-64.png"),
            isExpanded: false,
          });
          //debugger
          response.data.forEach(element => {
            //debugger
            if (holder_data.filter(x => x.text === element.mainMenuText).length <= 0) {


              if (element.subText != null) {
                holder_data.push({
                  text: element.mainMenuText,
                  icon: element.mainMenuIcon,
                  items: [],
                  isExpanded: false,
                });

                let holder_ = holder_data.filter(x => x.text === element.mainMenuText)[0];
                if (holder_ != undefined) {
                  if (holder_.items.filter(x => x.path === element.subPath).length <= 0) {
                    holder_.items.push({
                      text: element.subText,
                      path: element.subPath,
                      icon: element.subIcon,
                      isExpanded: false,
                    });
                  }
                }
                else {
                  if (element.subPath != undefined) {
                    holder_.items.push({
                      text: element.subText,
                      path: element.subPath,
                      icon: element.subIcon,
                      isExpanded: false,
                    });
                  }
                }

              }
              else {
                let holder_ = holder_data.filter(x => x.text === element.mainMenuText)[0];
                if (holder_ != undefined) {
                  if (holder_.items.filter(x => x.path === element.subPath).length <= 0) {
                    holder_data.push({
                      text: element.mainMenuText,
                      icon: element.mainMenuIcon,
                      items: [],
                      isExpanded: false,
                    });
                  }
                }
                else {
                  if (element.subPath != undefined) {
                    holder_.items.push({
                      text: element.subText,
                      path: element.subPath,
                      icon: element.subIcon,
                      isExpanded: false,
                    });
                  }
                }

              }
            }
            else {
              let holder_ = holder_data.filter(x => x.text === element.mainMenuText)[0];
              if (holder_ != undefined && holder_.items.filter(x => x.path === element.subPath).length <= 0) {
                if (element.subPath != undefined) {
                  holder_.items.push({
                    text: element.subText,
                    path: element.subPath,
                    icon: element.subIcon,
                  });
                }

              }

            }

            return;
          });
          //debugger
          if (localStorage.getItem('userName') === 'admin') {
            const menu_array = [...this.base_menu, ...holder_data];
            this.itemsList = menu_array;
          }
          else {
            const menu_array = [...holder_data];
            this.itemsList = menu_array;
          }
          this.loaded = true;
          // const menu_array = [...this.base_menu,...holder_data];
          // this.itemsList = menu_array;
        })
        .catch((error) => {
          debugger
          let holder_data = [];
          holder_data.push({
            text: "صفحه اصلی",
            path: "/home",
            icon: "home",
            isExpanded: false,
          });
          holder_data.push({
            text: "نوبت دهی",
            icon: require("@/assets/img/appointment-64.png"),
            items: [
              {
                text: "ثبت نوبت",
                path: "/newAppointment",
                icon: "add"
              },
              {
                text: "نوبت های ثبت شده",
                path: "/savedAppointments",
                icon: "comment"
              }
            ]
          });
          holder_data.push({
            text: "مشاوره آنلاین",
            icon: require("@/assets/img/request-48.png"),
            items: [
              {
                text: "ثبت خواست",
                path: "/newRequest",
                icon: "help"
              },
              {
                text: "درخواست های ثبت شده",
                path: "/savedRequests",
                icon: "download"
              }
            ]
          });
          holder_data.push({
            text: "گزارشات",
            icon: require("@/assets/img/assistance-64.png"),
            items: [
              {
                text: "مالی",
                path: "/profile1",
                icon: "money"
              },
              {
                text: "گزارشات",
                path: "/tasks1",
                icon: "percent"
              }
            ]
          });
          // const menu_array = [...holder_data];
          this.itemsList = holder_data;
          this.loaded = true;
          console.log(error);
          errorHandler.methods.handleError(error);
        });
    },
  }
};
</script>

<style lang="scss">
@import "../dx-styles.scss";
@import "../themes/generated/variables.additional.scss";

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 250px !important;

  .menu-container {
    min-height: 100%;
    display: flex;
    flex: 1;

    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      // ##

      // ## Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        padding-right: 0;
        margin-right: 5px;

        .dx-icon {
          // width: $side-panel-min-width !important;
          margin: 0 !important;
          margin-left: 5px !important;
        }
      }

      // ##

      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }

      // ##

      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level="1"] {
          font-weight: bold;
          border-bottom: 1px solid $base-border-color;
        }

        &[aria-level="2"] .dx-treeview-item-content {
          font-weight: normal;
          padding: 0 $side-panel-min-width;
        }
      }

      // ##
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused)>.dx-treeview-item {
            background: transparent;
          }

          &.dx-state-selected>.dx-treeview-item * {
            color: $base-accent;
          }

          &:not(.dx-state-focused)>.dx-treeview-item.dx-state-hover {
            background-color: lighten($base-bg, 4);
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container .dx-treeview-node.dx-state-selected.dx-state-focused>.dx-treeview-item * {
        color: inherit;
      }
    }

    // ##
  }
}
</style>
