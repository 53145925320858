<template>
  <div>
    <h2 class="content-block">{{ $t('menu_access.title') }}</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <DxDataGrid :data-source="totalmenus_datasource" :columns="totalmenus_columns" :ref="dataGridRefMenus"
          :show-row-lines="showRowLines" :show-column-lines="showColumnLines" :row-alternation-enabled="true"
          :rtl-enabled="true" :remote-operations="true" :column-auto-width="true" :allow-column-reordering="true"
          :allow-column-resizing="true" :hover-state-enabled="true" :noDataText="checkData"
          :selection="{ mode: 'single' }" @toolbar-preparing="onToolbarPreparing"
          @selection-changed="onSelectionChanged" class="mt-3" id="ps_Table"
          key-expr="menuId">

          <DxGroupPanel class="d-none d-lg-block" :visible="false" />
          <DxGrouping :auto-expand-all="false" expand-mode="rowClick" />
          <DxColumnChooser :enabled="true" />
          <DxExport :enabled="allowExportDataGrid" :allow-export-selected-data="allowExportSelectedDataGrid" />

          <DxHeaderFilter :visible="showHeaderFilter" />
          <DxSearchPanel :visible="true" :width="190" :placeholder="this.$i18n.t('menu_access.grid_search')" class="" />

          <!-- <DxSelection
                      :select-all-mode="allMode"
                      :show-check-boxes-mode="checkBoxesMode"
                      mode="multiple"
                    /> -->

          <DxScrolling mode="standard" :use-native="true"/>
          <DxPaging :enabled="false" />

          <template #btnGroup class="row">
            <div class="d-lg-none">
              <div class="mt-2">
                <DxDropDownButton :items="Dropactions" :drop-down-options="{ width: 190 }"
                  :text="this.$i18n.t('menu_access.grid_operation')" icon="save" @item-click="onItemClick" />
              </div>
            </div>
            <div class="d-none d-lg-block">
              <!-- <DxButton
                        class="ml-2 bg-success"
                        color="success"
                        size="sm"
                        icon="plus"
                        @click="NewPartPoput()">
                        </DxButton> -->
              <DxButton color="secondary" class="ml-2" size="sm" icon="revert" @click="$router.go(-1)">
              </DxButton>
              <DxButton color="light" size="sm" icon="refresh" @click="reloadMain()">
              </DxButton>
            </div>
          </template>


          <template #btnRGroup class="p-0 m-0">
            <div class="d-none d-lg-block">
              <div class="row">
                <div class="col-sm-5">
                  <button @click="setNewSubMenuDialog" class="btn w-100 btn-sm mr-1 bg-warning" size="sm">{{
                      $t('menu_access.new_sub_menu')
                  }}</button>
                </div>
                <div class="col-sm-7">
                  <button @click="setRolePermissionsDialog" class="btn w-100 btn-sm mr-1 bg-warning" size="sm">{{
                      $t('menu_access.access_roles_menus')
                  }}</button>
                </div>
              </div>
            </div>

          </template>
          <template #actions="{ data }">
            <div>
              <!-- <DxButton
                        class="bg-warning m-1"
                        icon="edit"
                        type="edit" 
                        title="ویرایش"
                        @click="setEdit(data)"> 
                        </DxButton>-->
              <DxButton class="bg-danger m-1" icon="remove" :title="this.$i18n.t('menu_access.grid_btn_delete')"
                @click="setDeleteDialog">
              </DxButton>
            </div>
          </template>
          <template #radif="{ data }">
            {{ data.rowIndex + 1 }}
          </template>
          <DxFilterRow :visible="true" />
          <DxLoadPanel :enabled="true" />
          <DxScrolling mode="standard" :use-native="true"/>
        </DxDataGrid>

      </div>
    </div>

    <!-- "فرم اختصاص منو ها به نقش" -->
    <DxPopup style="text-align: :center;" :width="this.showDialogNewWidth" :height="this.showDialogNewHeight"
      :show-title="true" :title="this.$i18n.t('menu_access.assign_menu_to_roles')" alignment="center"
      :drag-enabled="true" :hide-on-outside-click="false" v-model:visible="showDialogSetMenuRoles">
      <template #content="{ data }">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">

            <div class="card">
              <div class="card-body">

                <form>


                  <!-- Height-Width-Controller -->
                  <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                      <div class="d-flex flex-row">
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Height-Width-Controller-->

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-3" />
                          </span>
                        </div>
                        <DxDropDownBox v-model:value="gridBoxValueRole" v-model:opened="isDropDownBoxOpened"
                          :defer-rendering="false" :display-expr="gridBoxDisplayExpr" :show-clear-button="true"
                          :data-source="roles_datasource" value-expr="id" width="80%"
                          :placeholder="this.$i18n.t('menu_access.assign_menu_to_roles')">
                          <template #content="{ data }">
                            <DxDataGrid :data-source="roles_datasource" :columns="rolesListColumns"
                              :hover-state-enabled="true" v-model:selected-row-keys="gridBoxValueRole"
                              @selection-changed="changeDropDownBoxValue($event)" height="100%" ref="roles_grid">
                              <DxSelection mode="single" />
                              <DxPaging :enabled="false" />
                              <DxFilterRow :visible="true" />
                              <DxScrolling mode="standard" :use-native="true"/>
                              <template #radif_1="{ data }">
                                {{ data.rowIndex + 1 }}
                              </template>
                              <DxPaging :enabled="false" />
                            </DxDataGrid>
                          </template>
                        </DxDropDownBox>
                      </div>
                    </div>

                    <DxDataGrid :data-source="totalmenus_datasource" :columns="MenusListColumns"
                      :show-row-lines="showRowLines" :show-column-lines="showColumnLines"
                      :row-alternation-enabled="true" :rtl-enabled="true" :remote-operations="true"
                      :column-auto-width="true" :allow-column-reordering="true" :allow-column-resizing="true"
                      :hover-state-enabled="true" :noDataText="checkData" :selection="{ mode: 'single' }"
                      @selection-changed="onSelectionChangedPermission" ref="total_menus_grid" class="mt-3" id="ps_Table" key-expr="subMenuId">


                      <DxHeaderFilter :visible="showHeaderFilter" />
                      <DxSearchPanel :visible="true" :width="190"
                        :placeholder="this.$i18n.t('menu_access.grid_menus_search')" class="" />

                      <DxSelection :select-all-mode="allMode" :show-check-boxes-mode="checkBoxesMode" mode="multiple" />

                      <DxScrolling mode="standard" :use-native="true"/>


                      <template #action_permission="{ data }">
                        <div>
                          <DxButton class="bg-success m-1" icon="add"
                            :title="this.$i18n.t('menu_access.grid_btn_assign')" @click="savePermmissionToRole">
                          </DxButton>
                        </div>
                      </template>
                      <template #radif_Role="{ data }">
                        {{ data.rowIndex + 1 }}
                      </template>
                      <DxFilterRow :visible="true" />
                      <DxLoadPanel :enabled="true" />
                      <DxScrolling mode="standard" :use-native="true"/>
                      <DxPaging :enabled="false" />
                    </DxDataGrid>

                  </div>

                  <button class="btn btn-success mt-3 me-1 ms-2" @click="saveNewMenusToRole"><i
                      class="fa fa-home"></i>{{ $t('menu_access.dialog_btn_save') }}</button>
                  <button class="btn btn-warning mt-3" @click="setRolePermissionsDialogClose"><i
                      class="fa fa-home"></i>{{ $t('menu_access.dialog_btn_back') }}</button>
                </form>

                <div class="card-footer text-muted mt-2">{{ $t('menu_access.dialog_new_footer_label') }}</div>
              </div>
            </div>

          </div>
        </DxScrollView>
      </template>
    </DxPopup>

    <!-- تعریف زیر منو جدید -->
    <DxPopup style="text-align: :center;" :width="this.showDialogNewWidth" :height="this.showDialogNewHeight"
      :show-title="true" :title="this.$i18n.t('menu_access.sube_menu_defintion')" alignment="center"
      :drag-enabled="true" :hide-on-outside-click="false" v-model:visible="showDialogSaveNewSubMenu">
      <template #content="{ data }">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">

            <div class="card">
              <div class="card-body">
                <form>


                  <!-- Height-Width-Controller -->
                  <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                      <div class="d-flex flex-row">
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Height-Width-Controller-->

                  <div class="row">

                    <div class="col-sm-12">
                      <div class="form-group input-group p-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-list" class="m-3" />
                          </span>
                        </div>
                        <DxDropDownBox v-model:value="gridBoxValue" v-model:opened="isDropDownBoxOpened"
                          :defer-rendering="false" :display-expr="gridBoxDisplayExprMenu" :show-clear-button="true"
                          :data-source="menus_datasource" value-expr="id" width="80%"
                          :placeholder="this.$i18n.t('menu_access.mainmenu_selection')">
                          <template #content="{ data }">
                            <DxDataGrid :data-source="menus_datasource" :columns="menus_Columns"
                              :hover-state-enabled="true" v-model:selected-row-keys="gridBoxValue"
                              @selection-changed="changeDropDownBoxValueMenu($event)" ref="menus_grid" height="100%">
                              <DxSelection mode="single" />
                              <DxPaging :enabled="false" />
                              <DxFilterRow :visible="true" />
                              <DxScrolling mode="standard" :use-native="true"/>
                              <template #radif_1="{ data }">
                                {{ data.rowIndex + 1 }}
                              </template>
                              <DxPaging :enabled="false" />
                            </DxDataGrid>
                          </template>
                        </DxDropDownBox>
                      </div>
                    </div>

                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewSubMenu.Title" @input="this.check_content($event, 'inputSubTitle')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputSubTitle'), 'border-success-my': this.isStateOk('inputSubTitle'), 'rounded': true }"
                          :placeholder="this.$i18n.t('menu_access.submenu_title')" type="text">
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewSubMenu.Description"
                          @input="this.check_content($event, 'inputSubDiscription')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputSubDiscription'), 'border-success-my': this.isStateOk('inputSubDiscription'), 'rounded': true }"
                          :placeholder="this.$i18n.t('menu_access.submenu_description')" type="text">
                      </div>
                    </div>
                  </div>


                  <div class="row mt-2">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewSubMenu.Path" :hint="this.$i18n.t('menu_access.root_place_holder')"
                          @input="this.check_content($event, 'inputPath')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputPath'), 'border-success-my': this.isStateOk('inputPath'), 'rounded': true }"
                          :placeholder="this.$i18n.t('menu_access.root_place_holder')" type="text">
                      </div>
                    </div>
                  </div>

                  <button v-if="editMode" class="btn btn-success mt-3 me-1 ms-2" @click="editPartInfo($event)"><i
                      class="fa fa-home"></i>{{ $t('menu_access.dialog_new_submenu_btn_edit') }}</button>
                  <button v-else class="btn btn-success mt-3 me-1 ms-2" @click="saveNewSubMenu"><i
                      class="fa fa-home"></i>{{ $t('menu_access.dialog_new_submenu_btn_save') }}</button>
                  <button class="btn btn-warning mt-3" @click="this.showDialogSaveNewSubMenu = false;"><i
                      class="fa fa-home"></i>{{ $t('menu_access.dialog_new_submenu_btn_back') }}</button>
                </form>

                <div class="card-footer text-muted mt-2">{{ $t('menu_access.dialog_new_submenu_footer_label') }}</div>
              </div>
            </div>

          </div>
        </DxScrollView>
      </template>
    </DxPopup>






    <customDial :show="showDeleteDialog" :cancel="() => { this.showDeleteDialog = false; }" :confirm="delSubMenu"
      :title="this.$i18n.t('menu_access.delete_dialog_title')" :userPersonalInfo="MenuTitleSelected"
      :description="this.$i18n.t('menu_access.delete_dialog_body')" />

  </div>
</template>
<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxForm, DxItem } from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import { DxDataGrid, DxSelection } from 'devextreme-vue/data-grid';
import { computed } from '@vue/reactivity';
import { dxGrid } from '@/components/dxGrid'
import DxButton from 'devextreme-vue/button';
import { onMounted, reactive, ref } from 'vue';
import { mapGetters } from 'vuex';
import { useStore } from "vuex";
import MyValidator from '@/components/MyValidator'
import notify from 'devextreme/ui/notify';
import customDial from '@/components/customDialog.vue'
import themes from 'devextreme/ui/themes';
import DxDropDownButton from 'devextreme-vue/drop-down-button';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { DxDropDownBox } from 'devextreme-vue/drop-down-box';
import users_compo from '@/components/users_compo.vue'
import responsives from '@/utils/responsives';



export default {
  setup() {
    const store = useStore();
    return {
      store,
      totalmenus_datasource: computed(() => store.getters['menus/getTotalMenus']),
      roles_datasource: computed(() => store.getters['roles/getBaseRoles']),
      // permissions_datasource: computed(() => store.getters['roles/getBasePermissions']),

    }
  },
  mixins: [dxGrid, MyValidator, responsives],
  components: {
    DxButton,
    DxDataGrid,
    DxPopup,
    DxForm,
    DxSelectBox,
    DxDropDownButton,
    DxScrollView,
    DxDropDownBox,
    customDial,
    users_compo
  },
  data() {
    return {
      dataGridRefMenus: 'dataGrid',
      NewSubMenu: {},
      selectedMenu: {},
      selectedMenuText: '',
      menus_Columns: [
        { dataField: 'menuId', caption: this.$i18n.t('menu_access.grid_menu_menuId'), dataType: 'number', alignment: 'center', visible: false },
        { dataField: 'menuTitle', caption: this.$i18n.t('menu_access.grid_menu_menuTitle'), dataType: 'string', alignment: 'center' },
      ],
      menus_datasource: [],
      gridBoxValueRole: [3],
      gridBoxValue: [3],
      rolesListColumns: [
        { dataField: 'id', caption: this.$i18n.t('menu_access.grid_roles_id'), dataType: 'number', visible: false },
        { dataField: 'nameFa', caption: this.$i18n.t('menu_access.grid_roles_nameFa'), dataType: 'number', visible: true },
        { dataField: 'nameEn', caption: this.$i18n.t('menu_access.grid_roles_nameEn'), dataType: 'string' },
      ],
      MenusListColumns: [
        { dataField: 'menuId', caption: this.$i18n.t('menu_access.grid_menu_list_menuId'), dataType: 'number', visible: false },
        { dataField: 'subMenuId', caption: this.$i18n.t('menu_access.grid_menu_list_subMenuId'), dataType: 'number', visible: false },
        { dataField: 'mainTitle', caption: this.$i18n.t('menu_access.grid_menu_list_mainTitle'), dataType: 'string' },
        { dataField: 'subTitle', caption: this.$i18n.t('menu_access.grid_menu_list_subTitle'), dataType: 'string' },
      ],
      isDropDownBoxOpened: false,
      selectedRole: {},
      selectedRoleText: '',
      showDialogSetMenuRoles: false,
      showDeleteDialog: false,
      row_MenuToRole_Selected: [],
      PermissionTitleSelected: '',
      row_Menu_Selected: {},
      MenuTitleSelected: '',
      showDialogSaveNewSubMenu: false,
      totalmenus_columns: [
        { cellTemplate: "radif", caption: this.$i18n.t('menu_access.grid_row_radif'), dataType: 'number', allowFiltering: false, width: 50, alignment: "center" },
        { cellTemplate: 'actions', caption: this.$i18n.t('menu_access.grid_row_actions'), allowFiltering: false, allowSorting: false },
        { dataField: 'menuId', caption: this.$i18n.t('menu_access.grid_row_menuId'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'mainTitle', caption: this.$i18n.t('menu_access.grid_row_mainTitle'), dataType: 'string', alignment: 'center', groupIndex: 0, format: { type: "fixedPoint", precision: 0 } },
        { dataField: 'mainPath', caption: this.$i18n.t('menu_access.grid_row_mainPath'), dataType: 'string', alignment: 'center' },
        { dataField: 'mainIcon', caption: this.$i18n.t('menu_access.grid_row_mainIcon'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'mainDescription', caption: this.$i18n.t('menu_access.grid_row_mainDescription'), dataType: 'string', alignment: 'center' },
        { dataField: 'mainOrder', caption: this.$i18n.t('menu_access.grid_row_mainOrder'), dataType: 'string', alignment: 'center' },
        { dataField: 'subMenuId', caption: this.$i18n.t('menu_access.grid_row_subMenuId'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'subTitle', caption: this.$i18n.t('menu_access.grid_row_subTitle'), dataType: 'string', alignment: 'center' },
        { dataField: 'subPath', caption: this.$i18n.t('menu_access.grid_row_subPath'), dataType: 'string', alignment: 'center' },
        { dataField: 'subIcon', caption: this.$i18n.t('menu_access.grid_row_subIcon'), dataType: 'string', alignment: 'center' },
        { dataField: 'subDiscription', caption: this.$i18n.t('menu_access.grid_row_subDiscription'), dataType: 'string', alignment: 'center' },
        { dataField: 'subOrder', caption: this.$i18n.t('menu_access.grid_row_subOrder'), dataType: 'string', alignment: 'center' },
      ],
      Dropactions: [
        // { id: 1, text: "تعریف بخش جدید", icon: "user" },
        { id: 2, text: this.$i18n.t('menu_access.drop_down_toolbar_new_reload'), icon: "refresh" },
        { id: 3, text: this.$i18n.t('menu_access.drop_down_toolbar_new_back'), icon: "undo" },
        { id: 4, text: this.$i18n.t('menu_access.drop_down_toolbar_new_item'), icon: "preferences" },
        { id: 5, text: this.$i18n.t('menu_access.drop_down_acces_to_role'), icon: "preferences" },
      ],
      allMode: 'allPages',
      checkBoxesMode: themes.current().startsWith('material') ? 'always' : 'onClick',
    }
  },
  created() {
    this.reloadData();
    this.make_state_bind('inputSubTitle', false, this.$i18n.t('menu_access.validatoin_submenu_empty'));
    this.make_state_bind('inputSubDiscription', false, this.$i18n.t('menu_access.validatoin_submenu_desc_empty'));
    this.make_state_bind('inputPath', false, this.$i18n.t('menu_access.validatoin_menu_path_empty'));

  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      debugger

      const dataGrid = this.$refs.dataGrid.instance;
      this.$refs.dataGrid.columns[0].caption = this.$i18n.t('menu_access.grid_row_radif');
      this.$refs.dataGrid.columns[1].caption = this.$i18n.t('menu_access.grid_row_actions');
      this.$refs.dataGrid.columns[2].caption = this.$i18n.t('menu_access.grid_row_menuId');
      this.$refs.dataGrid.columns[3].caption = this.$i18n.t('menu_access.grid_row_mainTitle');
      this.$refs.dataGrid.columns[4].caption = this.$i18n.t('menu_access.grid_row_mainPath');
      this.$refs.dataGrid.columns[5].caption = this.$i18n.t('menu_access.grid_row_mainIcon');
      this.$refs.dataGrid.columns[6].caption = this.$i18n.t('menu_access.grid_row_mainDescription');
      this.$refs.dataGrid.columns[7].caption = this.$i18n.t('menu_access.grid_row_mainOrder');
      this.$refs.dataGrid.columns[8].caption = this.$i18n.t('menu_access.grid_row_subMenuId');
      this.$refs.dataGrid.columns[9].caption = this.$i18n.t('menu_access.grid_row_subTitle');
      this.$refs.dataGrid.columns[10].caption = this.$i18n.t('menu_access.grid_row_subPath');
      this.$refs.dataGrid.columns[11].caption = this.$i18n.t('menu_access.grid_row_subIcon');
      this.$refs.dataGrid.columns[12].caption = this.$i18n.t('menu_access.grid_row_subDiscription');
      this.$refs.dataGrid.columns[13].caption = this.$i18n.t('menu_access.grid_row_subOrder');



      dataGrid.option('columns', dataGrid.option('columns'));

      if (this.$refs.roles_grid != null) {
        const roles_grid_holder = this.$refs.roles_grid.instance;
        this.$refs.roles_grid.columns[0].caption = this.$i18n.t('menu_access.grid_roles_id');
        this.$refs.roles_grid.columns[1].caption = this.$i18n.t('menu_access.grid_roles_nameFa');
        this.$refs.roles_grid.columns[2].caption = this.$i18n.t('menu_access.grid_roles_nameEn');
        roles_grid_holder.option('columns', dataGrid_customer_company.option('columns'));
      }




      if (this.$refs.total_menus_grid != null) {
        const total_menus_grid_holder = this.$refs.total_menus_grid.instance;
        this.$refs.total_menus_grid.columns[0].caption = this.$i18n.t('menu_access.grid_menu_list_menuId');
        this.$refs.total_menus_grid.columns[1].caption = this.$i18n.t('menu_access.grid_menu_list_subMenuId');
        this.$refs.total_menus_grid.columns[2].caption = this.$i18n.t('menu_access.grid_menu_list_mainTitle');
        this.$refs.total_menus_grid.columns[3].caption = this.$i18n.t('menu_access.grid_menu_list_subTitle');
        total_menus_grid_holder.option('columns', dataGrid_customer_company.option('columns'));
      }

      if (this.$refs.menus_grid != null) {
        const menus_grid_holder = this.$refs.menus_grid.instance;
        this.$refs.menus_grid.columns[0].caption = this.$i18n.t('menu_access.grid_menu_menuId');
        this.$refs.menus_grid.columns[1].caption = this.$i18n.t('menu_access.grid_menu_menuTitle');
        menus_grid_holder.option('columns', dataGrid_customer_company.option('columns'));
      }
      
      

    },
    totalmenus_datasource() {

      let self = this;
      this.menus_datasource = [];
      this.totalmenus_datasource.forEach(element => {

        if (self.menus_datasource.filter(x => x.menuID === element.menuId).length <= 0) {
          self.menus_datasource.push(
            {
              menuID: element.menuId,
              menuTitle: element.mainTitle,
            }
          );
        }

      });
      console.log(this.menus_datasource);
    },

  },
  methods: {
    saveNewMenusToRole() {
      debugger
      if (this.selectedRole === undefined || this.selectedRole === null) {
        notify(this.$i18n.t('menu_access.validation_pre_role'), 'warning', this.notifyTimeSet);
        return;
      }
      notify(this.$i18n.t('menu_access.validation_load_wait'), 'primary', this.notifyTimeSet);
      const role_id_holder = this.selectedRole.id;
      this.store.dispatch('menus/deleteRoleMenuPolicy', role_id_holder);

      this.row_MenuToRole_Selected.forEach(element => {
        debugger

        const holder_role_permission = {
          RoleId: role_id_holder,
          MenuMainId: element.menuId,
          SubMenuId: element.subMenuId
        }
        setTimeout(() => {
          this.store.dispatch('menus/addNewRoleMenuPolicy', holder_role_permission);
        }, 1000)

      });
      this.showDialogSetMenuRoles = false;

    },
    gridBoxDisplayExpr(Menu) {
      return this.selectedRoleText;
    },
    gridBoxDisplayExprMenu(Menu) {
      return this.selectedMenuText;
    },
    changeDropDownBoxValueMenu(e) {
      debugger
      this.selectedMenu = e.currentSelectedRowKeys[0];
      this.isDropDownBoxOpened = false;
      if (this.selectedMenu != undefined)
        this.selectedMenuText = this.selectedMenu.menuTitle;
    },
    changeDropDownBoxValue(e) {
      debugger
      this.selectedRole = e.currentSelectedRowKeys[0];
      this.isDropDownBoxOpened = false;
      if (this.selectedRole != undefined)
        this.selectedRoleText = this.selectedRole.nameFa;
    },
    setRolePermissionsDialogClose() {
      this.showDialogSetMenuRoles = false;
      this.reloadData();
    },
    setRolePermissionsDialog() {
      debugger
      this.makeInitSize(600, 560);
      this.reloadDataRoles();
      this.showDialogSetMenuRoles = true;
    },
    setDeleteDialog() {
      this.showDeleteDialog = true;
    },
    delSubMenu() {
      debugger
      const id_holder = {
        id: this.row_Menu_Selected.subMenuId
      }
      this.store.dispatch('menus/deleteSubMenu', this.row_Menu_Selected.subMenuId);

      this.showDeleteDialog = false;
      setTimeout(() => {
        this.reloadData();
      }, this.reloadTimeSet);

      const dataGrid = this.$refs[this.dataGridRefMenus].instance;
      dataGrid.clearSelection();

    },
    saveNewSubMenu() {
      debugger
      if (this.isStateOkTotal() > 0) {
        let give_me_errors = this.getElementsWithErorrs();
        notify(give_me_errors, 'error', this.notifyTimeSet);
        return;
      }

      if (this.selectedMenu === undefined || this.selectedMenu.menuID === undefined) {
        notify(this.$i18n.t('menu_access.validation_pre_menu_selection'), 'warning', this.notifyTimeSet);
        return;
      }
      const new_sub_menu_holder = {
        Id: null,
        MenuId: this.selectedMenu.menuID,
        MenuText: this.NewSubMenu.Title,
        MenuPath: this.NewSubMenu.Path,
        MenuIcon: null,
        MenuDescription: this.NewSubMenu.Description,
        MenuTextEn: null,
        MenuOrder: null,
        MenuStyle: null,
        IsVisible: null,
      }
      this.store.dispatch('menus/addNewSubMenu', new_sub_menu_holder);
      this.showDialogSaveNewSubMenu = false;
      setTimeout(() => {
        this.reloadData();
      }, this.reloadTimeSet);
    },
    setNewSubMenuDialog() {
      this.makeInitSize(600, 520);
      this.showDialogSaveNewSubMenu = true;
    },
    reloadData() {
      this.store.dispatch('menus/getTotalMenus');
    },
    reloadDataRoles() {
      this.store.dispatch('roles/getAllRoles');
    },

    onItemClick(e) {
      debugger
      switch (e.itemData.id) {
        case 1:
          this.showDialog = true;
          break;
        case 2:
          this.reloadData();
          break;
        case 3:
          this.$router.go(-1);
          break;
        case 4:
          this.setNewSubMenuDialog();
          break;
        case 5:
          this.setRolePermissionsDialog();
          break;
        default:
          notify('خطای ناشناس در منو', 'warning', this.notifyTimeSet);
          break;
      }
    },
    onSelectionChangedPermission(selectedRowsData) {
      this.row_MenuToRole_Selected = selectedRowsData.selectedRowsData;
    },
    onSelectionChanged(selectedRowsData) {
      debugger
      this.row_Menu_Selected = selectedRowsData.selectedRowsData[0];
      this.MenuTitleSelected = this.row_Menu_Selected.mainTitle + '/' + this.row_Menu_Selected.subTitle;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "btnRGroup",
      });
      e.toolbarOptions.items.unshift({
        location: "after",
        template: "btnGroup",
      });
    },
  },

}
</script>
<style lang="scss">
@import "@/dx-styles.scss";

.dx-popup-wrapper>.dx-overlay-content {
  background: #fff;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border-left-color: $popup_color;
  border-left-width: 9px;
}

#ps_Table {
  height: 60vh;
}
</style>