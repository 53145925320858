export default {
    setUserReminders(state, data) {
        state.user_reminders = data;
    },
    
    // setActive_7Day_passed_reminders(state, data) {
    //     // debugger
    //     const holder_data = [
    //         { id: 1, text: "فایل های تاریخ نیاز : "+data.length, icon: "event",count:data.length },
    //         { id: 1, text: "فایل های بررسی نشده : 0", icon: "upload",count:0 },
    //         { id: 2, text: "پیغام ها : 0", icon: "email" ,count:0},
    //     ]

    //     // const holder_data = [
    //     //     { id: 1, text: this.i18n.t('notify_bar.date_expiring')+data.length, icon: "event",count:data.length },
    //     //     { id: 1, text: this.i18n.t('notify_bar.chart_edms_type_title'), icon: "upload",count:0 },
    //     //     { id: 2, text: this.i18n.t('notify_bar.chart_edms_type_title'), icon: "email" ,count:0},
    //     // ]

    //     state.active_7Day_passed_reminders_count = data.length;
    //     state.active_unread_messages_count=0;
    //     state.active_7Day_passed_reminders = data;
    //     state.active_7Day_passed_reminders_toolbar_items = holder_data;
    // },
   
}