import 'devextreme/dist/css/dx.common.css';
import 'devexpress-diagram/dist/dx-diagram.css'
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp } from "vue";
import router from "./router";
import store from "./store"
import 'bootstrap5/dist/css/bootstrap.min.css'
import 'bootstrap5/dist/js/bootstrap.min.js'
import 'popper.js/dist/popper.min.js';


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
/* add icons to the library */
library.add(faUserSecret)
library.add(fas)
library.add(far)
library.add(fab)


import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker'

import App from "./App";
import appInfo from "./app-info";
import { createI18n } from 'vue-i18n'
import {local_messages} from '@/lang/local_messages'
import './registerServiceWorker'


let messages = {
  en: {
    message: {
      hello: 'hello world!!!'
    }
  },
  fa: {
    message: {
      hello: 'خش آمدی'
    }
  }
}


const locales = require.context('./lang', true, /[A-Za-z0-9-_,\s]+\.json$/i)
const messages_file = {}
locales.keys().forEach(key => {
  
  const matched = key.match(/([A-Za-z0-9-_]+)\./i)
  if (matched && matched.length > 1) {
    const locale = matched[1]
    messages_file[locale] = locales(key)
  }
})

// function loadLocaleMessages () {
//   const locales = require.context('./lang', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

const i18n = createI18n({
  locale: 'fa', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages:messages_file
  // messages, // set locale messages
})


const app = createApp(App);

app.use(i18n);


//app.component('DatePicker', Vue3PersianDatetimePicker)
app.use(Vue3PersianDatetimePicker, {
  name: 'CustomDatePicker',
  props: {
    format: 'YYYY//DD HH:mm',
    displayFormat: 'jYYYY/jMM/jDD HH:mm',
    editable: false,
    inputClass: 'form-control my-date-class',
    placeholder: 'لطفا تاریخ مورد نظر خود را انتخاب نمایید',
    altFormat:  'jYYYY/jMM/jDD HH:mm',
    color: '#00acc1',
    autoSubmit: true,      
  }
})

// app.use(Vue3PersianDatetimePicker, {
//   name: 'CustomDatePicker',
//   props: {
//     format: 'jYYYY/jMM/jDD HH:mm',
//     displayFormat: 'jYYYY-jMM-jDD',
//     editable: false,
//     inputClass: 'form-control text-center my-custom-class-name',
//     placeholder: 'تاریخ',
//     altFormat: 'jYYYY/jMM/jDD HH:mm',
//     color: '#0373fc',
//     autoSubmit: false,
//     //...
//     //... And whatever you want to set as default.
//     //...
//   }
// })
//--------------- Notification Time Show Settings -------------------------
app.config.globalProperties.notifyTimeSet = 2000;
//--------------- TimeOut ReLoading Settings -------------------------
app.config.globalProperties.reloadTimeSet = 2000;
//--------------- Data Grid Settings -------------------------
app.config.globalProperties.showHeaderFilter = true;
app.config.globalProperties.showRowLines = true;
app.config.globalProperties.showColumnLines = true;
app.config.globalProperties.allowExportDataGrid = true;
app.config.globalProperties.allowExportSelectedDataGrid = true;
app.config.globalProperties.SelectionAllMode = 'allPages';
//--------------- Data Grid Settings -------------------------

// app.config.globalProperties.DownloadPath = 'D:\Yasan\API\ProManagement\PMIS_1\UploadedFilesTemp';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

app.use(router);
app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon)
app.config.globalProperties.$appInfo = appInfo;

app.mount('#app');
