
export const data = [{
  Id: 73,
  Service_Name: 'دکتر فرشاد مراد',
  LiscenceNo:'ندارد',
  workingDays:'شنبه-دوشنبه-چهارشنبه',
  Address: 'An Der Alster 82',
  Postal_Code: '20099',
  Description: 'در این قسمت اطلاعات مربوط به دکتر انتخاب شده نمایش داده میشود-شامل آدرس-رزومه و روزهای کاری',
  Hotel_Class: 'Diamond',
  Expert: 'ارتودنسی',
  Price: 299,
  Images: [{
    FileName: 'appointment-64.png',
  }, {
    FileName: 'appointment-91.png',
  }, {
    FileName: 'user-62.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'user-96.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'medical-services-64.png',
  }, {
    FileName: 'home-64.png',
  },
  ],
}, {
  Id: 75,
  Service_Name: 'دکتر امیر عظیمی',
  LiscenceNo:'ندارد',
  workingDays:'شنبه-دوشنبه-چهارشنبه-پنج شنبه',
  Address: 'Steindamm 99',
  Postal_Code: '20359',
  Description: 'در این قسمت اطلاعات مربوط به دکتر انتخاب شده نمایش داده میشود-شامل آدرس-رزومه و روزهای کاری',
  Hotel_Class: 'Platinum',
  Expert: 'عمومی',
  Price: 399,
  Images: [{
    FileName: 'appointment-64.png',
  }, {
    FileName: 'appointment-91.png',
  }, {
    FileName: 'user-62.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'user-96.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'medical-services-64.png',
  }, {
    FileName: 'home-64.png',
  },
  ],
}, {
  Id: 76,
  Service_Name: 'خانم دکتر الیاسی',
  LiscenceNo:'ندارد',
  workingDays:'شنبه-یکشنبه',
  Address: 'Borstelmannsweg 82',
  Postal_Code: '20537',
  Description: 'در این قسمت اطلاعات مربوط به دکتر انتخاب شده نمایش داده میشود-شامل آدرس-رزومه و روزهای کاری',
  Hotel_Class: 'Gold',
  Expert: 'جراحی',
  Price: 289,
  Images: [{
    FileName: 'appointment-64.png',
  }, {
    FileName: 'appointment-91.png',
  }, {
    FileName: 'user-62.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'user-96.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'medical-services-64.png',
  }, {
    FileName: 'home-64.png',
  },
  ],
}, {
  Id: 28,
  Service_Name: 'دکتر احمد کریمی',
  LiscenceNo:'ندارد',
  workingDays:'شنبه-یکشنبه',
  Address: '822 Mauna Loa Rd',
  Postal_Code: '96801',
  Description: 'در این قسمت اطلاعات مربوط به دکتر انتخاب شده نمایش داده میشود-شامل آدرس-رزومه و روزهای کاری',
  Hotel_Class: 'Gold',
  Expert: 'ارتودنسی',
  Price: 111,
  Images: [{
    FileName: 'appointment-64.png',
  }, {
    FileName: 'appointment-91.png',
  }, {
    FileName: 'user-62.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'user-96.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'medical-services-64.png',
  }, {
    FileName: 'home-64.png',
  },
  ],
}, {
  Id: 29,
  Service_Name: 'دکتر نیما کریمی',
  LiscenceNo:'ندارد',
  workingDays:'شنبه-یکشنبه',
  Address: '800 Waikiki Beach Rd',
  Postal_Code: '96801',
  Description: 'در این قسمت اطلاعات مربوط به دکتر انتخاب شده نمایش داده میشود-شامل آدرس-رزومه و روزهای کاری',
  Hotel_Class: 'Diamond',
  Expert: 'ارتودنسی',
  Price: 399,
  Images: [{
    FileName: 'appointment-64.png',
  }, {
    FileName: 'appointment-91.png',
  }, {
    FileName: 'user-62.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'user-96.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'medical-services-64.png',
  }, {
    FileName: 'home-64.png',
  },
  ],
}, {
  Id: 30,
  Service_Name: 'دکتر فرهاد مکری',
  LiscenceNo:'ندارد',
  workingDays:'شنبه-یکشنبه',
  Address: '900 Waikiki Beach Rd',
  Postal_Code: '96801',
  Description: 'در این قسمت اطلاعات مربوط به دکتر انتخاب شده نمایش داده میشود-شامل آدرس-رزومه و روزهای کاری',
  Hotel_Class: 'Platinum',
  Expert: 'زیبایی',
  Price: 399,
  Images: [{
    FileName: 'appointment-64.png',
  }, {
    FileName: 'appointment-91.png',
  }, {
    FileName: 'user-62.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'user-96.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'medical-services-64.png',
  }, {
    FileName: 'home-64.png',
  },
  ],
}, {
  Id: 31,
  Service_Name: 'خانم دکتر محدوند',
  LiscenceNo:'ندارد',
  workingDays:'شنبه-یکشنبه',
  Address: '543 Sandy Beach Rd.',
  Postal_Code: '96801',
  Description: 'در این قسمت اطلاعات مربوط به دکتر انتخاب شده نمایش داده میشود-شامل آدرس-رزومه و روزهای کاری',
  Hotel_Class: 'Platinum',
  Expert: 'عمومی',
  Price: 499,
  Images: [{
    FileName: 'appointment-64.png',
  }, {
    FileName: 'appointment-91.png',
  }, {
    FileName: 'user-62.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'user-96.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'medical-services-64.png',
  }, {
    FileName: 'home-64.png',
  },
  ],
}, {
  Id: 35,
  Service_Name: 'دکتر پژمان خطیری',
  LiscenceNo:'ندارد',
  workingDays:'شنبه-یکشنبه',
  Address: '424 Sand Hill Rd',
  Postal_Code: '96801',
  Description: 'در این قسمت اطلاعات مربوط به دکتر انتخاب شده نمایش داده میشود-شامل آدرس-رزومه و روزهای کاری',
  Hotel_Class: 'Diamond',
  Expert: 'عمومی',
  Price: 599,
  Images: [{
    FileName: 'appointment-64.png',
  }, {
    FileName: 'appointment-91.png',
  }, {
    FileName: 'user-62.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'user-96.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'medical-services-64.png',
  }, {
    FileName: 'home-64.png',
  },
  ],
}, {
  Id: 1,
  Service_Name: 'دکتر ایمان صاحلی',
  LiscenceNo:'ندارد',
  workingDays:'شنبه-یکشنبه',
  Address: '123 جراحی Blvd.',
  Postal_Code: '89120',
  Description: 'در این قسمت اطلاعات مربوط به دکتر انتخاب شده نمایش داده میشود-شامل آدرس-رزومه و روزهای کاری',
  Hotel_Class: 'Platinum',
  Expert: 'جراحی',
  Price: 90,
  Images: [{
    FileName: 'appointment-64.png',
  }, {
    FileName: 'appointment-91.png',
  }, {
    FileName: 'user-62.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'user-96.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'medical-services-64.png',
  }, {
    FileName: 'home-64.png',
  },
  ],
}, {
  Id: 2,
  Service_Name: 'خانم دکتر روانخواه',
  LiscenceNo:'ندارد',
  workingDays:'شنبه-یکشنبه',
  Address: '47 جراحی Blvd.',
  Postal_Code: '89119',
  Description: 'در این قسمت اطلاعات مربوط به دکتر انتخاب شده نمایش داده میشود-شامل آدرس-رزومه و روزهای کاری',
  Hotel_Class: 'Platinum',
  Expert: 'ارتودنسی',
  Price: 105,
  Images: [{
    FileName: 'appointment-64.png',
  }, {
    FileName: 'appointment-91.png',
  }, {
    FileName: 'user-62.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'user-96.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'medical-services-64.png',
  }, {
    FileName: 'home-64.png',
  },
  ],
}, {
  Id: 4,
  Service_Name: 'دکتر اکبر سیدی',
  LiscenceNo:'ندارد',
  workingDays:'شنبه-یکشنبه',
  Address: '28 Sunset Drive',
  Postal_Code: '89120',
  Description: 'در این قسمت اطلاعات مربوط به دکتر انتخاب شده نمایش داده میشود-شامل آدرس-رزومه و روزهای کاری',
  Hotel_Class: 'Diamond',
  Expert: 'عمومی',
  Price: 211,
  Images: [{
    FileName: 'appointment-64.png',
  }, {
    FileName: 'appointment-91.png',
  }, {
    FileName: 'user-62.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'user-96.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'medical-services-64.png',
  }, {
    FileName: 'home-64.png',
  },
  ],
}, {
  Id: 6,
  Service_Name: 'دکتر ماجد همراه',
  LiscenceNo:'ندارد',
  workingDays:'شنبه-یکشنبه',
  Address: '524 Paradise Road',
  Postal_Code: '89120',
  Description: 'در این قسمت اطلاعات مربوط به دکتر انتخاب شده نمایش داده میشود-شامل آدرس-رزومه و روزهای کاری',
  Hotel_Class: 'Platinum',
  Expert: 'جراحی',
  Price: 299,
  Images: [{
    FileName: 'appointment-64.png',
  }, {
    FileName: 'appointment-91.png',
  }, {
    FileName: 'user-62.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'user-96.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'medical-services-64.png',
  }, {
    FileName: 'home-64.png',
  },
  ],
}, {
  Id: 7,
  Service_Name: 'خانم دکتر مطلوب نیا',
  LiscenceNo:'ندارد',
  workingDays:'شنبه-یکشنبه',
  Address: '1000 جراحی Blvd.',
  Postal_Code: '89119',
  Description: 'در این قسمت اطلاعات مربوط به دکتر انتخاب شده نمایش داده میشود-شامل آدرس-رزومه و روزهای کاری',
  Hotel_Class: 'Gold',
  Expert: 'جراحی',
  Price: 149,
  Images: [{
    FileName: 'appointment-64.png',
  }, {
    FileName: 'appointment-91.png',
  }, {
    FileName: 'user-62.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'user-96.png',
    widthRatio: 2,
    heightRatio: 2,
  }, {
    FileName: 'medical-services-64.png',
  }, {
    FileName: 'home-64.png',
  },
  ],
}];
