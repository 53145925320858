import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxExport,
  DxButton,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxPager,
  DxPaging,
  DxSearchPanel,
  DxSelection,
  DxColumnChooser,
  DxLoadPanel,
  DxColumnFixing,
  DxSorting,
  DxStateStoring
} from 'devextreme-vue/data-grid';
import enMessages from "devextreme/localization/messages/en.json";
import { loadMessages } from "devextreme/localization";
const dataGridRef = 'dataGrid';

export const dxGrid = {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxExport,
    DxButton,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRangeRule,
    DxRequiredRule,
    DxValueFormat,
    DxPager,
    DxPaging,
    DxSearchPanel,
    DxSelection,
    DxColumnChooser,
    DxLoadPanel,
    DxColumnFixing,
    DxSorting,
    DxStateStoring
  },
  data(){
    return{
      dataGridRef,
      checkData: 'در حال بارگذاری اطلاعات',
      selectedRowsData: [],
      selectedRowsKey: [],
      selectedRowSet: [],
      pageSizes: [50, 100, 200, 500],
      reloadDataGridCheck: true,
    }
  },
  computed :{
    dataGrid: function() {
      return this.$refs[dataGridRef].instance;
    },
  },
  methods: {
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        template: 'btnGroup',
      });
      setTimeout(()=>{
      this.checkData = 'اطلاعاتی جهت نمایش موجود نمی‌باشد';
    },10000);
    },
    reloadDataGrid(){
      this.reloadDataGridCheck = false;
      this.$nextTick(() => {
      this.reloadDataGridCheck = true;
      });
    },
  },
  created(){
    loadMessages(enMessages);
  },
};
