<template>
    <div>
      <h2 class="content-block"> {{ $t('customer_provider.title') }}</h2>
      <div class="content-block">
        <div class="dx-card responsive-paddings">
          <DxDataGrid :data-source="customer_company_datasource" :columns="customer_company_columns"
            :ref="dataGridRefCustomerCompany" :show-row-lines="showRowLines" :show-column-lines="showColumnLines"
            :row-alternation-enabled="true" :rtl-enabled="true" :remote-operations="true" :column-auto-width="true"
            :allow-column-reordering="true" :allow-column-resizing="true" :hover-state-enabled="true"
            :noDataText="checkData" :selection="{ mode: 'single' }" @toolbar-preparing="onToolbarPreparing"
            @selection-changed="onSelectionChanged" class="mt-3" id="ps_Table" key-expr="id" >
  
            <DxColumnChooser :enabled="true" />
            <DxExport :enabled="allowExportDataGrid" :allow-export-selected-data="allowExportSelectedDataGrid" />
  
            <DxHeaderFilter :visible="showHeaderFilter" />
            <DxSearchPanel :visible="true" :width="190" :placeholder="this.$i18n.t('customer_provider.grid_search')" class="" />
  
            <DxSelection :select-all-mode="SelectionAllMode" :show-check-boxes-mode="checkBoxesMode" mode="multiple" />
  
            <template #btnGroup class="row">
              <div class="d-lg-none">
                <div class="mt-2">
                  <DxDropDownButton :items="Dropactions" :drop-down-options="{ width: 190 }" :text="this.$i18n.t('customer_provider.drop_down_toolbar')" icon="save"
                    @item-click="onItemClick" />
                </div>
              </div>
              <div class="d-none d-lg-block">
                <DxButton class="ml-2 bg-success" color="success" size="sm" icon="plus" @click="setShowDialogNew()">
                </DxButton>
                <DxButton color="secondary" class="ml-2" size="sm" icon="revert" @click="$router.go(-1)">
                </DxButton>
                <DxButton color="light" size="sm" icon="pulldown" @click="reloadData">
                </DxButton>
              </div>
            </template>
  
  
            <template #btnRGroup class="p-0 m-0">
              <div class="d-none d-lg-block">
                <div class="row">
                  <div class="col-sm-12">
                    <button class="btn w-100 btn-sm mr-1 bg-warning" size="sm">{{ $t('customer_provider.drop_down_toolbar') }}</button>
                  </div>
                </div>
              </div>
  
            </template>
            <template #actions="{ data }">
              <div>
                <DxButton class="bg-warning m-1" icon="edit" type="edit" :hint="this.$i18n.t('customer_provider.grid_row_edit')" @click="setEdit(data)">
                </DxButton>
                <DxButton class="bg-danger m-1" icon="remove" :hint="this.$i18n.t('customer_provider.grid_row_delete')" @click="setDeleteDialog">
                </DxButton>
              </div>
            </template>
            <template #radif="{ data }">
              {{ data.rowIndex + 1 }}
            </template>
            <DxFilterRow :visible="true" />
            <DxLoadPanel :enabled="true" />
            <DxScrolling mode="standard" :use-native="true"/>
          </DxDataGrid>
  
        </div>
      </div>
  
  
      <DxPopup style="text-align: :center;" :width="this.showDialogNewWidth" :height="this.showDialogNewHeight"
        :show-title="true" :title="this.$i18n.t('customer_provider.dialog_new_header')" alignment="center" :drag-enabled="true"
        :hide-on-outside-click="false" v-model:visible="ShowDialogNew">
        <template #content="{ data }">
          <DxScrollView width="100%" height="100%">
            <div class="popup-property-details">
  
              <div class="card">
                <div class="card-body">
                  <form>
  
  
                    <!-- Height-Width-Controller -->
                    <div class="container mb-1 me-0 pe-0 ">
                      <div class="col-sm-6">
                        <div class="d-flex flex-row">
                          <div class="">
                            <div class="form-group input-group">
                              <div class="input-group-prepend">
                                <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png"
                                  class="responseModels m-1" />
                              </div>
                            </div>
                          </div>
                          <div class="">
                            <div class="form-group input-group">
                              <div class="input-group-prepend">
                                <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png"
                                  class="responseModels m-1" />
                              </div>
                            </div>
                          </div>
                          <div class="">
                            <div class="form-group input-group">
                              <div class="input-group-prepend">
                                <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png"
                                  class="responseModels m-1" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Height-Width-Controller -->
  
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group input-group">
                          <div class="input-group-prepend mt-2">
                            <span class="input-group-text">
                              <font-awesome-icon icon="fas fa-user" class="m-1" />
                            </span>
                          </div>
                          <DxDropDownBox v-model:value="gridBoxValue" v-model:opened="isDropDownBoxOpened"
                            :defer-rendering="false" :display-expr="gridBoxDisplayExpr" :show-clear-button="true"
                            :data-source="working_catagory_datasource" value-expr="id"
                            :placeholder="this.$i18n.t('customer_provider.validate_part_selction')" width="90%">
                            <template #content="{ data }">
                              <DxDataGrid :data-source="working_catagory_datasource" :columns="working_catagory_columns"
                                :hover-state-enabled="true" v-model:selected-row-keys="gridBoxValue"
                                @selection-changed="changeDropDownBoxValue($event)"
                                @content-ready="$event.component.selectItem(gridBoxValue)" height="100%" ref="working_cat_grid">
                                <DxSelection mode="single" />
  
                                <DxPaging :enabled="false" />
                                <DxFilterRow :visible="true" />
                                <DxScrolling mode="standard" :use-native="true"/>
                                <template #radif_1="{ data }">
                                  {{ data.rowIndex + 1 }}
                                </template>
                              </DxDataGrid>
                            </template>
                          </DxDropDownBox>
                        </div>
                      </div>
                    </div>
  
                    <div class="row mt-2">
                      <div class="col-sm-12">
                        <div class="form-group input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <font-awesome-icon icon="fas fa-user" class="m-1" />
                            </span>
                          </div>
                          <input v-model="NewCustomerCompanyInfo.Title" @input="this.check_content($event, 'inputTitle')"
                            :class="{ 'border-danger-my': !this.isStateOk('inputTitle'), 'border-success-my': this.isStateOk('inputTitle'), 'rounded': true }"
                            :placeholder="this.$i18n.t('customer_provider.dialog_new_title_company')" type="text">
                        </div>
                      </div>
                    </div>
  
                    <div class="row mt-2">
                      <div class="col-sm-12">
                        <div class="form-group input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <font-awesome-icon icon="fas fa-user" class="m-1" />
                            </span>
                          </div>
                          <input v-model="NewCustomerCompanyInfo.Description" class="form-control"
                            :placeholder="this.$i18n.t('customer_provider.dialog_new_desc_company')" type="text">
                        </div>
                      </div>
                    </div>
  
                    <div class="container mt-2">
                      <div class="row">
                        <div class="col-sm-6 m-0 p-0">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <font-awesome-icon icon="fas fa-user" class="m-1" />
                              </span>
                            </div>
                            <input v-model="NewCustomerCompanyInfo.Telephone"
                              @input="this.check_content($event, 'inputTelephone')"
                              :class="{ 'border-danger-my': !this.isStateOk('inputTelephone'), 'border-success-my': this.isStateOk('inputTelephone'), 'rounded': true }"
                              :placeholder="this.$i18n.t('customer_provider.dialog_new_tel_company')" type="text">
                          </div>
                        </div>
                        <div class="col-sm-6 p-0">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <font-awesome-icon icon="fas fa-user" class="m-1" />
                              </span>
                            </div>
                            <input v-model="NewCustomerCompanyInfo.Fax" class="form-control" 
                            :placeholder="this.$i18n.t('customer_provider.dialog_new_fax_company')"
                              type="text">
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="row mt-2">
                      <div class="col-sm-12">
                        <div class="form-group input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <font-awesome-icon icon="fas fa-user" class="m-1" />
                            </span>
                          </div>
                          <input v-model="NewCustomerCompanyInfo.Address" class="form-control" 
                          :placeholder="this.$i18n.t('customer_provider.dialog_new_address_company')"
                            type="text">
                        </div>
                      </div>
                    </div>
  
                    <div class="row mt-2">
                      <div class="col-sm-12">
                        <div class="form-group input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <font-awesome-icon icon="fas fa-user" class="m-1" />
                            </span>
                          </div>
                          <input v-model="NewCustomerCompanyInfo.Email" class="form-control" 
                          :placeholder="this.$i18n.t('customer_provider.dialog_new_email_company')"
                            type="text">
                        </div>
                      </div>
                    </div>
  
                    <div class="row mt-2">
                      <div class="col-sm-12">
                        <div class="form-group input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <font-awesome-icon icon="fas fa-user" class="m-1" />
                            </span>
                          </div>
                          <input v-model="NewCustomerCompanyInfo.Website" class="form-control" 
                          :placeholder="this.$i18n.t('customer_provider.dialog_new_web_company')"
                            type="text">
                        </div>
                      </div>
                    </div>
  
  
                    <div class="container mt-2">
                      <div class="row">
                        <div class="col-sm-6 m-0 p-0">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <font-awesome-icon icon="fas fa-user" class="m-1" />
                              </span>
                            </div>
                            <input v-model="NewCustomerCompanyInfo.EconomyCode" class="form-control"
                              :placeholder="this.$i18n.t('customer_provider.dialog_new_fin_code_company')" type="text">
                          </div>
                        </div>
                        <div class="col-sm-6 p-0">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <font-awesome-icon icon="fas fa-user" class="m-1" />
                              </span>
                            </div>
                            <input v-model="NewCustomerCompanyInfo.SocialMedia" class="form-control"
                              :placeholder="this.$i18n.t('customer_provider.dialog_new_social_address_company')" type="text">
                          </div>
                        </div>
                      </div>
                    </div>
  
  
  
                    <div class="container mt-2">
                      <div class="row">
                        <div class="col-sm-6 m-0 p-0">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <font-awesome-icon icon="fas fa-user" class="m-1" />
                              </span>
                            </div>
                            <input v-model="NewCustomerCompanyInfo.Ceo" class="form-control" 
                            :placeholder="this.$i18n.t('customer_provider.dialog_new_manger_company')"
                              type="text">
                          </div>
                        </div>
                        <div class="col-sm-6 p-0">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <font-awesome-icon icon="fas fa-user" class="m-1" />
                              </span>
                            </div>
                            <input v-model="NewCustomerCompanyInfo.ProjectManager" class="form-control"
                              :placeholder="this.$i18n.t('customer_provider.dialog_new_project_manager_company')" type="text">
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="container mt-2">
                      <div class="row">
                        <div class="col-sm-6 m-0 p-0">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <font-awesome-icon icon="fas fa-user" class="m-1" />
                              </span>
                            </div>
                            <input v-model="NewCustomerCompanyInfo.ProjectExpert" class="form-control" 
                            :placeholder="this.$i18n.t('customer_provider.dialog_new_project_expert')"
                              type="text">
                          </div>
                        </div>
                        <!-- <div class="col-sm-6 p-0">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <font-awesome-icon icon="fas fa-user" class="m-1" />
                              </span>
                            </div>
                            <input v-model="NewCustomerCompanyInfo.MasterProject" class="form-control"
                              :placeholder="this.$i18n.t('customer_provider.dialog_new_project_manager_company')" type="text">
                          </div>
                        </div> -->
                      </div>
                    </div>
  
  
                    <button v-if="editMode" class="btn btn-success mt-3 me-1 ms-2" @click="editCustomerCompany($event)"><i
                        class="fa fa-home"></i>{{ $t('customer_provider.dialog_new_btn_edit') }}</button>
                    <button v-else class="btn btn-success mt-3 me-1 ms-2" @click="saveNewCustomerCompany"><i
                        class="fa fa-home"></i>{{ $t('customer_provider.dialog_new_btn_save') }}</button>
                    <button class="btn btn-warning mt-3" @click="this.ShowDialogNew = false;"><i
                        class="fa fa-home"></i>{{ $t('customer_provider.dialog_new_btn_back') }}</button>
                  </form>
  
                  <div class="card-footer text-muted mt-2">{{ $t('customer_provider.dialog_new_footer_label') }}</div>
                </div>
              </div>
  
            </div>
          </DxScrollView>
        </template>
      </DxPopup>
  
  
  
  
  
  
      <customDial :show="showDeleteDialog" :cancel="() => { this.showDeleteDialog = false; }" :confirm="delCustomerCompanyM"
        :title="this.$i18n.t('customer_provider.delete_dialog_title')" :userPersonalInfo="customer_company_selected_text"
        :description="this.$i18n.t('customer_provider.delete_dialog_body')" />
  
    </div>
  </template>
  <script>
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxForm, DxItem } from 'devextreme-vue/form';
  import { DxPopup } from 'devextreme-vue/popup';
  import { DxDataGrid, DxSelection } from 'devextreme-vue/data-grid';
  import { dxGrid } from '@/components/dxGrid'
  import DxButton from 'devextreme-vue/button';
  import { mapGetters, useStore } from 'vuex';
  import MyValidator from '@/components/MyValidator'
  import notify from 'devextreme/ui/notify';
  import customDial from '@/components/customDialog.vue'
  import themes from 'devextreme/ui/themes';
  import { computed } from '@vue/reactivity';
  import DxDropDownButton from 'devextreme-vue/drop-down-button';
  import { DxScrollView } from 'devextreme-vue/scroll-view';
  import users_compo from '@/components/users_compo.vue'
  import { DxDropDownBox } from 'devextreme-vue/drop-down-box';
  import responsives from '@/utils/responsives';
  
  
  export default {
    setup() {
      const store = useStore();
      return {
        store,
        customer_company_datasource: computed(() => store.getters['providers/getProvidersCopmany']),
        working_catagory_datasource: computed(() => store.getters['working_catagory/getWorkingCatagory']),
  
      }
    },
    mixins: [dxGrid, MyValidator, responsives],
    components: {
      DxButton,
      DxDataGrid,
      DxPopup,
      DxForm,
      DxSelectBox,
      DxDropDownButton,
      DxScrollView,
      DxDropDownBox,
      customDial
    },
    data() {
      return {
        dataGridRefCustomerCompany: 'dataGrid',
        customer_company_selected: {},
        customer_company_selected_text: '',
        working_catagory_columns: [
          { dataField: 'id', caption: this.$i18n.t('customer_provider.grid_working_category_id'), dataType: 'string', visible: false },
          { dataField: 'title', caption: this.$i18n.t('customer_provider.grid_working_category_title'), dataType: 'string' },
          { dataField: 'description', caption: this.$i18n.t('customer_provider.grid_working_category_description'), dataType: 'string', alignment: 'center' },
        ],
        isDropDownBoxOpened: false,
        selectedWorkingCatagory: {},
        selectedWorkingCatagoryText: '',
        gridBoxValue: null,
        editMode: false,
        NewCustomerCompanyInfo: {},
        ShowDialogNew: false,
        customer_company_columns: [
          { cellTemplate: "radif", caption: this.$i18n.t('customer_provider.grid_row_no'), dataType: 'number', allowFiltering: false, width: 50, alignment: "center" },
          { cellTemplate: 'actions', caption: this.$i18n.t('customer_provider.grid_row_operation'), allowFiltering: false, allowSorting: false },
          { dataField: 'id', caption: this.$i18n.t('customer_provider.grid_row_id'), dataType: 'string', visible: false },
          { dataField: 'workingCatagoryId', caption: this.$i18n.t('customer_provider.grid_row_workingCatagoryId'), dataType: 'string', visible: false },
          { dataField: 'economyCode', caption: this.$i18n.t('customer_provider.grid_row_economyCode'), dataType: 'string', alignment: 'center' },
          { dataField: 'title', caption: this.$i18n.t('customer_provider.grid_row_title'), dataType: 'string', alignment: 'center', format: { type: "fixedPoint", precision: 0 } },
          { dataField: 'description', caption: this.$i18n.t('customer_provider.grid_row_description'), dataType: 'string', alignment: 'center' },
          { dataField: 'telephone', caption: this.$i18n.t('customer_provider.grid_row_telephone'), dataType: 'string', alignment: 'center' },
          { dataField: 'fax', caption: this.$i18n.t('customer_provider.grid_row_fax'), dataType: 'string', alignment: 'center' },
          { dataField: 'email', caption: this.$i18n.t('customer_provider.grid_row_email'), dataType: 'string', alignment: 'center' },
          { dataField: 'address', caption: this.$i18n.t('customer_provider.grid_row_address'), dataType: 'string', alignment: 'center' },
          { dataField: 'website', caption: this.$i18n.t('customer_provider.grid_row_website'), dataType: 'string', alignment: 'center' },
          { dataField: 'socialMedia', caption: this.$i18n.t('customer_provider.grid_row_socialMedia'), dataType: 'string', alignment: 'center' },
          { dataField: 'ceo', caption: this.$i18n.t('customer_provider.grid_row_ceo'), dataType: 'string', alignment: 'center' },
          { dataField: 'projectManager', caption: this.$i18n.t('customer_provider.grid_row_project_manager'), dataType: 'string', alignment: 'center' },
          { dataField: 'projectExpert', caption: this.$i18n.t('customer_provider.grid_row_project_expert'), dataType: 'string', alignment: 'center' },
        ],
        NewshowDialog: false,
        showDeleteDialog: false,
        Dropactions: [
          { id: 1, text: this.$i18n.t('customer_provider.drop_down_toolbar_new_item'), icon: "user" },
          { id: 2, text: this.$i18n.t('customer_provider.drop_down_toolbar_new_reload'), icon: "refresh" },
          { id: 3, text: this.$i18n.t('customer_provider.drop_down_toolbar_new_back'), icon: "undo" },
          { id: 4, text: "تنظیمات1", icon: "preferences" },
          { id: 5, text: "تنظیمات2", icon: "preferences" },
          { id: 6, text: "تنظیمات3", icon: "preferences" },
        ],
        checkBoxesMode: themes.current().startsWith('material') ? 'always' : 'onClick',
      }
    },
    watch: {
      '$i18n.locale': function (newVal, oldVal) {
        debugger
        const dataGrid = this.$refs.dataGrid.instance;
        this.$refs.dataGrid.columns[0].caption = this.$i18n.t('customer_provider.grid_row_no');
        this.$refs.dataGrid.columns[1].caption = this.$i18n.t('customer_provider.grid_row_operation');
        this.$refs.dataGrid.columns[2].caption = this.$i18n.t('customer_provider.grid_row_id');
        this.$refs.dataGrid.columns[3].caption = this.$i18n.t('customer_provider.grid_row_title');
        this.$refs.dataGrid.columns[4].caption = this.$i18n.t('customer_provider.grid_row_workingCatagoryId');
        this.$refs.dataGrid.columns[5].caption = this.$i18n.t('customer_provider.grid_row_economyCode');
        this.$refs.dataGrid.columns[6].caption = this.$i18n.t('customer_provider.grid_row_title');
        this.$refs.dataGrid.columns[7].caption = this.$i18n.t('customer_provider.grid_row_description');
        this.$refs.dataGrid.columns[8].caption = this.$i18n.t('customer_provider.grid_row_telephone');
        this.$refs.dataGrid.columns[9].caption = this.$i18n.t('customer_provider.grid_row_fax');
        this.$refs.dataGrid.columns[10].caption = this.$i18n.t('customer_provider.grid_row_email');
        this.$refs.dataGrid.columns[11].caption = this.$i18n.t('customer_provider.grid_row_address');
        this.$refs.dataGrid.columns[12].caption = this.$i18n.t('customer_provider.grid_row_website');
        this.$refs.dataGrid.columns[13].caption = this.$i18n.t('customer_provider.grid_row_socialMedia');
        this.$refs.dataGrid.columns[14].caption = this.$i18n.t('customer_provider.grid_row_ceo');
        this.$refs.dataGrid.columns[15].caption = this.$i18n.t('customer_provider.grid_row_project_manager');
        this.$refs.dataGrid.columns[16].caption = this.$i18n.t('customer_provider.grid_row_project_expert');
        // this.$refs.dataGrid.columns[15].caption = this.$i18n.t('customer_provider.grid_row_masterProject');
  
        dataGrid.option('columns', dataGrid.option('columns'));
  
       
        if (this.$refs.working_cat_grid != null) {
          const dataGrid_working_categroy = this.$refs.working_cat_grid.instance;
          this.$refs.working_cat_grid.columns[0].caption = this.$i18n.t('customer_provider.grid_working_category_id');
          this.$refs.working_cat_grid.columns[1].caption = this.$i18n.t('customer_provider.grid_working_category_title');
          this.$refs.working_cat_grid.columns[2].caption = this.$i18n.t('customer_provider.grid_working_category_description');
          dataGrid_working_categroy.option('columns', dataGrid_working_categroy.option('columns'));
        }
      }
    },
    created() {
      this.make_state_bind('inputTitle', false, this.$i18n.t('customer_provider.validate_title_company'));
      this.make_state_bind('inputTelephone', false, this.$i18n.t('customer_provider.validate_telephone_company'));
      this.reloadData();
      this.reloadDataWorkingCatagory();
    },
    methods: {
      delCustomerCompanyM() {
        debugger
        this.store.dispatch('providers/deleteProviderCopmany', this.customer_company_selected.id);
        this.showDeleteDialog = false;
        setTimeout(() => {
          this.reloadData();
        }, 2000);
        const dataGrid = this.$refs[this.dataGridRefCustomerCompany].instance;
        dataGrid.clearSelection();
      },
      setDeleteDialog() {
        this.showDeleteDialog = true;
      },
      editCustomerCompany() {
        debugger
        if (this.selectedWorkingCatagory === undefined ||
          this.selectedWorkingCatagory === null ||
          this.selectedWorkingCatagory.id === undefined) {
          notify(this.$i18n.t('customer_provider.validataion_edit_part_selection'), 'warning', this.notifyTimeSet);
          return;
        }
        if (this.isStateOkTotal() > 0) {
          let give_me_errors = this.getElementsWithErorrs();
          notify(give_me_errors, 'error', this.notifyTimeSet);
          return;
        }
        this.NewCustomerCompanyInfo.WorkingCatagoryId = this.selectedWorkingCatagory.id;
        this.NewCustomerCompanyInfo.Id = this.customer_company_selected.id;
        this.store.dispatch('providers/editProviderCopmany', this.NewCustomerCompanyInfo);
        this.ShowDialogNew = false;
        setTimeout(() => {
          this.reloadData();
        }, 2000);
        const dataGrid = this.$refs[this.dataGridRefCustomerCompany].instance;
        dataGrid.clearSelection();
      },
      setEdit() {
  
        if (this.customer_company_selected === null ||
          this.customer_company_selected === undefined || this.customer_company_selected.title === undefined) {
          notify(this.$i18n.t('customer_provider.validation_pre_edit'), 'warning', this.notifyTimeSet);
          return;
        }
        this.makeInitSize(600, 600);
        this.NewCustomerCompanyInfo.Title = this.customer_company_selected.title;
        this.NewCustomerCompanyInfo.Description = this.customer_company_selected.description;
        this.NewCustomerCompanyInfo.Telephone = this.customer_company_selected.telephone;
        this.NewCustomerCompanyInfo.Fax = this.customer_company_selected.fax;
        this.NewCustomerCompanyInfo.Email = this.customer_company_selected.email;
        this.NewCustomerCompanyInfo.Address = this.customer_company_selected.address;
        this.NewCustomerCompanyInfo.Website = this.customer_company_selected.website;
        this.NewCustomerCompanyInfo.DocialMedia = this.customer_company_selected.socialMedia;
        this.NewCustomerCompanyInfo.MasterProject = this.customer_company_selected.masterProject;
        this.NewCustomerCompanyInfo.EconomyCode = this.customer_company_selected.economyCode;
        this.NewCustomerCompanyInfo.Ceo = this.customer_company_selected.ceo;
        this.NewCustomerCompanyInfo.ProjectManager = this.customer_company_selected.projectManager;
        this.NewCustomerCompanyInfo.ProjectExpert = this.customer_company_selected.projectExpert;
        debugger
        const holder_catagory = this.working_catagory_datasource.filter(x => x.id === this.customer_company_selected.workingCatagoryId)[0];
        this.selectedWorkingCatagory = holder_catagory;
        this.selectedWorkingCatagoryText = holder_catagory.title;
        this.gridBoxValue = holder_catagory;
        this.make_state('inputTitle', true);
        this.make_state('inputTelephone', true);
        //this.NewCustomerCompanyInfo.workingCatagoryId=this.customer_company_selected.workingCatagoryId;
        this.editMode = true;
        this.ShowDialogNew = true;
      },
      saveNewCustomerCompany() {
        debugger
        if (this.selectedWorkingCatagory === undefined ||
          this.selectedWorkingCatagory === null ||
          this.selectedWorkingCatagory.id === undefined) {
          notify(this.$i18n.t('customer_provider.validataion_add_part_selection'), 'warning', this.notifyTimeSet);
          return;
        }
        if (this.isStateOkTotal() > 0) {
          let give_me_errors = this.getElementsWithErorrs();
          notify(give_me_errors, 'error', this.notifyTimeSet);
          return;
        }
        this.NewCustomerCompanyInfo.WorkingCatagoryId = this.selectedWorkingCatagory.id;
        this.store.dispatch('providers/addNewProviderCopmany', this.NewCustomerCompanyInfo);
        this.ShowDialogNew = false;
        setTimeout(() => {
          this.reloadData();
        }, 2000);
        const dataGrid = this.$refs[this.dataGridRefCustomerCompany].instance;
        dataGrid.clearSelection();
      },
      gridBoxDisplayExpr() {
        return this.selectedWorkingCatagoryText;
      },
      changeDropDownBoxValue(e) {
        debugger
        this.selectedWorkingCatagory = e.currentSelectedRowKeys[0];
        this.isDropDownBoxOpened = false;
        if (this.selectedWorkingCatagory != undefined)
          this.selectedWorkingCatagoryText = this.selectedWorkingCatagory.title;
      },
      setShowDialogNew() {
        this.makeInitSize(600, 800);
        this.NewCustomerCompanyInfo = {};
        this.make_state('inputTitle', false);
        this.make_state('inputTelephone', false);
        this.editMode = false;
        this.ShowDialogNew = true;
      },
      reloadData() {
        this.store.dispatch('providers/getProvidersCopmany');
      },
      reloadDataWorkingCatagory() {
        this.store.dispatch('working_catagory/getWorkingCatagory');
      },
      onItemClick(e) {
        debugger
        switch (e.itemData.id) {
          case 1:
            this.NewUserPopup();
            break;
          case 2:
            this.reloadData();
            break;
          case 3:
            this.$router.go(-1);
            break;
          default:
            notify('خطای ناشناس در منو', 'warning', this.notifyTimeSet);
            break;
        }
      },
      onSelectionChanged(selectedRowsData) {
        debugger
        this.customer_company_selected = selectedRowsData.selectedRowsData[0];
        if (this.customer_company_selected != null || this.customer_company_selected != undefined) {
          this.customer_company_selected_text = this.customer_company_selected.title;
        }
  
      },
      onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
          location: "before",
          template: "btnRGroup",
        });
        e.toolbarOptions.items.unshift({
          location: "after",
          template: "btnGroup",
        });
      },
    },
  
  }
  </script>
  <style lang="scss">
  @import "@/dx-styles.scss";
  
  .dx-popup-wrapper>.dx-overlay-content {
    background: #fff;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
    border-radius: 4px;
    border-left-color: $popup_color;
    border-left-width: 9px;
  }
  
  #ps_Table {
    height: 60vh;
  }
  </style>