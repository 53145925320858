export default {
    getParts : state => {                
        return state.parts;
    },
    getBaseParts : state => {
        return state.BaseParts;
    },
    getSinglePart : state => {
        return state.singlePart;
    },
    getUser_part : state => {
        return state.user_part;
    }
}