export default {    
    user_edms_chart:[],
    user_uploads_chart:[],
    user_uploads_states_chart:[],
    user_comments_chart:[],
    // sale
    total_uploads_chart:[],
    total_uploads_states_chart:[],
    total_comments_chart:[],
    active_reminders:[],
    active_7Day_passed_reminders_toolbar_items:[],
    active_7Day_passed_reminders:[],
    active_7Day_passed_reminders_count:0,
    active_unread_messages_count:0,
    users_last:[],
}