<template>
  <div>
    <h2 class="content-block"> {{ $t('doctor_list.title') }}</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <DxDataGrid :data-source="doctor_list_datasource" :columns="doctor_list_columns" :ref="dataGridRefCustomerCompany"
          :show-row-lines="showRowLines" :show-column-lines="showColumnLines" :row-alternation-enabled="true"
          :rtl-enabled="true" :remote-operations="true" :column-auto-width="true" :allow-column-reordering="true"
          :allow-column-resizing="true" :hover-state-enabled="true" :noDataText="checkData"
          :selection="{ mode: 'single' }" @toolbar-preparing="onToolbarPreparing" @selection-changed="onSelectionChanged"
          class="mt-3" id="ps_Table" key-expr="id">

          <DxColumnChooser :enabled="true" />
          <DxExport :enabled="allowExportDataGrid" :allow-export-selected-data="allowExportSelectedDataGrid" />

          <DxHeaderFilter :visible="showHeaderFilter" />
          <DxSearchPanel :visible="true" :width="190" :placeholder="this.$i18n.t('doctor_list.grid_search')" class="" />

          <DxSelection :select-all-mode="SelectionAllMode" :show-check-boxes-mode="checkBoxesMode" mode="multiple" />

          <template #btnGroup class="row">
            <div class="d-lg-none">
              <div class="mt-2">
                <DxDropDownButton :items="Dropactions" :drop-down-options="{ width: 190 }"
                  :text="this.$i18n.t('doctor_list.drop_down_toolbar')" icon="save" @item-click="onItemClick" />
              </div>
            </div>
            <div class="d-none d-lg-block">
              <DxButton class="ml-2 bg-success" color="success" size="sm" icon="plus" @click="setShowDialogNew()">
              </DxButton>
              <DxButton color="secondary" class="ml-2" size="sm" icon="revert" @click="$router.go(-1)">
              </DxButton>
              <DxButton color="light" size="sm" icon="pulldown" @click="reloadData">
              </DxButton>
            </div>
          </template>


          <template #btnRGroup class="p-0 m-0">
            <div class="d-none d-lg-block">
              <div class="row">
                <div class="col-sm-12">
                  <button class="btn w-100 btn-sm mr-1 bg-warning" size="sm">{{ $t('doctor_list.drop_down_toolbar')
                  }}</button>
                </div>
              </div>
            </div>

          </template>
          <template #actions="{ data }">
            <div>
              <DxButton class="bg-warning m-1" icon="edit" type="edit" :hint="this.$i18n.t('doctor_list.grid_row_edit')"
                @click="setEdit(data)">
              </DxButton>
              <DxButton class="bg-danger m-1" icon="remove" :hint="this.$i18n.t('doctor_list.grid_row_delete')"
                @click="setDeleteDialog(data)">
              </DxButton>
            </div>
          </template>
          <template #radif="{ data }">
            {{ data.rowIndex + 1 }}
          </template>
          <DxFilterRow :visible="true" />
          <DxLoadPanel :enabled="true" />
          <DxScrolling mode="standard" :use-native="true" />
        </DxDataGrid>

      </div>
    </div>


    <DxPopup style="text-align: :center;" :width="this.showDialogNewWidth" :height="this.showDialogNewHeight"
      :show-title="true" :title="this.$i18n.t('doctor_list.dialog_new_header')" alignment="center" :drag-enabled="true"
      :hide-on-outside-click="false" v-model:visible="ShowDialogNew">
      <template #content="{ data }">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">

            <div class="card">
              <div class="card-body">
                <form>


                  <!-- Height-Width-Controller -->
                  <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                      <div class="d-flex flex-row">
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Height-Width-Controller -->

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend mt-2">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <DxDropDownBox v-model:value="gridBoxValue" v-model:opened="isDropDownBoxOpened"
                          :defer-rendering="false" :display-expr="gridBoxDisplayExpr" :show-clear-button="true"
                          :data-source="sub_working_catagory_datasource" value-expr="id"
                          :placeholder="this.$i18n.t('doctor_list.validate_working_selction')" width="90%">
                          <template #content="{ data }">
                            <DxDataGrid :data-source="sub_working_catagory_datasource" :columns="working_catagory_columns"
                              :hover-state-enabled="true" v-model:selected-row-keys="gridBoxValue"
                              @selection-changed="changeDropDownBoxValue($event)"
                              @content-ready="$event.component.selectItem(gridBoxValue)" height="100%"
                              ref="working_cat_grid">
                              <DxSelection mode="single" />

                              <DxPaging :enabled="false" />
                              <DxFilterRow :visible="true" />
                              <DxScrolling mode="standard" :use-native="true" />
                              <template #radif_1="{ data }">
                                {{ data.rowIndex + 1 }}
                              </template>
                            </DxDataGrid>
                          </template>
                        </DxDropDownBox>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-6">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewCustomerCompanyInfo.Name" @input="this.check_content($event, 'inputName')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputName'), 'border-success-my': this.isStateOk('inputName'), 'rounded': true }"
                          :placeholder="this.$i18n.t('doctor_list.dialog_new_name')" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewCustomerCompanyInfo.Family" @input="this.check_content($event, 'inputFamily')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputFamily'), 'border-success-my': this.isStateOk('inputFamily'), 'rounded': true }"
                          :placeholder="this.$i18n.t('doctor_list.dialog_new_family')" type="text">
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-6">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>          
                          <input v-model="NewCustomerCompanyInfo.LiscenceNo" class="form-control"
                          :placeholder="this.$i18n.t('doctor_list.dialog_new_liscence_no')" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewCustomerCompanyInfo.BusinessDays" class="form-control"
                          :placeholder="this.$i18n.t('doctor_list.dialog_new_business_days')" type="text">
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-6">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewCustomerCompanyInfo.Telephone"
                          @input="this.check_content($event, 'inputTelephone')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputTelephone'), 'border-success-my': this.isStateOk('inputTelephone'), 'rounded': true }"
                          :placeholder="this.$i18n.t('doctor_list.dialog_new_tel')" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewCustomerCompanyInfo.Mobile" class="form-control"
                          :placeholder="this.$i18n.t('doctor_list.dialog_new_mobile')" type="text">
                      </div>
                    </div>
                  </div>




                  <div class="row mt-2">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewCustomerCompanyInfo.Address" class="form-control"
                          :placeholder="this.$i18n.t('doctor_list.dialog_new_address')" type="text">
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewCustomerCompanyInfo.Email" class="form-control"
                          :placeholder="this.$i18n.t('doctor_list.dialog_new_email')" type="text">
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewCustomerCompanyInfo.Website" class="form-control"
                          :placeholder="this.$i18n.t('doctor_list.dialog_new_web')" type="text">
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="NewCustomerCompanyInfo.Description" class="form-control"
                          :placeholder="this.$i18n.t('doctor_list.dialog_new_desc')" type="text">
                      </div>
                    </div>
                  </div>


                  <div class="container mt-2">
                    <div class="row">

                      <div class="col-sm-6 p-0">
                        <div class="form-group input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <font-awesome-icon icon="fas fa-user" class="m-1" />
                            </span>
                          </div>
                          <input v-model="NewCustomerCompanyInfo.SocialMedia" class="form-control"
                            :placeholder="this.$i18n.t('doctor_list.dialog_new_social_address_company')" type="text">
                        </div>
                      </div>
                      <div class="col-sm-6 p-0">
                        <div class="form-group input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <font-awesome-icon icon="fas fa-user" class="m-1" />
                            </span>
                          </div>
                          <input v-model="NewCustomerCompanyInfo.Fax" class="form-control"
                            :placeholder="this.$i18n.t('doctor_list.dialog_new_fax')" type="text">
                        </div>
                      </div>
                    </div>
                  </div>






                  <button v-if="editMode" class="btn btn-success mt-3 me-1 ms-2" @click="editCustomerCompany($event)"><i
                      class="fa fa-home"></i>{{ $t('doctor_list.dialog_new_btn_edit') }}</button>
                  <button v-else class="btn btn-success mt-3 me-1 ms-2" @click="saveNewCustomerCompany"><i
                      class="fa fa-home"></i>{{ $t('doctor_list.dialog_new_btn_save') }}</button>
                  <button class="btn btn-warning mt-3" @click="this.ShowDialogNew = false;"><i class="fa fa-home"></i>{{
                    $t('doctor_list.dialog_new_btn_back') }}</button>
                </form>

                <div class="card-footer text-muted mt-2">{{ $t('doctor_list.dialog_new_footer_label') }}</div>
              </div>
            </div>

          </div>
        </DxScrollView>
      </template>
    </DxPopup>






    <customDial :show="showDeleteDialog" :cancel="() => { this.showDeleteDialog = false; }" :confirm="delCustomerCompanyM"
      :title="this.$i18n.t('doctor_list.delete_dialog_title')" :userPersonalInfo="doctor_list_selected_text"
      :description="this.$i18n.t('doctor_list.delete_dialog_body')" />

  </div>
</template>
<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxForm, DxItem } from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import { DxDataGrid, DxSelection } from 'devextreme-vue/data-grid';
import { dxGrid } from '@/components/dxGrid'
import DxButton from 'devextreme-vue/button';
import { mapGetters, useStore } from 'vuex';
import MyValidator from '@/components/MyValidator'
import notify from 'devextreme/ui/notify';
import customDial from '@/components/customDialog.vue'
import themes from 'devextreme/ui/themes';
import { computed } from '@vue/reactivity';
import DxDropDownButton from 'devextreme-vue/drop-down-button';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import users_compo from '@/components/users_compo.vue'
import { DxDropDownBox } from 'devextreme-vue/drop-down-box';
import responsives from '@/utils/responsives';


export default {
  setup() {
    const store = useStore();
    return {
      store,
      doctor_list_datasource: computed(() => store.getters['doctor_list/getDoctorList']),
      working_catagory_datasource: computed(() => store.getters['working_catagory/getWorkingCatagory']),
      sub_working_catagory_datasource: computed(() => store.getters['sub_working_catagory/getBaseSubWorkingCatagory']),
    }
  },
  mixins: [dxGrid, MyValidator, responsives],
  components: {
    DxButton,
    DxDataGrid,
    DxPopup,
    DxForm,
    DxSelectBox,
    DxDropDownButton,
    DxScrollView,
    DxDropDownBox,
    customDial
  },
  data() {
    return {
      dataGridRefCustomerCompany: 'dataGrid',
      doctor_list_selected: {},
      doctor_list_selected_text: '',
      working_catagory_columns: [
        { dataField: 'id', caption: this.$i18n.t('doctor_list.grid_working_category_id'), dataType: 'string', visible: false },
        { dataField: 'workingCatagory', caption: this.$i18n.t('doctor_list.grid_working_category_title'), dataType: 'string' },
        { dataField: 'subWorkingCatagory', caption: this.$i18n.t('doctor_list.grid_working_category_description'), dataType: 'string', alignment: 'center' },
      ],
      isDropDownBoxOpened: false,
      selectedWorkingCatagory: {},
      selectedWorkingCatagoryText: '',
      gridBoxValue: null,
      editMode: false,
      NewCustomerCompanyInfo: {},
      ShowDialogNew: false,
      doctor_list_columns: [
        { cellTemplate: "radif", caption: this.$i18n.t('doctor_list.grid_row_no'), dataType: 'number', allowFiltering: false, width: 75, alignment: "center" },
        { cellTemplate: 'actions', caption: this.$i18n.t('doctor_list.grid_row_operation'), allowFiltering: false, allowSorting: false },
        { dataField: 'id', caption: this.$i18n.t('doctor_list.grid_row_id'), dataType: 'string', visible: false },
        { dataField: 'subWorkingCatagoryId', caption: this.$i18n.t('doctor_list.grid_row_workingCatagoryId'), dataType: 'string', visible: false },
        { dataField: 'name', caption: this.$i18n.t('doctor_list.dialog_new_name'), dataType: 'string', alignment: 'center', format: { type: "fixedPoint", precision: 0 } },
        { dataField: 'family', caption: this.$i18n.t('doctor_list.dialog_new_family'), dataType: 'string', alignment: 'center', format: { type: "fixedPoint", precision: 0 } },
        { dataField: 'description', caption: this.$i18n.t('doctor_list.grid_row_description'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'telephone', caption: this.$i18n.t('doctor_list.grid_row_telephone'), dataType: 'string', alignment: 'center' },
        { dataField: 'mobile', caption: this.$i18n.t('doctor_list.grid_row_mobile'), dataType: 'string', alignment: 'center', visible: true },
        { dataField: 'email', caption: this.$i18n.t('doctor_list.grid_row_email'), dataType: 'string', alignment: 'center' },
        { dataField: 'address', caption: this.$i18n.t('doctor_list.grid_row_address'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'website', caption: this.$i18n.t('doctor_list.grid_row_website'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'socialMedia', caption: this.$i18n.t('doctor_list.grid_row_socialMedia'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'liscneceNo', caption: this.$i18n.t('doctor_list.grid_row_liscence_no'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'businessDays', caption: this.$i18n.t('doctor_list.grid_row_business_days'), dataType: 'string', alignment: 'center', visible: false },
      ],
      NewshowDialog: false,
      showDeleteDialog: false,
      Dropactions: [
        { id: 1, text: this.$i18n.t('doctor_list.drop_down_toolbar_new_item'), icon: "user" },
        { id: 2, text: this.$i18n.t('doctor_list.drop_down_toolbar_new_reload'), icon: "refresh" },
        { id: 3, text: this.$i18n.t('doctor_list.drop_down_toolbar_new_back'), icon: "undo" },
        { id: 4, text: "تنظیمات1", icon: "preferences" },
        { id: 5, text: "تنظیمات2", icon: "preferences" },
        { id: 6, text: "تنظیمات3", icon: "preferences" },
      ],
      checkBoxesMode: themes.current().startsWith('material') ? 'always' : 'onClick',
    }
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      debugger
      const dataGrid = this.$refs.dataGrid.instance;
      this.$refs.dataGrid.columns[0].caption = this.$i18n.t('doctor_list.grid_row_no');
      this.$refs.dataGrid.columns[1].caption = this.$i18n.t('doctor_list.grid_row_operation');
      this.$refs.dataGrid.columns[2].caption = this.$i18n.t('doctor_list.grid_row_id');
      this.$refs.dataGrid.columns[3].caption = this.$i18n.t('doctor_list.grid_row_title');
      this.$refs.dataGrid.columns[4].caption = this.$i18n.t('doctor_list.grid_row_workingCatagoryId');
      this.$refs.dataGrid.columns[5].caption = this.$i18n.t('doctor_list.grid_row_economyCode');
      this.$refs.dataGrid.columns[6].caption = this.$i18n.t('doctor_list.grid_row_title');
      this.$refs.dataGrid.columns[7].caption = this.$i18n.t('doctor_list.grid_row_description');
      this.$refs.dataGrid.columns[8].caption = this.$i18n.t('doctor_list.grid_row_telephone');
      this.$refs.dataGrid.columns[9].caption = this.$i18n.t('doctor_list.grid_row_fax');
      this.$refs.dataGrid.columns[10].caption = this.$i18n.t('doctor_list.grid_row_email');
      this.$refs.dataGrid.columns[11].caption = this.$i18n.t('doctor_list.grid_row_address');
      this.$refs.dataGrid.columns[12].caption = this.$i18n.t('doctor_list.grid_row_website');
      this.$refs.dataGrid.columns[13].caption = this.$i18n.t('doctor_list.grid_row_socialMedia');
      this.$refs.dataGrid.columns[14].caption = this.$i18n.t('doctor_list.grid_row_ceo');
      // this.$refs.dataGrid.columns[15].caption = this.$i18n.t('doctor_list.grid_row_masterProject');

      dataGrid.option('columns', dataGrid.option('columns'));


      if (this.$refs.working_cat_grid != null) {
        const dataGrid_working_categroy = this.$refs.working_cat_grid.instance;
        this.$refs.working_cat_grid.columns[0].caption = this.$i18n.t('doctor_list.grid_working_category_id');
        this.$refs.working_cat_grid.columns[1].caption = this.$i18n.t('doctor_list.grid_working_category_title');
        this.$refs.working_cat_grid.columns[2].caption = this.$i18n.t('doctor_list.grid_working_category_description');
        dataGrid_working_categroy.option('columns', dataGrid_working_categroy.option('columns'));
      }
    }
  },
  created() {
    this.make_state_bind('inputName', false, this.$i18n.t('doctor_list.validate_title_company'));
    this.make_state_bind('inputFamily', false, this.$i18n.t('doctor_list.validate_title_company'));
    this.make_state_bind('inputTelephone', false, this.$i18n.t('doctor_list.validate_telephone_company'));
    this.reloadData();
    // this.reloadDataWorkingCatagory();
    this.reloadDataSubWorkingCatagory();
  },
  methods: {
    delCustomerCompanyM() {
      debugger
      this.store.dispatch('doctor_list/deleteDoctor', this.doctor_list_selected.id);
      this.showDeleteDialog = false;
      setTimeout(() => {
        this.reloadData();
      }, 2000);
      const dataGrid = this.$refs[this.dataGridRefCustomerCompany].instance;
      dataGrid.clearSelection();
    },
    setDeleteDialog(data_sent) {
      debugger
      this.doctor_list_selected = data_sent.data;
      this.doctor_list_selected_text = this.doctor_list_selected.name + ' '+this.doctor_list_selected.family
      this.showDeleteDialog = true;
    },
    editCustomerCompany() {
      debugger
      if (this.selectedWorkingCatagory.length <= 0) {
        notify(this.$i18n.t('doctor_list.validataion_add_part_selection'), 'warning', this.notifyTimeSet);
        return;
      }
      if (this.isStateOkTotal() > 0) {
        let give_me_errors = this.getElementsWithErorrs();
        notify(give_me_errors, 'error', this.notifyTimeSet);
        return;
      }
      let dataHolder = {
        Id :this.doctor_list_selected.id,
        WorkingCatagoryId : this.selectedWorkingCatagory[0] == undefined ? this.selectedWorkingCatagory.id:this.selectedWorkingCatagory[0].id,
        Name : this.NewCustomerCompanyInfo.Name,
        Family : this.NewCustomerCompanyInfo.Family,
        Telephone : this.NewCustomerCompanyInfo.Telephone,
        Mobile : this.NewCustomerCompanyInfo.Mobile,
        Fax : this.NewCustomerCompanyInfo.Fax,
        Email : this.NewCustomerCompanyInfo.Email,
        Address : this.NewCustomerCompanyInfo.Address,
        Website : this.NewCustomerCompanyInfo.Website,
        Description : this.NewCustomerCompanyInfo.Description,
        SocialMedia : this.NewCustomerCompanyInfo.SocialMedia,
        LiscneceNo : this.NewCustomerCompanyInfo.LiscenceNo,
        BusinessDays : this.NewCustomerCompanyInfo.BusinessDays,
      }
      
      this.store.dispatch('doctor_list/editDoctor', dataHolder);
      this.ShowDialogNew = false;
      setTimeout(() => {
        this.reloadData();
      }, 2000);
      const dataGrid = this.$refs[this.dataGridRefCustomerCompany].instance;
      dataGrid.clearSelection();
    },
    setEdit(data_sent) {
      debugger
      this.doctor_list_selected = data_sent.data;
      if (this.doctor_list_selected === null ||
        this.doctor_list_selected === undefined || this.doctor_list_selected.name === undefined) {
        notify(this.$i18n.t('doctor_list.validation_pre_edit'), 'warning', this.notifyTimeSet);
        return;
      }
      this.makeInitSize(600, 690);      
      this.NewCustomerCompanyInfo.Name = this.doctor_list_selected.name;
      this.NewCustomerCompanyInfo.Family = this.doctor_list_selected.family;
      this.NewCustomerCompanyInfo.Description = this.doctor_list_selected.description;
      this.NewCustomerCompanyInfo.Mobile = this.doctor_list_selected.mobile;
      this.NewCustomerCompanyInfo.Telephone = this.doctor_list_selected.telephone;
      this.NewCustomerCompanyInfo.Fax = this.doctor_list_selected.fax;
      this.NewCustomerCompanyInfo.Email = this.doctor_list_selected.email;
      this.NewCustomerCompanyInfo.Address = this.doctor_list_selected.address;
      this.NewCustomerCompanyInfo.Website = this.doctor_list_selected.website;
      this.NewCustomerCompanyInfo.SocialMedia = this.doctor_list_selected.socialMedia;
      this.NewCustomerCompanyInfo.LiscenceNo  = this.doctor_list_selected.liscneceNo;
      this.NewCustomerCompanyInfo.BusinessDays = this.doctor_list_selected.businessDays;

      debugger
      const holder_catagory = this.sub_working_catagory_datasource.filter(x => x.id === this.doctor_list_selected.workingCatagoryId);
      this.selectedWorkingCatagoryText = holder_catagory[0].subWorkingCatagory;
      this.selectedWorkingCatagory = holder_catagory;      
      this.gridBoxValue = holder_catagory;

      this.make_state('inputName', true);
      this.make_state('inputFamily', true);
      this.make_state('inputTelephone', true);
      //this.NewCustomerCompanyInfo.workingCatagoryId=this.doctor_list_selected.workingCatagoryId;
      this.editMode = true;
      this.ShowDialogNew = true;
    },
    saveNewCustomerCompany() {
      debugger
      if (this.selectedWorkingCatagory === undefined ||
        this.selectedWorkingCatagory === null ||
        this.selectedWorkingCatagory.id === undefined) {
        notify(this.$i18n.t('doctor_list.validataion_add_part_selection'), 'warning', this.notifyTimeSet);
        return;
      }
      if (this.isStateOkTotal() > 0) {
        let give_me_errors = this.getElementsWithErorrs();
        notify(give_me_errors, 'error', this.notifyTimeSet);
        return;
      }
      let dataHolder = {
        WorkingCatagoryId : this.selectedWorkingCatagory.id,
        Name : this.NewCustomerCompanyInfo.Name,
        Family : this.NewCustomerCompanyInfo.Family,
        Telephone : this.NewCustomerCompanyInfo.Telephone,
        Mobile : this.NewCustomerCompanyInfo.Mobile,
        Fax : this.NewCustomerCompanyInfo.Fax,
        Email : this.NewCustomerCompanyInfo.Email,
        Address : this.NewCustomerCompanyInfo.Address,
        Website : this.NewCustomerCompanyInfo.Website,
        Description : this.NewCustomerCompanyInfo.Description,
        SocialMedia : this.NewCustomerCompanyInfo.SocialMedia,
        LiscneceNo : this.NewCustomerCompanyInfo.LiscenceNo,
        BusinessDays : this.NewCustomerCompanyInfo.BusinessDays,
      }

      debugger
      // const holder_catagory = this.working_catagory_datasource.filter(x => x.id === this.doctor_list_selected.workingCatagoryId)[0];
      // this.selectedWorkingCatagory = holder_catagory;
      // this.selectedWorkingCatagoryText = holder_catagory.title;
      // this.gridBoxValue = holder_catagory;

      // this.NewCustomerCompanyInfo.WorkingCatagoryId = this.selectedWorkingCatagory.id;
      this.store.dispatch('doctor_list/addNewDoctor', dataHolder);
      this.ShowDialogNew = false;
      setTimeout(() => {
        this.reloadData();
      }, 2000);
      const dataGrid = this.$refs[this.dataGridRefCustomerCompany].instance;
      dataGrid.clearSelection();
    },
    gridBoxDisplayExpr() {
      return this.selectedWorkingCatagoryText;
    },
    changeDropDownBoxValue(e) {
      debugger
      this.selectedWorkingCatagory = e.currentSelectedRowKeys[0];
      this.isDropDownBoxOpened = false;
      if (this.selectedWorkingCatagory != undefined)
        this.selectedWorkingCatagoryText = this.selectedWorkingCatagory.subWorkingCatagory;
    },
    setShowDialogNew() {
      this.makeInitSize(600, 690);
      this.NewCustomerCompanyInfo = {};
      this.make_state('inputName', false);
      this.make_state('inputFamily', false);
      this.make_state('inputTelephone', false);
      this.editMode = false;
      this.ShowDialogNew = true;
    },
    reloadData() {
      this.store.dispatch('doctor_list/getDoctorList');
    },
    reloadDataWorkingCatagory() {
      this.store.dispatch('working_catagory/getWorkingCatagory');
    },
    reloadDataSubWorkingCatagory() {
      this.store.dispatch('sub_working_catagory/getBaseSubWorkingCatagory');
    },
    onItemClick(e) {
      debugger
      switch (e.itemData.id) {
        case 1:
          this.NewUserPopup();
          break;
        case 2:
          this.reloadData();
          break;
        case 3:
          this.$router.go(-1);
          break;
        default:
          notify('خطای ناشناس در منو', 'warning', this.notifyTimeSet);
          break;
      }
    },
    onSelectionChanged(selectedRowsData) {
      debugger
      this.doctor_list_selected = selectedRowsData.selectedRowsData[0];
      if (this.doctor_list_selected != null || this.doctor_list_selected != undefined) {
        this.doctor_list_selected_text = this.doctor_list_selected.title;
      }

    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "btnRGroup",
      });
      e.toolbarOptions.items.unshift({
        location: "after",
        template: "btnGroup",
      });
    },
  },

}
</script>
<style lang="scss">
@import "@/dx-styles.scss";

.dx-popup-wrapper>.dx-overlay-content {
  background: #fff;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border-left-color: $popup_color;
  border-left-width: 9px;
}

#ps_Table {
  height: 60vh;
}
</style>