<template>
  <div>
    <h2 class="content-block">صفحه اصلی</h2>
    <div class="content-block">
      <!-- <div class="dx-card gallery-frame mobile-hide">
        <DxGallery id="gallery" :data-source="dataSource" :loop="loop" :slideshow-delay="slideshowDelay"
          :show-nav-buttons="showNavButtons" :show-indicator="showIndicator" :height="600" :wrapAround="false" />



      </div> -->
      <div class="service-frame" data-aos="fade-up" data-aos-duration="3000">
        <myCarousel></myCarousel>
      </div>

      <div class="service-frame dx-card responsive-paddings mobile-hide" data-aos="fade-down" data-aos-duration="3000">
        <service_list></service_list>
      </div>

      <div class="service-frame dx-card responsive-paddings" >
        <introBar></introBar>
      </div>

      <div class="service-frame dx-card responsive-paddings" >
        <introBanner></introBanner>
      </div>

      <div class="service-frame dx-card responsive-paddings">
        <serviceCards></serviceCards>
      </div>


    </div>
  </div>
</template>
<script>
// import DxGallery from 'devextreme-vue/gallery';
import service_list from '@/components/service_list.vue';
import introBar from '@/components/introBar.vue';
import introBanner from '@/components/introBanner.vue';
import serviceCards from '@/components/serviceCards.vue';
import myCarousel from '@/components/myCarousel.vue';

export default {
  components: {
    // DxGallery,
    service_list,
    introBar,
    introBanner,
    serviceCards,
    myCarousel
  },

  data() {
    return {
      dataSource: [
        // require('@/assets/img/m10.jpg'),
        // require('@/assets/img/m11.jpg'),
        // require('@/assets/img/m12.jpg'),
        // require('@/assets/img/m13.jpg'),
        require('@/assets/clinic/1.jpg'),
        require('@/assets/clinic/3.jpg'),
        require('@/assets/clinic/4.jpg'),
        require('@/assets/clinic/6.jpg'),



      ],
      loop: true,
      slideshowDelay: 2000,
      showNavButtons: true,
      showIndicator: true,
    };
  },

  methods: {

    setSlideshowDelay(e) {
      this.slideshowDelay = e.value ? 2000 : 0;
    },
  },
};
</script>

<style lang="scss">
.screen-x-small .content-block {
  margin-right: 1px !important;
  margin-left: 1px !important;
}

#gallery {
  margin: auto;
  max-width: 100%;
}

.vpd-actions {
  button {
    font-family: dana;
  }
}


.gallery-frame {
  padding: 10px !important;

  .dx-gallery-item-content {

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .dx-gallery-item-image {
    object-fit: cover;
  }
}

.service-frame {
  margin-top: 10px;
}


.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;

    &.plus {
      margin: 0;
    }
  }
}
</style>
