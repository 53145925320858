export default {
    setTitle(state,data)
    {
        state.title=data;
    },
    setUsers(state,data)
    {        
        // if (data) {
        //     for (let i = 0; i < data.length; i++) {
        //         let holder_info ={'name':data[i].name,'phone':data[i].phone,'username':data[i].username,'website':data[i].website};
        //         state.users.push(holder_info);
        //     }
        // }        
        state.users=data;
    },
    setUser_menu_roles(state,data)
    {
        state.user_menu_roles=data;
    },
    setUsers_in_part(state,data)
    {
        state.users_in_part = data;
    }
}