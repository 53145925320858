<template>
  <div class="container-fluid">
    <div class="row content">
      <div class="col-sm-3 border rounded p-4">
        <!-- <h2>داشبورد فروشش</h2>
        <hr /> -->
        <div class="card text-white bg-danger mb-3">
          <div class="card-header"> {{ $t('sale_dashboard.RectedFiles') }}</div>
          <div class="card-body">
            <h5 class="card-title">{{ $t('sale_dashboard.RectedFilesCount') }} {{ DeniedCount }}</h5>

          </div>
        </div>

        <div class="card text-white bg-warning mb-3">
          <div class="card-header">{{ $t('sale_dashboard.NewUndoFiles') }}</div>
          <div class="card-body">
            <h5 class="card-title">{{ $t('sale_dashboard.NewUndoFilesCount') }} {{ NewUnUploadCount }}</h5>
          </div>
        </div>

        <div class="card text-white bg-success mb-3">
          <div class="card-header">{{ $t('sale_dashboard.ApprivedFiles') }}</div>
          <div class="card-body">
            <h5 class="card-title">{{ $t('sale_dashboard.ApprivedFilesCount') }} {{ AcceptedCount }}</h5>

          </div>
        </div>

        <div class="card text-white bg-info mb-3">
          <div class="card-header">{{ $t('sale_dashboard.InProgressFiles') }}</div>
          <div class="card-body">
            <h5 class="card-title">{{ $t('sale_dashboard.InProgressFilesCount') }} {{ InProgressCount }}</h5>
          </div>
        </div>

        <div class="row">
          <button type="button" class="btn btn-dark m-1 p-3 w-100">
            {{ $t('sale_dashboard.Edms_New') }} <span class="badge badge-danger bg-info p-3">{{ NewUnUploadCount
            }}</span>
          </button>
        </div>

        <div class="row">
          <button type="button" class="btn btn-dark m-1 p-3 w-100">
            {{ $t('sale_dashboard.Edms_InProgress') }} <span class="badge badge-warning bg-warning p-3">{{
                InProgressCount
            }}</span>
          </button>
        </div>

        <div class="row">
          <button type="button" class="btn btn-dark m-1 p-3 w-100">
            {{ $t('sale_dashboard.Edms_Approved') }} <span class="badge badge-success bg-success p-3">{{ AcceptedCount
            }}</span>
          </button>
        </div>

        <div class="row">
          <button type="button" class="btn btn-dark m-1 p-3 w-100">
            {{ $t('sale_dashboard.Edms_Rejected') }} <span class="badge badge-success bg-danger p-3">{{ DeniedCount
            }}</span>
          </button>
        </div>
      </div>
      <br />

      <div class="col-sm-9 rounded" style="padding: 10px">

        <div class="row" v-show="showMoreGrid">
          <DxDataGrid :data-source="GriddataSource" :remote-operations="false" :allow-column-reordering="true"
            :row-alternation-enabled="false" :columns="userCommentsColumn" :show-borders="true" class="mr-5 mt-3"
            width="90%" height="40vh" ref="main_grid" @row-prepared="onRowPrepared">


            <DxGroupPanel :visible="false" />
            <DxSearchPanel :visible="false" :highlight-case-sensitive="true" />
            <DxGrouping :auto-expand-all="false" />
            <template #radif="{ data }">
              {{ data.rowIndex + 1 }}
            </template>
            <template #actions="{ data }">
              <div>
                <Button class="btn" target="_blank" :title="this.$i18n.t('sale_dashboard.grid_row_btn_change_status') +' : '+ data.row.data.title"
                  @click="changeSeenStatus(data.row.data)">
                  <font-awesome-icon v-if="data.row.data.seen === false" icon="fas fa-microphone-slash" class="m-3" />
                  <font-awesome-icon v-if="data.row.data.seen === true" icon="fas fa-microphone" class="m-3" />
                </Button>
              </div>
            </template>

          </DxDataGrid>
        </div>

        <div class="row">
          <div class="col-sm-5 rounded">
            <div class="mr-3">
              <chart_line v-if="renderEnabled" :ChartTitle="this.$i18n.t('sale_dashboard.ChartTitle')"
                :ChartData="user_uploads" ref="LineRef">
              </chart_line>
            </div>
          </div>
          <div class="col-sm-5 rounded">
            <div class="mr-5">



              <chart_pie v-if="pie_renderEnabled" :ChartTitle="this.$i18n.t('sale_dashboard.ChartEdmsTypes')"
                ChartArgument="typeOp" ChartValue="valueOp" :ChartData="user_uploads" ref="PieRef"
                @DataLoaded="DataLoadedEmited"></chart_pie>
              <!-- <chart_area ChartTitle="نمودار الزامات - روندکلی"></chart_area> -->
            </div>
          </div>
        </div>
        <div class="row"></div>
        <hr />

        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <!-- <button type="button" class="btn btn-secondary">1</button>
          <button type="button" class="btn btn-secondary">2</button> -->

          <!-- <div class="btn-group" role="group">
            <button id="btnGroupDrop1" type="button" class="btn btn-warning dropdown-toggle" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" >
              @click="setChartShow"
              اطلاع رسان - داشبورد
            </button>
            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <a class="dropdown-item" @click="setChartShow">نمایش نمودار های تحلیل</a>
              <a class="dropdown-item" @click="setGridShow">نمایش پیغام های سیستم</a>
            </div>
          </div> -->

        </div>



        <div class="row" v-show="showMoreChart">
          <div class="col-sm-5 rounded">
            <div class="">
              <!-- <h4>Users</h4>
              <p>1 Million</p> -->
              <chart_line id="chart3" :ChartTitle="this.$i18n.t('sale_dashboard.ChartTitle')"></chart_line>
            </div>
          </div>
          <div class="col-sm-5 mr-5 rounded">
            <div class="">
              <!-- <h4>Pages</h4>
              <p>100 Million</p> -->
              <chart_pie :ChartTitle="this.$i18n.t('sale_dashboard.ChartTitle')"></chart_pie>
            </div>
          </div>
          <div class="col-sm-3 mr-5 rounded" hidden>
            <div class="">
              <!-- <h4>Sessions</h4>
              <p>10 Million</p> -->
              <!-- <chart_gauge
               ChartTitle="نمودار الزامات-آپلود ها"></chart_gauge> -->
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>
<script>
import chart_line from "@/components/chart/chart_line";
import chart_pie from "@/components/chart/chart_pie";
// import chart_area from "@/components/chart/chart_area";
// import chart_gauge from "@components/chart/chart_gauge";
// import chart_sankey from "@components/chart/chart_sankey";

import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { useStore } from "vuex";
import { computed } from "vue";
import DxPieChart, {
  DxSeries,
  DxLabel,
  DxConnector,
  DxSmallValuesGrouping,
  DxLegend,
  DxExport,
} from "devextreme-vue/pie-chart";

export default {
  setup() {
    const store = useStore();



    return {
      store,
      user_uploads: computed(() => store.getters['charts/getTotal_uploads_chart']),
      user_uploads_states: computed(() => store.getters['charts/getTotal_uploads_states_chart']),
      GriddataSource: computed(() => store.getters['charts/getActive_Reminders']),
    }
  },
  components: {
    chart_line,
    chart_pie,
    DxDataGrid,
    DxColumn,
    DxGrouping,
    DxGroupPanel,
    DxSearchPanel,

    DxPieChart,
    DxSeries,
    DxLabel,
    DxConnector,
    DxSmallValuesGrouping,
    DxLegend,
    DxExport,
  },
  data() {
    return {
      renderEnabled: false,
      pie_renderEnabled: false,
      NewUnUploadCount: 0,
      InProgressCount: 0,
      AcceptedCount: 0,
      DeniedCount: 0,

      pie_data: [],
      showMoreChart: false,
      showMoreGrid: true,
      userCommentsColumn: [
        { cellTemplate: "radif", caption: this.$i18n.t('sale_dashboard.grid_row_no'), dataType: 'number', allowFiltering: false, width: 50, alignment: "center" },
        { cellTemplate: 'actions', caption: this.$i18n.t('sale_dashboard.grid_row_actions'), dataType: 'string', alignment: 'center' },
        { dataField: 'title', caption: this.$i18n.t('sale_dashboard.grid_row_title'), dataType: 'string', alignment: 'center' },
        { dataField: 'innerNo', caption: this.$i18n.t('sale_dashboard.grid_row_innerNo'), dataType: 'string', alignment: 'center', },
        { dataField: 'outerNo', caption: this.$i18n.t('sale_dashboard.grid_row_outerNo'), dataType: 'string', alignment: 'center' },
        { dataField: 'dateRequired', caption: this.$i18n.t('sale_dashboard.grid_row_dateRequired'), dataType: 'string', alignment: 'center' },
        { dataField: 'company', caption: this.$i18n.t('sale_dashboard.grid_row_company'), dataType: 'string', alignment: 'center' },
        { dataField: 'fileEdmsTitle', caption: this.$i18n.t('sale_dashboard.grid_row_fileEdmsTitle'), dataType: 'string', alignment: 'center' },
        { dataField: 'poi', caption: this.$i18n.t('sale_dashboard.grid_row_poi'), dataType: 'string', alignment: 'center' },
        { dataField: 'dateSaved', caption: this.$i18n.t('sale_dashboard.grid_row_dateSaved'), dataType: 'string', alignment: 'center' },
        { dataField: 'versionStatus', caption: this.$i18n.t('sale_dashboard.grid_row_versionStatus'), dataType: 'string', alignment: 'center' },
        { dataField: 'edmsReminderId', caption: this.$i18n.t('sale_dashboard.grid_row_edmsReminderId'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'seen', caption: this.$i18n.t('sale_dashboard.grid_row_seen_status'), dataType: 'boolean', alignment: 'center', visible: true },
      ],
    };
  },
  methods: {
    changeSeenStatus(data){
      debugger
      if(data.seen === true)
      {
        this.store.dispatch('charts/setUnSeen_7Passed_Reminders', data.edmsReminderId);
      }
      else if(data.seen === false)
      {
        this.store.dispatch('charts/setSeen_7Passed_Reminders', data.edmsReminderId);
      }
    
      setTimeout(() => {
        this.reloadData();
      }, 2000);
    },
    onRowPrepared(e) {
      if (e.rowType === "data") {
        debugger
        if (e.data.seen === false) {
          e.rowElement.style.background = "#fc937c";
        }
        else {
          e.rowElement.style.background = "#75d99f";
        }
      }
    },
    DataLoadedEmited() {
      debugger
      this.pie_renderEnabled = false;
      this.pie_renderEnabled = true;
    },
    formatLabel(pointInfo) {
      return `${pointInfo.argumentText}: ${pointInfo.valueText}%`;
    },
    setChartShow() {
      debugger
      if (this.showMoreChart == true) {
        this.showMoreChart = false;
        this.showMoreGrid = true;
      }
      else {
        this.showMoreGrid = false;
        this.showMoreChart = true;

      }

    },
    setGridShow() {
    },

    reloadData() {
      this.store.dispatch('charts/GetActive_Reminders')
      this.store.dispatch('charts/GetTotal_uploads_chart')
      this.store.dispatch('charts/GetTotal_uploads_states_chart')
    }
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      debugger
      const dataGrid = this.$refs.main_grid.instance;
      this.$refs.main_grid.columns[0].caption = this.$i18n.t('sale_dashboard.grid_row_no');
      this.$refs.main_grid.columns[1].caption = this.$i18n.t('sale_dashboard.grid_row_title');
      this.$refs.main_grid.columns[2].caption = this.$i18n.t('sale_dashboard.grid_row_innerNo');
      this.$refs.main_grid.columns[3].caption = this.$i18n.t('sale_dashboard.grid_row_outerNo');
      this.$refs.main_grid.columns[4].caption = this.$i18n.t('sale_dashboard.grid_row_dateRequired');
      this.$refs.main_grid.columns[5].caption = this.$i18n.t('sale_dashboard.grid_row_company');
      this.$refs.main_grid.columns[6].caption = this.$i18n.t('sale_dashboard.grid_row_fileEdmsTitle');
      this.$refs.main_grid.columns[7].caption = this.$i18n.t('sale_dashboard.grid_row_poi');
      this.$refs.main_grid.columns[8].caption = this.$i18n.t('sale_dashboard.grid_row_dateSaved');
      this.$refs.main_grid.columns[9].caption = this.$i18n.t('sale_dashboard.grid_row_versionStatus');
      this.$refs.main_grid.columns[10].caption = this.$i18n.t('sale_dashboard.grid_row_edmsReminderId');
      this.$refs.main_grid.columns[11].caption = this.$i18n.t('sale_dashboard.grid_row_seen_status');


      dataGrid.option('columns', dataGrid.option('columns'));

      this.renderEnabled = false;
      this.pie_renderEnabled = false;

      setTimeout(() => {
        debugger
        this.renderEnabled = true;
        this.pie_renderEnabled = true;
      }, 50);

    },
    user_uploads() {
      //debugger

      this.NewUnUploadCount = this.user_uploads[0].unUploaded;
      this.InProgressCount = this.user_uploads[0].inProgress;
      this.AcceptedCount = this.user_uploads[0].accepted_Holder;
      this.DeniedCount = this.user_uploads[0].denied;

      this.pie_data.push({
        typeOp: this.$i18n.t('sale_dashboard.chart_series_new'),
        count: this.user_uploads[0].unUploaded
      });

      this.pie_data.push({
        typeOp: this.$i18n.t('sale_dashboard.chart_series_in_progress'),
        count: this.user_uploads[0].inProgress,
      });

      this.pie_data.push({
        typeOp: this.$i18n.t('sale_dashboard.chart_series_approved'),
        count: this.user_uploads[0].accepted_Holder,
      });

      this.pie_data.push({
        typeOp: this.$i18n.t('sale_dashboard.chart_series_rejected'),
        count: this.user_uploads[0].denied,
      });



      this.$forceUpdate();
      this.renderEnabled = true;
      this.pie_renderEnabled = true;

      if (this.$refs.PieRef != undefined) {
        console.log()
        setTimeout(() => {
          console.log("PieRef.refreshChart");
          this.$refs.PieRef.refreshChart();
        }, 1000);
      }

      if (this.$refs.LineRef != undefined) {
        setTimeout(() => {
          console.log("LineRef.refreshChart");
          this.$refs.LineRef.refreshChart();
        }, 1000);
      }

    },
  },
  created() {
    this.reloadData();
  },
};
</script>
<style scoped>

</style>

