<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar-my">
      <dx-item :visible="menuToggleEnabled" location="before" css-class="menu-button">
        <template #default>
          <dx-button icon="menu" styling-mode="text" @click="toggleMenuFunc" />
        </template>
      </dx-item>

      <dx-item v-if="title" location="before" css-class="header-title dx-toolbar-label">
        <div class="logo-frame">
          <div>{{ title }}</div>
          <div class="logo-img">
            <img src="@/assets/logo.png" alt="Logo" />
          </div>
        </div>
      </dx-item>

      <dx-item location="after" locate-in-menu="auto" menu-item-template="menuUserItem">


        <!-- This is the desktop view -->
        <template #default>
          <div class="Maincontainer">
            <div class="NotifiBar">

              <div class="locale-changer">
                <img class="first-img" src="@/assets/img/iran.png" alt="FA" @click="changeLocale('fa')" />

                <!-- <img src="@/assets/img/united-kingdom.png" alt="ENG" @click="changeLocale('en')" /> -->
                <!-- <select v-model="$i18n.locale">
                  <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale
                  }}<img src="@/assets/img/ir" alt=""/>
                  </option>
                  <option :value="$i18n.availableLocales[0]">
                    <img src="@/assets/img/iran.png" alt="FA" />
                    {{ $i18n.availableLocales[0] }}
                  </option>
                  <option :value="$i18n.availableLocales[1]">
                    <img src="@/assets/img/united-kingdom.png" alt="ENG" />
                    {{ $i18n.availableLocales[1] }}
                  </option>
                </select> -->
              </div>

              <div class="dx-field d-none d-lg-block" v-if="notify_show">
                <div class="dx-field-value">
                  <div class="MyDropDownFrame">
                    <button class="btn my-btns" style="background: #a37c10;color: #fff;" @click="showDate_Warning">
                      {{ $t('notify_bar.title') }}{{ warning_date }}
                    </button>
                    <button class="btn my-btns" style="background: #2429b5;color: #fff;">
                      {{ $t('notify_bar.messages') }} 0
                      <!-- {{ user_reminderes_datasource.length }} -->
                    </button>
                    <!-- <DxDropDownButton :split-button="true" :use-select-mode="false" :items="active_7_days_passed_items"
                      :text="reminder_label" icon="info" class="MyDropDown" :drop-down-options="dropDownOptions"
                      @item-click="onItemClick" /> -->
                  </div>
                </div>
              </div>

            </div>
            <dx-button class="user-button" height="100%" styling-mode="text" width="25vw" style="min-width: 250px">
              <user-panel v-if="user_panel_show" :email="email" :userInformation="userInfo" :menu-items="userMenuItems"
                menu-mode="context" />
            </dx-button>
          </div>
        </template>
      </dx-item>

      <template #menuUserItem>
        <user-panel :email="email" :userInformation="userInfo" :menu-items="userMenuItems"
        :menuItemsGuest="userMenuItemsGuest" menu-mode="list" />
      </template>
    </dx-toolbar>


    <DxPopup style="text-align: :center;" :width="this.showDialogNewWidth" :height="this.showDialogNewHeight"
      :show-title="true" :title="this.$i18n.t('notify_bar.days_7_reminder_title')" alignment="center" :drag-enabled="true"
      :hide-on-outside-click="false" v-model:visible="popup7_PassedVisible">
      <template #content="{ data }">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">

            <div class="card">
              <!-- <h5 class="card-header">فرم اطلاعات کاربر</h5> -->
              <div class="card-body">
                <form>
                  <!-- Height-Width-Controller -->
                  <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                      <div class="d-flex flex-row">
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Height-Width-Controller-->

                  <div class="row">
                    <DxDataGrid :data-source="user_reminderes_datasource" height="300px"
                      :columns="norify_7_passed_columns" ref="main_grid" :show-row-lines="showRowLines"
                      :show-column-lines="showColumnLines" :row-alternation-enabled="true" :rtl-enabled="true"
                      :remote-operations="true" :column-auto-width="true" :allow-column-reordering="true"
                      :allow-column-resizing="true" :hover-state-enabled="true" :noDataText="checkData"
                      :selection="{ mode: 'single' }" @toolbar-preparing="onToolbarPreparing"
                      @selection-changed="onSelectionChanged" @row-click="onThisRowClick" class="mt-3" id="ps_Table">

                      <DxColumnChooser :enabled="true" />
                      <DxExport :enabled="allowExportDataGrid"
                        :allow-export-selected-data="allowExportSelectedDataGrid" />
                      <!-- <DxSelection mode="single" /> -->

                      <DxHeaderFilter :visible="showHeaderFilter" />
                      <DxSearchPanel :visible="true" :width="190" :placeholder="this.$i18n.t('notify_bar.grid_search')"
                        class="" />

                      <!-- <DxSelection :select-all-mode="allMode" :show-check-boxes-mode="checkBoxesMode" mode="multiple" /> -->

                      <template #btnGroup class="row">
                        <div class="d-lg-none">
                          <div class="mt-2">
                            <DxDropDownButton :items="Dropactions" :drop-down-options="{ width: 190 }"
                              :text="this.$i18n.t('notify_bar.drop_down_toolbar')" icon="save"
                              @item-click="onItemClick" />
                          </div>
                        </div>
                        <div class="d-none d-lg-block">
                          <!-- <DxButton class="ml-2 bg-success" color="success" size="sm" icon="plus"
                            hint="ثبت اطلاعات کاربر جدید" @click="NewUserPopup()">
                          </DxButton>
                          <DxButton color="secondary" class="ml-2" size="sm" icon="revert" hint="برگشت به صفحه قبل"
                            @click="$router.go(-1)">
                          </DxButton> -->
                          <DxButton color="light" size="sm" icon="refresh" @click="reloadData"
                            :hint="this.$i18n.t('notify_bar.drop_down_toolbar_new_reload')">
                          </DxButton>
                        </div>
                      </template>


                      <template #btnRGroup class="p-0 m-0">
                        <div class="d-none d-lg-block">
                          <div class="row">
                            <div class="col-sm-4">
                              <button class="btn btn-sm mr-1 bg-warning" size="sm">تنظیمات1</button>
                            </div>
                            <div class="col-sm-4">
                              <button class="btn btn-sm mr-1 bg-dark text-white" size="sm">تنظیمات2</button>
                            </div>
                            <div class="col-sm-4">
                              <button class="btn btn-sm mr-1 bg-success" size="sm">تنظیمات3</button>
                            </div>
                          </div>
                        </div>

                      </template>

                      <template #actions="{ data }">
                        <div>
                          <!-- <DxButton class="m-1" icon="key" type="dark" hint="اطلاعات - تخصیص نقش کاربر"
                            @click="showUserRoleDialog(data)">
                          </DxButton> -->
                        </div>
                      </template>
                      <template #radif="{ data }">
                        {{ data.rowIndex + 1 }}
                      </template>
                      <DxPaging :enabled="false" />
                      <DxFilterRow :visible="true" />
                      <DxLoadPanel :enabled="true" />
                      <DxScrolling mode="standard" />
                    </DxDataGrid>
                  </div>
                  <button class="btn btn-warning mt-3" @click="this.popup7_PassedVisible = false"><i
                      class="fa fa-home"></i> {{ $t('notify_bar.btn_back') }}</button>
                </form>

                <div class="card-footer text-muted mt-2"> {{ $t('notify_bar.dialog_new_footer_label') }}</div>
              </div>
            </div>




          </div>
        </DxScrollView>
      </template>
    </DxPopup>

  </header>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import auth from "../auth";
import { useRouter, useRoute } from 'vue-router';
import { ref } from 'vue';
import DxDropDownButton from 'devextreme-vue/drop-down-button';
import { useStore } from "vuex";
import { computed } from "vue";

import UserPanel from "./user-panel";
import notify from "devextreme/ui/notify";

import responsives from '@/utils/responsives';
import { dxGrid } from '@/components/dxGrid'
import { DxPopup } from 'devextreme-vue/popup';
import { DxDataGrid, DxSelection } from 'devextreme-vue/data-grid';
import { DxScrollView } from 'devextreme-vue/scroll-view';


export default {
  props: {
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
    logOutFunc: Function
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const email = ref("");
    // auth.getUser().then((e) => email.value = e.data.email);

    const userInfo = ref("");
    // auth.getUser().then((e) => userInfo.value = e.data.userInfo);
    // userInfo.value = localStorage.getItem('userInfo');

    const userMenuItemsGuest = [{
      text: "ورود",
      icon: "user",
      onClick: onLogoutClick
    }];

    const userMenuItems = [{
      text: "اطلاعات حساب",
      icon: "user",
      onClick: onProfileClick
    },
    {
      text: "خروج",
      icon: "runner",
      onClick: onLogoutClick
    }];

    function onLogoutClick() {
      auth.logOut();
      router.push({
        path: "/login-form",
        query: { redirect: route.path }
      });
    }

    function onProfileClick() {
      router.push({
        path: "/profile",
        query: { redirect: route.path }
      });
    }

    return {
      store,
      email,
      userInfo,
      userMenuItems,
      userMenuItemsGuest,
      user_reminderes_datasource: computed(() => store.getters['user_reminders/getUserReminders']),
      // warning_message: computed(() => store.getters['user_reminders/getUserReminders']),      
      warning_date: computed(() => store.getters['user_reminders/getUserRemindersCount']),
    };
  },
  mixins: [dxGrid, responsives],
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    UserPanel,
    DxDropDownButton,
    DxScrollView,
    DxDataGrid, DxSelection,
    DxPopup
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      this.selected_lang = newVal;
      this.reloadData();

      debugger
      if (this.$refs.main_grid != null) {
        const dataGrid = this.$refs.main_grid.instance;
        this.$refs.main_grid.columns[0].caption = this.$i18n.t('notify_bar.grid_row_no');
        this.$refs.main_grid.columns[1].caption = this.$i18n.t('notify_bar.grid_row_title');
        this.$refs.main_grid.columns[2].caption = this.$i18n.t('notify_bar.grid_row_innerNo');
        this.$refs.main_grid.columns[3].caption = this.$i18n.t('notify_bar.grid_row_outerNo');
        this.$refs.main_grid.columns[4].caption = this.$i18n.t('notify_bar.grid_row_dateRequired');
        this.$refs.main_grid.columns[5].caption = this.$i18n.t('notify_bar.grid_row_company');
        this.$refs.main_grid.columns[6].caption = this.$i18n.t('notify_bar.grid_row_fileEdmsTitle');
        this.$refs.main_grid.columns[7].caption = this.$i18n.t('notify_bar.grid_row_poi');
        this.$refs.main_grid.columns[8].caption = this.$i18n.t('notify_bar.grid_row_dateSaved');
        this.$refs.main_grid.columns[9].caption = this.$i18n.t('notify_bar.grid_row_versionStatus');
        this.$refs.main_grid.columns[10].caption = this.$i18n.t('notify_bar.grid_row_edmsReminderId');



        dataGrid.option('columns', dataGrid.option('columns'));
      }

      // this.notify_show=false;
      // this.notify_show=true;
    },
    user_reminderes_datasource(newVal, oldVal) {
      // debugger
      this.notify_show = false;
      setTimeout(() => {
        this.notify_show = true;
      }, 500)


      // this.active_7_days_passed_items[0].text = this.$i18n.t('notify_bar.date_expiring') +
      //   this.active_7_days_passed_items[0].count;
      // this.active_7_days_passed_items[1].text = this.$i18n.t('notify_bar.new_edms') +
      //   this.active_7_days_passed_items[1].count;
      // this.active_7_days_passed_items[2].text = this.$i18n.t('notify_bar.messages') +
      //   this.active_7_days_passed_items[2].count;




      // this.userMenuItems[0].text = this.$i18n.t('toolbar_drop_down.account_user');
      // this.userMenuItems[1].text = this.$i18n.t('toolbar_drop_down.logout');

      this.user_panel_show = false;
      setTimeout(() => {
        this.user_panel_show = true;
      }, 100);

      // switch (this.selected_lang) {
      //   case 'en':
      //     this.active_7_days_passed_items[0].text = this.$i18n.t('notify_bar.date_expiring') +
      //       this.active_7_days_passed_items[0].count;
      //     this.active_7_days_passed_items[1].text = this.$i18n.t('notify_bar.new_edms') +
      //       this.active_7_days_passed_items[1].count;
      //     this.active_7_days_passed_items[2].text = this.$i18n.t('notify_bar.messages') +
      //       this.active_7_days_passed_items[2].count;

      //     this.userMenuItems[0].text = this.$i18n.t('toolbar_drop_down.account_user');
      //     this.userMenuItems[1].text = this.$i18n.t('toolbar_drop_down.logout');

      //     this.user_panel_show = false;
      //     this.user_panel_show = true;
      //   case 'fa':

      //     break;
      //   default:
      //     break;
      // }
    },
  },
  data() {
    return {


      user_panel_show: true,
      selected_lang: '',
      notify_show: true,
      norify_7_passed_columns: [
        { cellTemplate: "radif", caption: this.$i18n.t('notify_bar.grid_row_no'), dataType: 'number', allowFiltering: false, width: 70, alignment: "center" },
        { dataField: 'id', caption: this.$i18n.t('notify_bar.grid_row_id'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'contractId', caption: this.$i18n.t('notify_bar.grid_row_contractId'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'contractNumber', caption: this.$i18n.t('notify_bar.grid_row_contractNumber'), dataType: 'string', alignment: 'center' },
        { dataField: 'contractDescription', caption: this.$i18n.t('notify_bar.grid_row_contractDescription'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'contractDate', caption: this.$i18n.t('notify_bar.grid_row_contractDate'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'reminderCategory', caption: this.$i18n.t('notify_bar.grid_row_reminderCategory'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'reminderTitle', caption: this.$i18n.t('notify_bar.grid_row_reminderTitle'), dataType: 'string', alignment: 'center' },
        { dataField: 'reminderBody', caption: this.$i18n.t('notify_bar.grid_row_reminderBody'), dataType: 'string', alignment: 'center' },
        { dataField: 'reminderLink', caption: this.$i18n.t('notify_bar.grid_row_reminderLink'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'reminderStatus', caption: this.$i18n.t('notify_bar.grid_row_reminderStatus'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'reminderUser', caption: this.$i18n.t('notify_bar.grid_row_reminderUser'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'reminderDate', caption: this.$i18n.t('notify_bar.grid_row_reminderDate'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'reminderId', caption: this.$i18n.t('notify_bar.grid_row_reminderId'), dataType: 'string', alignment: 'center', visible: false },
        { dataField: 'dateSeen', caption: this.$i18n.t('notify_bar.grid_row_dateSeen'), dataType: 'string', alignment: 'center', visible: true },

      ],
      popup7_PassedVisible: false,
      reminder_label: this.$i18n.t('notify_bar.title'),
      dropDownOptions: {
        width: 250,
        text_1: this.$i18n.t('notify_bar.date_expiring'),//"فایل های تاریخ نیاز : ",
        text_2: this.$i18n.t('notify_bar.new_edms'),//"فایل های بررسی نشده : ",
        text_3: this.$i18n.t('notify_bar.messages'),//"پیغام ها : ",
      },
      actions: [
        { id: 1, text: this.$i18n.t('notify_bar.date_expiring'), icon: "event" },
        { id: 1, text: this.$i18n.t('notify_bar.new_edms'), icon: "upload" },
        { id: 2, text: this.$i18n.t('notify_bar.messages'), icon: "email" },

      ],
    }
  },
  created() {
    this.reloadData();
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
    onThisRowClick(data) {
      debugger
      let dataHolder = {
        Id: null,
        ReminderId: data.data.id,
        UserSeen: localStorage.getItem('userId'),
        DateSeen: null,
      }
      this.store.dispatch('user_reminders/updateUserReminders', dataHolder);
      setTimeout(() => {
        this.reloadData();
      }, 2000);
    },
    reloadData() {
      console.log('Header - Toolbar Runned');
      // let dataHolder= {
      //   user_id:localStorage.getItem('userId'),
      //   user_role:localStorage.getItem('roles'),
      // }
      // this.store.dispatch('user_reminders/getUserReminders',dataHolder );

    },
    // onButtonClick(e) {
    //   debugger
    //   notify(`Go to ${e.component.option('text')}'s profile`, 'success', 600);
    // },

    onItemClick(e) {
      debugger
      switch (e.itemData.id) {
        case 1:
          this.makeInitSize(1000, 600);
          this.popup7_PassedVisible = true;
          break;

        default:
          break;
      }
      notify(e.itemData.text, 'success', 600);
    },
    showDate_Warning() {
      this.makeInitSize(1000, 650);
      this.popup7_PassedVisible = true;
    }
  },
};
</script>

<style lang="scss">
.dx-swatch-additional .dx-scrollable-native.dx-rtl.dx-scrollable .dx-scrollable-content {
  min-width: 240px !important;
}
</style>
<style lang="scss" scoped>
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.logo-frame {
  display: flex;
  align-items: center;

  .logo-img {
    width: 3vw;
    min-width: 50px;
    max-width: 100px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}


.dx-scrollable-native.dx-rtl .dx-scrollable .dx-scrollable-content,
.dx-scrollable-native.dx-rtl.dx-scrollable .dx-scrollable-content {
  float: right;
  width: 100%;
}




.locale-changer {
  display: flex !important;
  padding-left: 10px !important;
  padding-right: 10px !important;


  .first-img {
    margin-left: 10px;
  }

  img {
    cursor: pointer;
    transition: transform .3s ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.Maincontainer {
  width: 100%;
}




.NotifiBar {
  display: flex;
  flex-wrap: wrap;
  // width: 30vw;
  position: absolute;
  z-index: 1;
  right: -90%;
  top: 20%;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}




.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .header-toolbar-my {
    .dx-toolbar {
      height: 100%;
    }
  }

  .dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content .dx-icon {
    color: $base-accent;
    height: 100%;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button>.dx-button-content {
    padding: 3px;
    margin-top: 8px;
    width: 40vw;
  }
}

.MyDropDownFrame {
  display: flex;
  min-width: 20vw;

  .my-btns {
    min-width: 140px;
    height: 40px;
    ;
  }

  button {
    cursor: pointer;
    transition: transform .3s ease-out;

    &:hover {
      transform: translateY(-10px) !important;
    }
  }


  .MyDropDown {
    width: 190%;
    cursor: pointer;
    transition: transform .3s ease-out;

    &:hover {
      box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
      transform: translateY(0px) !important;
    }


  }
}
</style>
