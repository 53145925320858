import axios from 'axios'
import notify from 'devextreme/ui/notify';
import errorHandler from '@/components/errorHandler';

export default {

    // get Last User Infos
    // ***********************************
    getUserReminders({ commit }, data) {
        // debugger
        axios
            .get('Reminders/GetUserReminders?user_id='+data.user_id+'&user_role='+data.user_role)
            .then((response) => {
                // debugger                                                
                commit('setUserReminders', response.data);
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
            
    },
    updateUserReminders({ commit }, data) {
        debugger
        axios
            .post('Reminders/UpdateReminder',data)
            .then((response) => {
                // debugger                                                
                commit('setUserReminders', response.data);
            })
            .catch(error => {
                errorHandler.methods.handleError(error);
            })
            
    },

    

}