import axios from "axios";
import { Buffer } from 'buffer';
import { useStore } from "vuex";
import store from "./store";
import errorHandler from "./components/errorHandler";

const defaultUser = {
  email: localStorage.getItem("userName"),
  avatarUrl:'TT' //'https://js.devexpress.com/Demos/WidgetsGallery/JSemos/images/employees/06.png'
};

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function parseJwt_(token) {  
  debugger
  try {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/').replace('\\x','').replace(/\r?\n|\r/g, "").replace(/\s+/g, ' ').replace(/[\n\r\t\s]+/g, '');
    var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
  } catch (error) {
    debugger
    localStorage.clear();
    //this._user = null;
    //store.commit('setUserInfo',null);
    errorHandler.methods.handleError(error);

  } 
 
}

export default {
 setup(){
  const store = useStore();
    return{
      store
    }
 },
  _user: defaultUser,
  loggedIn() {    
    let token_holder = localStorage.getItem('token');
    if(token_holder == null)
      return false;
      
    const jwtPayload = parseJwt(token_holder);
    
    if (jwtPayload.exp < Date.now()/1000) {
        // token expired
        return false;
        // deleteTokenFromLocalStorage();
        // next("/");
    }
    this._user ={
      email: localStorage.getItem("userName"),
      userInfo : localStorage.getItem("userInfo"),
      avatarUrl:'TT' //'https://js.devexpress.com/Demos/WidgetsGallery/JSemos/images/employees/06.png'
    } 

    return !!this._user;
  },

  async logIn(email, password) {
    try {
      // Send request
      console.log(email, password);
      const user_info = {Username:email,Password:password}
      

      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false,
        message: "Authentication failed"
      };
    }
  },

  async logOut() {
    
    localStorage.clear();
    store.commit('setUserInfo',null);
    this._user = null;
  },

  async getUser() {
    try {
      // Send request

      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }
};
