import axios from 'axios'
import errorHandler from '@/components/errorHandler.js'
import notify from 'devextreme/ui/notify';


export default {
    //callTitle({dispatch,commit}){
    callTitle({commit}){
        commit('setTitle','this is new  data for title !');
    },
    getUsersInPartId({commit},data)
    {
        debugger           
        axios
        .get('Users/GetUsersByPartId?part_id='+data)
        .then((response)=>{                                    
            debugger
            commit('setUsers_in_part',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    getUsersInPartByDesc({commit},data)
    {
        debugger           
        axios
        .get('UserRoles/GetUsersByPartDesc?part_desc='+data)
        .then((response)=>{                                    
            debugger
            commit('setUsers_in_part',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    getUserRoleWithMenusAccess({commit},data){     
        debugger           
        axios
        .get('UserRoles/GetUserRoleWithMenusAccess/'+data)
        .then((response)=>{                                    
            debugger
            commit('setUser_menu_roles',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    getUsersWithCompanyParts({commit},data){                
        axios
        .get('users/GetUsersWithCompanyParts')
        .then((response)=>{  
            debugger                                  
            commit('setUsers',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    getUsersBaseInfos({commit},data){  
        debugger      
        let uid_holder=data.uid;
        let username_holder=data.username;
        if(uid_holder === '')
            uid_holder=null;
        if(username_holder === '')
        username_holder=null;
        axios
        .get('users/GetUsersBaseInfos?uid='+uid_holder+'&username='+username_holder)
        .then((response)=>{            
            debugger
            commit('setUsers',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    getUsers({commit},data){        
        axios
        .get('users/getusers')
        .then((response)=>{            
            commit('setUsers',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    addNewUser({commit},data){
        axios
        .post('Authenticate/Register',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    editUserPass({commit},data){
        debugger
        axios
        .post('Authenticate/UpdateUser',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    editUser({commit},data){
        debugger
        axios
        .post('Users/UpdateUser',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    deleteUser({commit},data){ 
        axios
        .post('users/DeleteUser?id='+data)
        .then((response)=>{            
            
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            } 
        })
        .catch(error => {            
            errorHandler.methods.handleError(error);
        })
       
    },

}