import axios from 'axios'
import notify from 'devextreme/ui/notify';
import errorHandler from '@/components/errorHandler';

export default {

    getMenuAccssedByUserId({commit},data){ 
        debugger                       
        axios
        .get('Menus/GetMenuAccssedByUserId?uid='+data)
        .then((response)=>{           
            debugger                                                 
            commit('setRootMenus',response.data);
        })
        .catch(error => {
            debugger
            errorHandler.methods.handleError(error);
        })
    }, 
    getRootMenus({commit},data){                        
        axios
        .get('Menus/GetRootMenus')
        .then((response)=>{     
            // debugger                                                       
            commit('setRootMenus',response.data);
        })
        .catch(error => {
            debugger
            errorHandler.methods.handleError(error);
        })
    }, 
    getTotalMenus({commit},data){                        
        axios
        .get('Menus/GetAllMenuSubMenus')
        .then((response)=>{                                                            
            commit('setTotalMenus',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },   
    addNewSubMenu({commit},data){
        debugger
        axios
        .post('Menus/AddNewSubMenu',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    addNewRoleMenuPolicy({commit},data){
        debugger
        axios
        .post('Menus/AddNewRoleMenuPolicy',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    // editSubMenu({commit},data){
    //     debugger
    //     axios
    //     .post('Menus/UpdatePart',data)
    //     .then((response)=>{  
    //         debugger
    //         if(response.status === 200)
    //         {
    //             notify(response.data.message, "success", this.notifyTimeSet);
    //         }   
    //         else 
    //         {
    //             notify(response.data.message, "error", this.notifyTimeSet);
    //         }                   
    //     })
    //     .catch(error => {
    //         errorHandler.methods.handleError(error);
    //     })
    // },
    deleteRoleMenuPolicy({commit},data){ 
        debugger
        axios
        .post('Menus/DeleteRoleMenuPolicy/'+data)
        .then((response)=>{            
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            } 
        })
        .catch(error => {
            debugger
            errorHandler.methods.handleError(error);
        })
    },
    deleteSubMenu({commit},data){ 
        debugger
        axios
        .post('Menus/DeleteSubMenu?id='+data)
        .then((response)=>{            
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            } 
        })
        .catch(error => {
            debugger
            errorHandler.methods.handleError(error);
        })
    },

}