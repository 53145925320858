<template>
  <div>
    <h2 class="content-block"> {{ $t('users.title') }}</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <DxDataGrid :data-source="users_info" :columns="users_colomuns" :ref="dataGridRefUsers"
          :show-row-lines="showRowLines" :show-column-lines="showColumnLines" :row-alternation-enabled="true"
          :rtl-enabled="true" :remote-operations="true" :column-auto-width="true" :allow-column-reordering="true"
          :allow-column-resizing="true" :hover-state-enabled="true" :noDataText="checkData"
          :selection="{ mode: 'single' }" @toolbar-preparing="onToolbarPreparing"
          @selection-changed="onSelectionChanged" class="mt-3" id="ps_Table" key-expr="id">

          <DxColumnChooser :enabled="true" />
          <DxExport :enabled="allowExportDataGrid" :allow-export-selected-data="allowExportSelectedDataGrid" />
          <DxSelection mode="single" />

          <DxHeaderFilter :visible="showHeaderFilter" />
          <DxSearchPanel :visible="true" :width="190" :placeholder="this.$i18n.t('users.grid_search')" class="" />

          <DxSelection :select-all-mode="allMode" :show-check-boxes-mode="checkBoxesMode" mode="multiple" />

          <template #btnGroup class="row">
            <div class="d-lg-none">
              <div class="mt-2">
                <DxDropDownButton :items="Dropactions" :drop-down-options="{ width: 190 }"
                  :text="this.$i18n.t('users.drop_down_toolbar')" icon="save" @item-click="onItemClick" />
              </div>
            </div>
            <div class="d-none d-lg-block">
              <DxButton class="ml-2 bg-success" color="success" size="sm" icon="plus"
                :hint="this.$i18n.t('users.drop_down_toolbar_new_item')" @click="NewUserPopup()">
              </DxButton>
              <DxButton color="secondary" class="ml-2" size="sm" icon="revert"
                :hint="this.$i18n.t('users.drop_down_toolbar_new_reload')" @click="$router.go(-1)">
              </DxButton>
              <DxButton color="light" size="sm" icon="refresh" @click="reloadData"
                :hint="this.$i18n.t('users.drop_down_toolbar_new_back')">
              </DxButton>
            </div>
          </template>


          <template #btnRGroup class="p-0 m-0">
            <div class="d-none d-lg-block">
              <div class="row">
                <div class="col-sm-4">
                  <button class="btn btn-sm mr-1 bg-warning" size="sm">{{ $t('users.drop_down_toolbar') }}</button>
                </div>
                <div class="col-sm-4">
                  <button class="btn btn-sm mr-1 bg-dark text-white" size="sm">{{
                    $t('users.drop_down_toolbar')
                  }}</button>
                </div>
                <div class="col-sm-4">
                  <button class="btn btn-sm mr-1 bg-success" size="sm">{{ $t('users.drop_down_toolbar') }}</button>
                </div>
              </div>
            </div>

          </template>

          <template #actions="{ data }">
            <div>
              <DxButton class="m-1" icon="key" type="dark" :hint="this.$i18n.t('users.grid_btn_role_to_user')"
                @click="showUserRoleDialog(data)">
              </DxButton>
              <DxButton class="m-1" icon="fieldchooser" type="default"
                :hint="this.$i18n.t('users.grid_btn_user_menu_access')" @click="accessUserMenuRoles(data)">
              </DxButton>
              <DxButton class="m-1" icon="group" :hint="this.$i18n.t('users.grid_btn_set_user_part')" type="success"
                @click="accessUserM(data)">
              </DxButton>
              <DxButton class="bg-warning m-1" icon="edit" type="edit"
                :hint="this.$i18n.t('users.grid_btn_edit_user_info')" @click="editUserM(data)">
              </DxButton>
              <DxButton class="bg-danger m-1" icon="remove" :hint="this.$i18n.t('users.grid_btn_delet_user_info')"
                @click="showDeleteDialog">
              </DxButton>
            </div>
          </template>
          <template #radif="{ data }">
            {{ data.rowIndex + 1 }}
          </template>
          <DxPaging :enabled="false" />
          <DxFilterRow :visible="true" />
          <DxLoadPanel :enabled="true" />
          <DxScrolling mode="standard" :use-native="true"/>
        </DxDataGrid>

      </div>
    </div>



    <DxPopup style="text-align: :center;" :width="this.showDialogNewWidth" :height="this.showDialogNewHeight"
      :show-title="true" :title="this.$i18n.t('users.dialog_user_info')" alignment="center" :drag-enabled="true"
      :hide-on-outside-click="false" v-model:visible="popupNewUserVisible">
      <template #content="{ data }">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">

            <div class="card">
              <!-- <h5 class="card-header">فرم اطلاعات کاربر</h5> -->
              <div class="card-body">


                <form>

                  <!-- Height-Width-Controller -->
                  <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                      <div class="d-flex flex-row">
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Height-Width-Controller-->

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="employeeInfo.Name" @input="this.check_content($event, 'inputName')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputName'), 'border-success-my': this.isStateOk('inputName'), 'rounded': true }"
                          :placeholder="this.$i18n.t('users.dialog_user_name')" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-1" />
                          </span>
                        </div>
                        <input v-model="employeeInfo.Family" @input="this.check_content($event, 'inputFamily')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputFamily'), 'border-success-my': this.isStateOk('inputFamily'), 'rounded': true }"
                          :placeholder="this.$i18n.t('users.dialog_user_family')" type="text">
                      </div>
                    </div>
                  </div>


                  <div class="row mt-2">
                    <div class="col-sm-6">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user-shield" class="m-1" />
                          </span>
                        </div>
                        <input v-model="employeeInfo.Username" @input="this.check_content($event, 'inputUsername')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputUsername'), 'border-success-my': this.isStateOk('inputUsername'), 'rounded': true }"
                          :placeholder="this.$i18n.t('users.dialog_user_username')" type="text">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-at" class="m-1" />
                          </span>
                        </div>
                        <input v-model="employeeInfo.Email" @input="this.check_content($event, 'inputEmail')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputEmail'), 'border-success-my': this.isStateOk('inputEmail'), 'rounded': true }"
                          :placeholder="this.$i18n.t('users.dialog_user_email')" type="email">
                      </div>
                    </div>
                  </div>


                  <div class="row mt-2">
                    <div class="col-sm-6">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-lock" class="m-1" />
                          </span>
                        </div>
                        <input v-model="employeeInfo.Password" @input="this.check_content($event, 'inputPwd')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputPwd'), 'border-success-my': this.isStateOk('inputPwd'), 'rounded': true }"
                          :placeholder="this.$i18n.t('users.dialog_user_pwd')" type="password">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group input-group has-validation">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-check-double" class="m-1" />
                          </span>
                        </div>
                        <input v-model="employeeInfo.PasswordConfirm"
                          @input="this.check_content($event, 'inputPwdConfirm')"
                          :class="{ 'border-danger-my': !this.isStateOk('inputPwdConfirm'), 'border-success-my': this.isStateOk('inputPwdConfirm'), 'rounded': true }"
                          :placeholder="this.$i18n.t('users.dialog_user_pwd_confirm')" type="password">
                      </div>
                      <div class="mt-2" v-show="editMode">
                        {{ $t('users.dialog_user_change_pwd') }}
                        <DxSwitch class="" v-model:value="changePwd" />
                      </div>
                    </div>
                  </div>
                  <button v-if="editMode" class="btn btn-success mt-3 me-1 ms-2" @click="editUserInfo($event)"><i
                      class="fa fa-home"></i>{{ $t('users.dialog_user_btn_edit') }}</button>
                  <button v-else class="btn btn-success mt-3 me-1 ms-2" @click="saveNewUser($event)"><i
                      class="fa fa-home"></i>{{ $t('users.dialog_user_btn_save') }}</button>
                  <button class="btn btn-warning mt-3" @click="this.popupNewUserVisible = false"><i
                      class="fa fa-home"></i>{{ $t('users.dialog_user_btn_back') }}</button>
                </form>

                <div class="card-footer text-muted mt-2">{{ $t('users.dialog_user_footer_label') }}</div>
              </div>
            </div>




          </div>
        </DxScrollView>
      </template>
    </DxPopup>


    <DxPopup style="text-align: :center;" :width="this.showDialogNewWidth" :height="this.showDialogNewHeight"
      :show-title="true" :title="titleUserMenu" alignment="center" :drag-enabled="true" :hide-on-outside-click="false"
      v-model:visible="showDialogUserMenuRole">
      <template #content="{ data }">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">

            <div class="card">
              <div class="card-body">

                <form>

                  <!-- Height-Width-Controller -->
                  <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                      <div class="d-flex flex-row">
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Height-Width-Controller -->

                  <div class="row">
                    <div class="col-sm-12">
                      <DxDataGrid :data-source="users_menu_roles_datasource" :columns="users_menu_roles_colomuns"
                        ref="dataGrid_roles" :show-row-lines="showRowLines" :show-column-lines="showColumnLines"
                        :row-alternation-enabled="true" :rtl-enabled="true" :remote-operations="true"
                        :column-auto-width="true" :allow-column-reordering="true" :allow-column-resizing="true"
                        :hover-state-enabled="true" :noDataText="checkData" :selection="{ mode: 'single' }" class="mt-3"
                        id="ps_Table" key-expr="userID" height="350px">

                        <DxColumnChooser :enabled="true" />
                        <DxExport :enabled="allowExportDataGrid"
                          :allow-export-selected-data="allowExportSelectedDataGrid" />


                        <DxHeaderFilter :visible="showHeaderFilter" />
                        <DxSearchPanel :visible="true" :width="190"
                          :placeholder="this.$i18n.t('users.dialog_grid_user_menu_role_search')" class="" />

                        <!-- <DxSelection
                          :select-all-mode="allMode"
                          :show-check-boxes-mode="checkBoxesMode"
                          mode="multiple"
                        /> -->



                        <template #radif_menu="{ data }">
                          {{ data.rowIndex + 1 }}
                        </template>
                        <DxPaging :enabled="false" />
                        <DxFilterRow :visible="true" />
                        <DxLoadPanel :enabled="true" />
                        <DxScrolling mode="standard" :use-native="true"/>
                      </DxDataGrid>
                    </div>
                  </div>

                  <button class="btn btn-success mt-3 me-1 ms-2" @click="saveNewPartForUser"><i class="fa fa-home"></i>
                    {{ $t('users.dialog_grid_user_menu_role_btn_save') }}</button>
                  <button class="btn btn-warning mt-3" @click="this.showDialogUserMenuRole = false"><i
                      class="fa fa-home"></i> {{ $t('users.dialog_grid_user_menu_role_btn_back') }}</button>
                </form>

                <div class="card-footer text-muted mt-2">{{ $t('users.dialog_grid_user_menu_role_footer_label') }}</div>
              </div>
            </div>

          </div>
        </DxScrollView>
      </template>
    </DxPopup>


    <DxPopup style="text-align: :center;" :width="this.showDialogNewWidth" :height="this.showDialogNewHeight"
      :show-title="true" :title="titleUserRole" alignment="center" :drag-enabled="true" :hide-on-outside-click="false"
      v-model:visible="showDialogRoleUser">
      <template #content="{ data }">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">

            <div class="card">
              <div class="card-body">

                <form>

                  <!-- Height-Width-Controller -->
                  <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                      <div class="d-flex flex-row">
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Height-Width-Controller -->

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-3" />
                          </span>
                        </div>
                        <DxDropDownBox v-model:value="gridBoxValueRole" v-model:opened="isDropDownBoxOpened"
                          :defer-rendering="false" :display-expr="gridBoxDisplayExprRole" :show-clear-button="true"
                          :data-source="all_roles_datasource" value-expr="id"
                          :placeholder="this.$i18n.t('users.dialog_role_user_part_select')" width="80%">
                          <template #content="{ data }">
                            <DxDataGrid :data-source="all_roles_datasource" :columns="RolesListColumns"
                              :hover-state-enabled="true" v-model:selected-row-keys="gridBoxValueRole"
                              @selection-changed="changeDropDownBoxValueRole($event)" height="100%"
                              ref="grid_all_roles">
                              <DxSelection mode="single" />

                              <DxPaging :enabled="false" />
                              <DxFilterRow :visible="true" />
                              <DxScrolling mode="standard" :use-native="true"/>
                              <template #radif_1="{ data }">
                                {{ data.rowIndex + 1 }}
                              </template>
                            </DxDataGrid>
                          </template>
                        </DxDropDownBox>
                      </div>
                    </div>


                    <DxDataGrid :data-source="userRolePermissionsDatasource" :columns="userRolePermissionsColumns"
                      :ref="dataGridRefUsersRoles" :show-row-lines="showRowLines" :show-column-lines="showColumnLines"
                      :row-alternation-enabled="true" :rtl-enabled="true" :remote-operations="true"
                      :column-auto-width="true" :allow-column-reordering="true" :allow-column-resizing="true"
                      :hover-state-enabled="true" :noDataText="checkData" height="400px"
                      @selection-changed="onSelectionChangedUserRoles" :selection="{ mode: 'single' }" class="mt-3"
                      id="ps_Table" key-expr="userID">

                      <DxColumnChooser :enabled="true" />
                      <DxExport :enabled="allowExportDataGrid"
                        :allow-export-selected-data="allowExportSelectedDataGrid" />


                      <DxHeaderFilter :visible="showHeaderFilter" />
                      <DxSearchPanel :visible="true" :width="190"
                        :placeholder="this.$i18n.t('users.dialog_role_user_grid_search')" class="" />
                      <template #actionsUserRole="{ data }">
                        <div>
                          <DxButton class="bg-danger m-1" icon="remove"
                            :hint="this.$i18n.t('users.dialog_role_user_grid_delete_role')"
                            @click="showDeleteDialogUserRole">
                          </DxButton>
                        </div>
                      </template>
                      <template #radif_menu="{ data }">
                        {{ data.rowIndex + 1 }}
                      </template>
                      <DxPaging :enabled="false" />
                      <DxFilterRow :visible="true" />
                      <DxLoadPanel :enabled="true" />
                      <DxScrolling mode="standard" :use-native="true"/>
                    </DxDataGrid>

                  </div>

                  <button class="btn btn-success mt-3 me-1 ms-2" @click="saveNewRoleToUser"><i class="fa fa-home"></i>
                    {{ $t('users.dialog_role_user_grid_btn_save') }}</button>
                  <button class="btn btn-warning mt-3" @click="closeUserRoleDialog"><i class="fa fa-home"></i> {{
                    $t('users.dialog_role_user_grid_btn_back')
                  }}</button>
                </form>

                <div class="card-footer text-muted mt-2">{{ $t('users.dialog_role_user_grid_footer_label') }}</div>
              </div>
            </div>

          </div>
        </DxScrollView>
      </template>
    </DxPopup>


    <DxPopup style="text-align: :center;" :width="this.showDialogNewWidth" :height="this.showDialogNewHeight"
      :show-title="true" :title="titlePartForUser" alignment="center" :drag-enabled="true"
      :hide-on-outside-click="false" v-model:visible="showDialogPartUser">
      <template #content="{ data }">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">

            <div class="card">
              <div class="card-body">

                <form>

                  <!-- Height-Width-Controller -->
                  <div class="container mb-1 me-0 pe-0 ">
                    <div class="col-sm-6">
                      <div class="d-flex flex-row">
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeSmallScreen" src="@/assets/img/smartphone.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeMediumScreen" src="@/assets/img/tablet.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                              <img @click="this.makeLargeScreen" src="@/assets/img/desktop.png"
                                class="responseModels m-1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Height-Width-Controller -->

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="fas fa-user" class="m-3" />
                          </span>
                        </div>
                        <DxDropDownBox v-model:value="gridBoxValue" v-model:opened="isDropDownBoxOpened"
                          :defer-rendering="false" :display-expr="gridBoxDisplayExpr" :show-clear-button="true"
                          :data-source="partsListDataSource" value-expr="id"
                          :placeholder="this.$i18n.t('users.dialog_part_user_part_select')" width="80%">
                          <template #content="{ data }">
                            <DxDataGrid :data-source="partsListDataSource" :columns="partsListColumns"
                              :hover-state-enabled="true" v-model:selected-row-keys="gridBoxValue"
                              @selection-changed="changeDropDownBoxValue($event)" height="100%" ref="grid_parts">
                              <DxSelection mode="single" />

                              <DxPaging :enabled="false" />
                              <DxFilterRow :visible="true" />
                              <DxScrolling mode="standard" :use-native="true"/>
                              <template #radif_1="{ data }">
                                {{ data.rowIndex + 1 }}
                              </template>
                            </DxDataGrid>
                          </template>
                        </DxDropDownBox>
                      </div>
                    </div>
                  </div>

                  <button class="btn btn-success mt-3 me-1 ms-2" @click="saveNewPartForUser"><i
                      class="fa fa-home"></i>{{ $t('users.dialog_part_user_grid_btn_save') }}</button>
                  <button class="btn btn-warning mt-3" @click="closePartUserDialog"><i class="fa fa-home"></i>{{
                    $t('users.dialog_part_user_grid_btn_back')
                  }}</button>
                </form>

                <div class="card-footer text-muted mt-2">{{ $t('users.dialog_part_user_grid_footer_label') }}</div>
              </div>
            </div>

          </div>
        </DxScrollView>
      </template>
    </DxPopup>



    <customDial :show="showUserDeleteDialog" :cancel="() => { this.showUserDeleteDialog = false; }" :confirm="delUserM"
      :title="this.$i18n.t('users.delete_user_account_dialog_title')" :userPersonalInfo="fullUserNameSelected"
      :description="this.$i18n.t('users.delete_user_account_dialog_body')" />



    <customDial :show="DeleteDialogUserRole" :cancel="closeDeleteDialogUserRole" :confirm="delUserRole"
      :title="this.$i18n.t('users.delete_role_dialog_title')" :userPersonalInfo="dialogDeleteUserRoleTitle"
      :description="this.$i18n.t('users.delete_role_dialog_body')" />

  </div>




</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxForm, DxItem } from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import { DxDataGrid, DxSelection } from 'devextreme-vue/data-grid';
import { computed } from '@vue/reactivity';
import { dxGrid } from '@/components/dxGrid'
import DxButton from 'devextreme-vue/button';
import { onMounted, reactive, ref } from 'vue';
import { mapGetters } from 'vuex';
import { useStore } from "vuex";
import MyValidator from '@/components/MyValidator'
import notify from 'devextreme/ui/notify';
import customDial from '@/components/customDialog.vue'
import themes from 'devextreme/ui/themes';
import DxDropDownButton from 'devextreme-vue/drop-down-button';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { DxDropDownBox } from 'devextreme-vue/drop-down-box';
import responsives from '@/utils/responsives';
import { DxSwitch } from 'devextreme-vue/switch';

export default {
  setup() {
    const store = useStore();

    // const users_colomuns = [
    //   { cellTemplate: "radif", caption: this.$i18n.t('users.grid_row_user_no'), dataType: 'number', allowFiltering: false },
    //   { dataField: 'id', caption: this.$i18n.t('users.grid_row_user_id'), dataType: 'number', visible: false },
    //   { dataField: 'firstName', caption: this.$i18n.t('users.grid_row_user_firstName'), dataType: 'string' },
    //   { dataField: 'lastName', caption: this.$i18n.t('users.grid_row_user_lastName'), dataType: 'string' },
    //   { dataField: 'userName', caption: this.$i18n.t('users.grid_row_user_userName'), dataType: 'string' },
    //   { dataField: 'email', caption: this.$i18n.t('users.grid_row_user_email'), dataType: 'string' },
    //   { dataField: 'title', caption: this.$i18n.t('users.grid_row_user_title'), dataType: 'string' },
    //   { dataField: 'partTitle', caption: this.$i18n.t('users.grid_row_user_partTitle'), dataType: 'string' },
    //   { dataField: 'passwordHash', caption: this.$i18n.t('users.grid_row_user_passwordHash'), dataType: 'string', visible: false },
    //   { cellTemplate: 'actions', caption: this.$i18n.t('users.grid_row_user_actions'), allowFiltering: false, allowSorting: false }
    // ];
    let row_User_Selected = reactive(null);
    //---------------- New User
    let employeeInfo = reactive({
      Name: '',
      Family: '',
      Username: '',
      Email: '',
      Password: '',
      PasswordConfirm: ''
    });
    //----------------New User
    const CloseUserPopup = () => {
      popupNewUserVisible.value = false;
    }



    return {
      store,
      // users_colomuns,
      users_info: computed(() => store.getters['user/getUsers']),
      company_info: computed(() => store.getters['company/getCompany']),
      partsListDataSource: computed(() => store.getters['parts/getParts']),
      users_menu_roles_datasource: computed(() => store.getters['user/getUser_menu_roles']),
      userRolePermissionsDatasource: computed(() => store.getters['roles/getUserRolesPermissions']),
      all_roles_datasource: computed(() => store.getters['roles/getBaseRoles']),
      row_User_Selected,
      //New User
      employeeInfo,
      CloseUserPopup
    };
  },
  mixins: [dxGrid, MyValidator, responsives],
  components: {
    DxButton,
    DxDataGrid,
    DxPopup,
    DxForm,
    DxItem,
    customDial,
    DxSelectBox,
    DxDropDownButton,
    DxScrollView,
    DxDropDownBox,
    DxSwitch
  },
  data() {
    return {
      users_colomuns: [
        { cellTemplate: "radif", caption: this.$i18n.t('users.grid_row_user_no'), dataType: 'number', allowFiltering: false },
        { dataField: 'id', caption: this.$i18n.t('users.grid_row_user_id'), dataType: 'number', visible: false },
        { dataField: 'firstName', caption: this.$i18n.t('users.grid_row_user_firstName'), dataType: 'string' },
        { dataField: 'lastName', caption: this.$i18n.t('users.grid_row_user_lastName'), dataType: 'string' },
        { dataField: 'userName', caption: this.$i18n.t('users.grid_row_user_userName'), dataType: 'string' },
        { dataField: 'email', caption: this.$i18n.t('users.grid_row_user_email'), dataType: 'string' },
        { dataField: 'title', caption: this.$i18n.t('users.grid_row_user_title'), dataType: 'string' },
        { dataField: 'partTitle', caption: this.$i18n.t('users.grid_row_user_partTitle'), dataType: 'string' },
        { dataField: 'passwordHash', caption: this.$i18n.t('users.grid_row_user_passwordHash'), dataType: 'string', visible: false },
        { cellTemplate: 'actions', caption: this.$i18n.t('users.grid_row_user_actions'), allowFiltering: false, allowSorting: false }
      ],
      changePwd: null,
      showUserDeleteDialog: false,
      DeleteDialogUserRole: false,
      DeleteUserRole: false,
      row_UserRole_Selected: {},
      dialogDeleteUserRoleTitle: "",
      userRolePermissionsColumns: [
        { dataField: 'userId', caption: this.$i18n.t('users.grid_row_user_permission_userId'), dataType: 'number', visible: false },
        { cellTemplate: 'actionsUserRole', caption: this.$i18n.t('users.grid_row_user_permission_actionsUserRole'), allowFiltering: false, allowSorting: false },
        { dataField: 'userInfo', caption: this.$i18n.t('users.grid_row_user_permission_userInfo'), dataType: 'string', visible: false },
        { dataField: 'userName', caption: this.$i18n.t('users.grid_row_user_permission_userName'), dataType: 'string', visible: false },
        { dataField: 'roleId', caption: this.$i18n.t('users.grid_row_user_permission_roleId'), dataType: 'string', visible: false },
        { dataField: 'roleName', caption: this.$i18n.t('users.grid_row_user_permission_roleName'), dataType: 'string' },
        { dataField: 'roleNameFa', caption: this.$i18n.t('users.grid_row_user_permission_roleNameFa'), dataType: 'string' },
        { dataField: 'permissionId', caption: this.$i18n.t('users.grid_row_user_permission_permissionId'), dataType: 'string', visible: false },
        { dataField: 'permissionTitleEn', caption: this.$i18n.t('users.grid_row_user_permission_permissionTitleEn'), dataType: 'string' },
        { dataField: 'permissionTitle', caption: this.$i18n.t('users.grid_row_user_permission_permissionTitle'), dataType: 'string' },
      ],
      selectedRoleText: '',
      selectedRole: {},
      RolesListColumns: [
        { dataField: 'id', caption: this.$i18n.t('users.grid_row_roles_id'), dataType: 'number', visible: false },
        { dataField: 'nameFa', caption: this.$i18n.t('users.grid_row_roles_nameFa'), dataType: 'string' },
        { dataField: 'nameEn', caption: this.$i18n.t('users.grid_row_roles_nameEn'), dataType: 'string' },
      ],
      userRolePermissions: [],
      showDialogRoleUser: false,
      titleUserRole: '',
      titleUserMenu: '',
      users_menu_roles_colomuns: [
        { cellTemplate: "radif_menu", caption: this.$i18n.t('users.grid_row_user_menu_role_radif_menu'), dataType: 'number', allowFiltering: false },
        { dataField: 'userID', caption: this.$i18n.t('users.grid_row_user_menu_role_userID'), dataType: 'number', visible: false },
        { dataField: 'firstName', caption: this.$i18n.t('users.grid_row_user_menu_role_firstName'), dataType: 'string', visible: false },
        { dataField: 'lastName', caption: this.$i18n.t('users.grid_row_user_menu_role_lastName'), dataType: 'string', visible: false },
        { dataField: 'userName', caption: this.$i18n.t('users.grid_row_user_menu_role_userName'), dataType: 'string', visible: false },
        { dataField: 'roleId', caption: this.$i18n.t('users.grid_row_user_menu_role_roleId'), dataType: 'string', visible: false },
        { dataField: 'roleName', caption: this.$i18n.t('users.grid_row_user_menu_role_roleName'), dataType: 'string' },
        { dataField: 'partTitle', caption: this.$i18n.t('users.grid_row_user_menu_role_partTitle'), dataType: 'string' },
        { dataField: 'menuText', caption: this.$i18n.t('users.grid_row_user_menu_role_menuText'), dataType: 'string' },
      ],
      showDialogUserMenuRole: false,
      titlePartForUser: '',
      gridBoxValue: [3],
      gridBoxValueRole: [3],
      partsListColumns: [
        { cellTemplate: "radif_1", caption: this.$i18n.t('users.grid_row_parts_radif_1'), dataType: 'number', allowFiltering: false },
        { dataField: 'id', caption: this.$i18n.t('users.grid_row_parts_id'), dataType: 'number', visible: false },
        { dataField: 'partTitle', caption: this.$i18n.t('users.grid_row_parts_partTitle'), dataType: 'string' },
      ],
      isDropDownBoxOpened: false,
      selectedPart: {},
      selectedPartText: '',
      showDialogPartUser: false,
      dataGridRefUsers: 'dataGrid',
      dataGridRefUsersRoles: 'dataGridRole',
      Dropactions: [
        { id: 1, text: this.$i18n.t('users.drop_down_toolbar_new_user'), icon: "user" },
        { id: 2, text: this.$i18n.t('users.drop_down_toolbar_new_reload_2'), icon: "refresh" },
        { id: 3, text: this.$i18n.t('users.drop_down_toolbar_new_back_2'), icon: "undo" },
        // { id: 4, text: "تنظیمات1", icon: "preferences" },
        // { id: 5, text: "تنظیمات2", icon: "preferences" },
        // { id: 6, text: "تنظیمات3", icon: "preferences" },
      ],
      UserColumns: [
        { cellTemplate: "radif", caption: this.$i18n.t('users.grid_users_info_radif'), dataType: 'number', allowFiltering: false, width: 50, alignment: "center" },
        { cellTemplate: 'actions', caption: this.$i18n.t('users.grid_users_info_actions'), allowFiltering: false, allowSorting: false },
        { dataField: 'Id', caption: this.$i18n.t('users.grid_users_info_Id'), dataType: 'string', alignment: 'center', format: { type: "fixedPoint", precision: 0 } },
        { dataField: 'FullName', caption: this.$i18n.t('users.grid_users_info_FullName'), dataType: 'string', alignment: 'center' },
        { dataField: 'Username', caption: this.$i18n.t('users.grid_users_info_Username'), dataType: 'string', alignment: 'center' },
        { dataField: 'Email', caption: this.$i18n.t('users.grid_users_info_Email'), dataType: 'string', alignment: 'center' }
      ],
      allMode: 'allPages',
      checkBoxesMode: themes.current().startsWith('material') ? 'always' : 'onClick',
      fullUserNameSelected: '',
      editMode: false,
      popupNewUserVisible: false,
    }
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      debugger

      const dataGrid = this.$refs.dataGrid.instance;
      this.$refs.dataGrid.columns[0].caption = this.$i18n.t('users.grid_row_user_menu_role_radif_menu');
      this.$refs.dataGrid.columns[1].caption = this.$i18n.t('users.grid_row_user_menu_role_userID');
      this.$refs.dataGrid.columns[2].caption = this.$i18n.t('users.grid_row_user_menu_role_firstName');
      this.$refs.dataGrid.columns[3].caption = this.$i18n.t('users.grid_row_user_menu_role_lastName');
      this.$refs.dataGrid.columns[4].caption = this.$i18n.t('users.grid_row_user_menu_role_userName');
      this.$refs.dataGrid.columns[5].caption = this.$i18n.t('users.grid_row_user_menu_role_roleId');
      this.$refs.dataGrid.columns[6].caption = this.$i18n.t('users.grid_row_user_menu_role_roleName');
      this.$refs.dataGrid.columns[7].caption = this.$i18n.t('users.grid_row_user_menu_role_partTitle');
      this.$refs.dataGrid.columns[8].caption = this.$i18n.t('users.grid_row_user_menu_role_menuText');

      dataGrid.option('columns', dataGrid.option('columns'));


      if (this.$refs.dataGrid_roles != null) {
        const dataGrid_roles_holder = this.$refs.dataGrid_roles.instance;
        this.$refs.dataGrid_roles.columns[0].caption = this.$i18n.t('users.grid_row_user_menu_role_radif_menu');
        this.$refs.dataGrid_roles.columns[1].caption = this.$i18n.t('users.grid_row_user_menu_role_userID');
        this.$refs.dataGrid_roles.columns[2].caption = this.$i18n.t('users.grid_row_user_menu_role_firstName');
        this.$refs.dataGrid_roles.columns[3].caption = this.$i18n.t('users.grid_row_user_menu_role_lastName');
        this.$refs.dataGrid_roles.columns[4].caption = this.$i18n.t('users.grid_row_user_menu_role_userName');
        this.$refs.dataGrid_roles.columns[5].caption = this.$i18n.t('users.grid_row_user_menu_role_roleId');
        this.$refs.dataGrid_roles.columns[6].caption = this.$i18n.t('users.grid_row_user_menu_role_roleName');
        this.$refs.dataGrid_roles.columns[7].caption = this.$i18n.t('users.grid_row_user_menu_role_partTitle');
        this.$refs.dataGrid_roles.columns[8].caption = this.$i18n.t('users.grid_row_user_menu_role_menuText');
        dataGrid_roles_holder.option('columns', dataGrid_customer_company.option('columns'));
      }

      if (this.$refs.grid_all_roles != null) {
        const grid_all_roles_holder = this.$refs.grid_all_roles.instance;
        this.$refs.grid_all_roles.columns[0].caption = this.$i18n.t('users.grid_row_roles_id');
        this.$refs.grid_all_roles.columns[1].caption = this.$i18n.t('users.grid_row_roles_nameFa');
        this.$refs.grid_all_roles.columns[2].caption = this.$i18n.t('users.grid_row_roles_nameEn');
        grid_all_roles_holder.option('columns', dataGrid_customer_company.option('columns'));
      }


      if (this.$refs.dataGridRole != null) {
        const dataGridRole_holder = this.$refs.dataGridRole.instance;
        this.$refs.dataGridRole.columns[0].caption = this.$i18n.t('users.grid_row_user_permission_userId');
        this.$refs.dataGridRole.columns[1].caption = this.$i18n.t('users.grid_row_user_permission_actionsUserRole');
        this.$refs.dataGridRole.columns[2].caption = this.$i18n.t('users.grid_row_user_permission_userInfo');
        this.$refs.dataGridRole.columns[3].caption = this.$i18n.t('users.grid_row_user_permission_userName');
        this.$refs.dataGridRole.columns[4].caption = this.$i18n.t('users.grid_row_user_permission_roleId');
        this.$refs.dataGridRole.columns[5].caption = this.$i18n.t('users.grid_row_user_permission_roleName');
        this.$refs.dataGridRole.columns[6].caption = this.$i18n.t('users.grid_row_user_permission_roleNameFa');
        this.$refs.dataGridRole.columns[7].caption = this.$i18n.t('users.grid_row_user_permission_permissionId');
        this.$refs.dataGridRole.columns[8].caption = this.$i18n.t('users.grid_row_user_permission_permissionTitleEn');
        this.$refs.dataGridRole.columns[9].caption = this.$i18n.t('users.grid_row_user_permission_permissionTitle');

        dataGridRole_holder.option('columns', dataGrid_customer_company.option('columns'));
      }

      if (this.$refs.grid_parts != null) {
        const grid_parts_holder = this.$refs.grid_parts.instance;
        this.$refs.grid_parts.columns[0].caption = this.$i18n.t('users.grid_row_parts_radif_1');
        this.$refs.grid_parts.columns[1].caption = this.$i18n.t('users.grid_row_parts_id');
        this.$refs.grid_parts.columns[2].caption = this.$i18n.t('users.grid_row_parts_partTitle');

        grid_parts_holder.option('columns', dataGrid_customer_company.option('columns'));
      }




    }

  },
  methods: {
    setNewUserDialog() {
      this.popupNewUserVisible = false;
      this.makeInitSize(650, 410)
    },
    showDeleteDialog() {
      debugger
      if (this.row_User_Selected === null || this.row_User_Selected === undefined) {
        notify(this.$i18n.t('users.validation_select_user_first'), 'warning', this.notifyTimeSet);
        return;
      }
      this.showUserDeleteDialog = true;
    },
    delUserRole() {
      debugger
      if (this.row_UserRole_Selected === null || this.row_UserRole_Selected === undefined) {
        notify(this.$i18n.t('users.validation_pre_role_delete'), 'warning', this.notifyTimeSet);
        return;
      }
      if (this.row_User_Selected === null || this.row_User_Selected === undefined) {
        notify(this.$i18n.t('users.validation_pre_role_delete'), 'warning', this.notifyTimeSet);
        return;
      }
      const holder_data = {
        uid: this.row_User_Selected.id,
        role_id: this.row_UserRole_Selected.roleId
      }
      this.store.dispatch('roles/deleteUserRoleByUserIdRoleId', holder_data);
      this.DeleteDialogUserRole = false;
      setTimeout(() => {
        this.getUserRoleByUid();
      }
        , 2000);
    },
    closeDeleteDialogUserRole() {
      debugger
      const dataGrid = this.$refs[this.dataGridRefUsersRoles].instance;
      dataGrid.clearSelection();
      this.DeleteDialogUserRole = false;
    },
    showDeleteDialogUserRole() {
      this.DeleteDialogUserRole = true;
    },
    saveNewRoleToUser() {
      debugger
      if (this.selectedRole === null || this.selectedRole === undefined || this.selectedRole.id === undefined) {
        notify(this.$i18n.t('users.validation_pre_role_to_user'), 'warning', this.notifyTimeSet);
        return;
      }
      const holder_data = {
        UserId: this.row_User_Selected.id,
        RoleID: this.selectedRole.id
      }
      this.store.dispatch('roles/addRoleUser', holder_data);
      this.closeUserRoleDialog();
    },
    reloadDataRoles() {
      this.store.dispatch('roles/getAllRoles');
    },
    getUserRoleByUid() {
      debugger
      this.store.dispatch('roles/getUserRolesByUserId', this.row_User_Selected.id);
    },
    showUserRoleDialog() {
      this.makeInitSize(1000, 800);
      if (this.row_User_Selected === null || this.row_User_Selected === undefined) {
        notify(this.$i18n.t('users.validation_select_user_first'), 'warning', this.notifyTimeSet);
        return;
      }
      this.getUserRoleByUid();
      this.showDialogRoleUser = true;
    },
    closeUserRoleDialog() {
      this.showDialogRoleUser = false;
      setTimeout(() => {
        debugger
        this.reloadData();
      }, this.reloadTimeSet);
    },
    accessUserMenuRoles(data) {
      this.makeInitSize(1000, 700);
      debugger
      if (this.row_User_Selected === null || this.row_User_Selected === undefined) {
        notify(this.$i18n.t('users.validation_select_user_first'), 'warning', this.notifyTimeSet);
        return;
      }
      this.reloadUserMenuRoleData();
      this.showDialogUserMenuRole = true;
    },
    closePartUserDialog() {
      debugger
      this.showDialogPartUser = false;
      this.reloadData();
    },
    saveNewPartForUser() {
      debugger
      if (this.selectedPart === undefined) {
        notify(this.$i18n.t('users.validation_pre_part_seleciton'), 'warning', this.notifyTimeSet);
        return;
      }
      const newData = {
        Company: this.company_info[0].id,
        CompanyPart: this.selectedPart.id,
        UserId: this.row_User_Selected.id
      }
      this.store.dispatch('parts/addNewPartUser', newData);
      this.showDialogPartUser = false;
      setTimeout(() => {
        this.reloadData();
      }, this.reloadTimeSet);
    },
    gridBoxDisplayExpr() {
      return this.selectedPartText;
    },
    gridBoxDisplayExprRole() {
      return this.selectedRoleText;
    },
    changeDropDownBoxValue(e) {
      debugger
      this.selectedPart = e.currentSelectedRowKeys[0];
      this.isDropDownBoxOpened = false;
      if (this.selectedPart != undefined)
        this.selectedPartText = this.selectedPart.partTitle;
    },
    changeDropDownBoxValueRole(e) {
      debugger
      this.selectedRole = e.currentSelectedRowKeys[0];
      this.isDropDownBoxOpened = false;
      if (this.selectedRole != undefined)
        this.selectedRoleText = this.selectedRole.nameFa;
    },
    accessUserM() {
      debugger
      this.makeInitSize(600, 400);
      if (this.row_User_Selected === null || this.row_User_Selected === undefined) {
        notify(this.$i18n.t('users.validation_part_assign_to_user'), 'warning', this.notifyTimeSet);
        return;
      }
      this.showDialogPartUser = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "btnRGroup",
      });
      e.toolbarOptions.items.unshift({
        location: "after",
        template: "btnGroup",
      });
    },
    onItemClick(e) {
      debugger
      switch (e.itemData.id) {
        case 1:
          this.NewUserPopup();
          break;
        case 2:
          this.reloadData();
          break;
        case 3:
          this.$router.go(-1);
          break;
        default:
          notify('خطای ناشناس در منو', 'warning', this.notifyTimeSet);
          break;
      }
    },
    reloadUserMenuRoleData() {
      debugger
      const user_id = this.row_User_Selected.id;
      this.store.dispatch('user/getUserRoleWithMenusAccess', user_id);
    },
    reloadData() {
      this.store.dispatch('user/getUsersWithCompanyParts');
    },
    reloadCompanyData() {
      debugger
      this.store.dispatch("company/getCmopany");
    },
    reloadParts() {
      this.store.dispatch("parts/getPartsWithCount");
    },
    delUserM() {
      debugger
      this.store.dispatch('user/deleteUser', this.row_User_Selected.id);
      this.showUserDeleteDialog = false;
      setTimeout(() => {
        debugger
        this.reloadData();
      }, this.reloadTimeSet);
    },
    saveNewUser($event) {
      this.makeInitSize(650, 410);
      debugger
      if (this.isStateOkTotal() > 0) {
        let give_me_errors = this.getElementsWithErorrs();
        notify(give_me_errors, 'error', this.notifyTimeSet);
        console.log(give_me_errors);
        //this.$store.commit('setError',give_me_errors);       
        return;
      }
      const { Name, Family, Username, Email, Password, PasswordConfirm } = this.employeeInfo;
      const user_holder = {
        name: Name,
        family: Family,
        username: UserName,
        email: Email,
        password: Password
      }
      this.store.dispatch('user/addNewUser', user_holder);
      this.popupNewUserVisible = false;
      setTimeout(() => {
        this.reloadData();
      }, this.reloadTimeSet);

    },
    NewUserPopup() {
      debugger
      this.makeInitSize(650, 450);
      this.popupNewUserVisible = true;
      this.employeeInfo.Name = '';
      this.employeeInfo.Family = '';
      this.employeeInfo.Username = '';
      this.employeeInfo.Email = '';
      this.employeeInfo.Password = '';
      this.employeeInfo.PasswordConfirm = '';
      this.editMode = false;
      this.make_all_fields_wrong();
    },
    editUserInfo() {
      debugger
      const { Id, Name, Family, Username, Email, Password, PasswordConfirm } = this.employeeInfo;
      // const user_holder = {  
      //   Id: Id,           
      //   FirstName: Name,
      //   LastName: Family,
      //   UserName: Username,
      //   NormalizedUserName :Username,
      //   Email: Email,                
      //   PasswordHash: Password
      // }
      const user_holder = {
        id: Id,
        userName: Username,
        normalizedUserName: Username,
        email: Email,
        normalizedEmail: Email,
        emailConfirmed: true,
        passwordHash: Password,
        securityStamp: "",
        concurrencyStamp: "",
        phoneNumber: "",
        phoneNumberConfirmed: true,
        twoFactorEnabled: true,
        lockoutEnd: null,
        lockoutEnabled: true,
        accessFailedCount: 0,
        firstName: Name,
        lastName: Family
      }
      this.store.dispatch('user/editUser', user_holder);
      if (this.changePwd === true) {
        setTimeout(() => {
          this.store.dispatch('user/editUserPass', user_holder);
        }, 1000)
      }

      this.popupNewUserVisible = false;
      setTimeout(() => {
        debugger
        this.reloadData();
        const dataGrid = this.$refs[this.dataGridRefUsers].instance;

        dataGrid.clearSelection();
      }, this.reloadTimeSet);
    },
    editUserM() {
      debugger
      this.makeInitSize(650, 500);
      if (this.row_User_Selected === null || this.row_User_Selected === undefined) {
        notify(this.$i18n.t('users.validation_pre_user_edit_selection'), 'warning', this.notifyTimeSet);
        return;
      }
      this.employeeInfo.Id = this.row_User_Selected.id;
      this.employeeInfo.Name = this.row_User_Selected.firstName;
      this.employeeInfo.Family = this.row_User_Selected.lastName;
      this.employeeInfo.Username = this.row_User_Selected.userName;
      this.employeeInfo.Email = this.row_User_Selected.email;
      this.employeeInfo.Password = this.row_User_Selected.passwordHash;
      this.employeeInfo.PasswordConfirm = this.row_User_Selected.passwordHash;
      this.editMode = true;
      this.popupNewUserVisible = true;
      this.make_all_fields_ok();
    },
    onSelectionChanged(selectedRowsData) {
      debugger
      this.row_User_Selected = selectedRowsData.selectedRowsData[0];
      if (this.row_User_Selected != null || this.row_User_Selected != undefined) {
        this.fullUserNameSelected = this.row_User_Selected.firstName + ' ' + this.row_User_Selected.lastName;
        this.titleUserMenu = this.$i18n.t('users.selected_user_menu') + this.fullUserNameSelected;
        this.titlePartForUser = this.$i18n.t('users.selected_user_part') + this.fullUserNameSelected;
        this.titleUserRole = this.$i18n.t('users.selected_user_role') + this.fullUserNameSelected;
      }

    },
    onSelectionChangedUserRoles(selectedRowsData) {
      debugger
      this.row_UserRole_Selected = selectedRowsData.selectedRowsData[0];
      if (this.row_UserRole_Selected != null || this.row_UserRole_Selected != undefined) {
        this.row_UserRole_SelectedTitle = this.row_UserRole_Selected.roleNameFa;
        this.dialogDeleteUserRoleTitle = this.$i18n.t('users.detele_user_role_title') +
          this.row_UserRole_SelectedTitle +
          this.$i18n.t('users.detele_user_role_belong') + this.fullUserNameSelected;
      }

    },

    saveNewUser($event) {
      debugger
      if (this.isStateOkTotal() > 0) {
        let give_me_errors = this.getElementsWithErorrs();
        notify(give_me_errors, 'error', this.notifyTimeSet);
        return;
      }
      const { Name, Family, Username, Email, Password, PasswordConfirm } = this.employeeInfo;
      if (Password != PasswordConfirm) {
        notify(this.$i18n.t('users.validation_password_not_match'), 'warning', this.notifyTimeSet);
        return;
      }
      const user_holder = {
        name: Name,
        family: Family,
        username: Username,
        email: Email,
        password: Password
      }
      this.store.dispatch('user/addNewUser', user_holder);
      this.popupNewUserVisible = false;
      setTimeout(() => {
        this.reloadData();
      }, this.reloadTimeSet);

    }
  },
  created() {
    this.makeInitSize(650, 410);
    this.reloadData();
    this.reloadCompanyData();
    this.reloadParts();
    this.reloadDataRoles();
    this.make_state_bind('inputName', false, this.$i18n.t('users.validation_name_empty'));
    this.make_state_bind('inputFamily', false, this.$i18n.t('users.validation_famliy_empty'));
    this.make_state_bind('inputUsername', false, this.$i18n.t('users.validation_user_empty'));
    this.make_state_bind('inputEmail', false, this.$i18n.t('users.validation_email_empty'));
    this.make_state_bind('inputPwd', false, this.$i18n.t('users.validation_pwd_empty'));
    this.make_state_bind('inputPwdConfirm', false, this.$i18n.t('users.validation_pwd_confirm_empty'));
  },
}

</script>

<style lang="scss">
@import "@/dx-styles.scss";




.dx-popup-wrapper>.dx-overlay-content {
  background: #fff;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border-left-color: $popup_color;
  border-left-width: 9px;
}

#ps_Table {
  height: 60vh;
}

.dx-toast-message {
  white-space: pre-line;
}

.dx-toast-content {
  min-width: 100px;
  max-width: 200px;
  padding: 10px;
}

.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;

  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 17px;
}

.vue-logo {
  width: 180px;
  height: 62px;
}

.plus {
  margin: 20px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;

    &.plus {
      margin: 0;
    }
  }
}


</style>
