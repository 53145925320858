export default {
    setTitleMain(state, data) {
        state.titleMain = data;
    },
    setUserInfo(state, data) {
        debugger
        if (data != null) {
            state.userId = data.userId;
            state.token = data.token;
            state.userName = data.userName;
            state.userInfo = data.userInfo;
            state.userIsLogged = true;
        }
        else 
        {
            state.userId = null;
            state.token = null;
            state.userName = null;
            state.userInfo = null;
            state.userIsLogged = false;
        }
    },
    setLoginLoading(state, data) {
        state.login_loading = data;
    },
    setTokenExpires(state, data) {
        state.tokenExpires = data;
    },

}