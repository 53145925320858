export default {   
    setParts(state,data)
    {                      
        state.parts=data;
    },
    setBaseParts(state,data)
    {                      
        state.BaseParts=data;
    },
    setSinglePart(state,data)
    {
        state.singlePart = data;
    },
    setUser_part(state,data)
    {
        state.user_part = data;
    }
}