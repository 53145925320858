<template>
    <carousel :items-to-show="1" :autoplay="2000" :wrap-around="true" v-bind="settings" >
        <!-- :breakpoints="breakpoints" -->
        <slide v-for="(slider_item, key) in slider_images" :key="key">
            <img :src="slider_item" alt="Slider" />
        </slide>

        <template #addons>
            <Navigation />
            <Pagination />
        </template>
    </carousel>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
    name: 'myCarousel',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 3.5,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 5,
                    snapAlign: 'start',
                },
            },
            slider_images: [
                // require('@/assets/clinic/m6.jpg'),
                // require('@/assets/clinic/m8.jpg'),
                // require('@/assets/clinic/m9.jpg'),
                
                require('@/assets/clinic/1.jpg'),
                // require('@/assets/clinic/3.jpg'),
                // require('@/assets/clinic/4.jpg'),
                require('@/assets/clinic/6.jpg'),



            ],
        }

    }
}
</script>

<style lang="scss" scoped>

.carousel__slide {
    img{
        width: 100% !important;
        height: 350px !important;
        object-fit: cover !important;
    }
}
.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
</style>