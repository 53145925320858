import axios from 'axios'
import notify from 'devextreme/ui/notify';
import errorHandler from '@/components/errorHandler';

export default {

    getBasePatientList({commit},data){   
        debugger                     
        axios
        .get('Patients/GetBasePatientsList')
        .then((response)=>{
            debugger                                                
            commit('setBasePatientList',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    getPatientList({commit},data){   
        // debugger                     
        axios
        .get('Patients/GetPatientsList')
        .then((response)=>{
            // debugger                                                
            commit('setPatientList',response.data);
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    addNewPatient({commit},data){
        debugger
        axios
        .post('Patients/AddNewPatient',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },    
    editPatient({commit},data){
        debugger
        axios
        .post('Patients/UpdatePatient',data)
        .then((response)=>{  
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            }                   
        })
        .catch(error => {
            errorHandler.methods.handleError(error);
        })
    },
    deletePatient({commit},data){ 
        debugger
        axios
        .post('Patients/DeletePatient?id='+data)
        .then((response)=>{            
            debugger
            if(response.status === 200)
            {
                notify(response.data.message, "success", this.notifyTimeSet);
            }   
            else 
            {
                notify(response.data.message, "error", this.notifyTimeSet);
            } 
        })
        .catch(error => {
            debugger
            errorHandler.methods.handleError(error);
        })
    },

}