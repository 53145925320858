<template class="m-1">
    <hr>
    <p>{{title}}</p>
    <DxDataGrid
              class="mt-3"
              :show-row-lines="true"
              :show-column-lines="true"
              :row-alternation-enabled="false"
              :rtl-enabled="true"
              :data-source="UsersDataSource"
              :columns="UserColumns"
              :remote-operations="false"
              :column-auto-width="true"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              column-resizing-mode="widget"
              :hover-state-enabled="true"
              @selection-changed="selectOrder"
              :noDataText="checkData"
              @toolbar-preparing="onToolbarPreparing($event)"
              @editor-preparing="onEditorPreparing"
              @row-Prepared="OnRowPrepared"
              key-expr="id"
              :allowFiltering="filterEnabled"
              id="ps_Table"
              :height="height"
              :filter-sync-enabled="filterEnabled"
              > 
              >
              <DxScrolling mode="infinite"/>
              <DxSorting mode="multiple"/>
              <DxSelection
               mode="single"
              />
              <DxSearchPanel
              :visible="false"
              :width="200"
              placeholder="جستجو ..."
              />
              <DxExport
              :enabled="false"
              />
              <DxColumnChooser :enabled="false" mode="dragAndDrop"/>

            
            <!-- <template #btnRGroup class="p-0 m-0">
                <div class="row">
                    <div class="col-sm-4">
                        <Button class="border mr-1" color="danger" size="sm" @click="showByFilter(1)" >click1</Button> 
                    </div>
                    <div class="col-sm-4">
                        <Button class="border mr-1" color="danger" size="sm" @click="showByFilter(1)" >click2</Button> 
                    </div>
                    <div class="col-sm-4">
                        <Button class="border mr-1" color="danger" size="sm" @click="showByFilter(1)" >click3</Button> 
                    </div>
                </div>            
            </template>  -->

              <!-- <template #expired="{ data }">
                <div class="py-2 px-2 rounded" style="background: #e97a79; color: #fff;" v-if="data.data.expired == 1">
                  منقضی شده
                </div>
                <div v-else>

                </div>
              </template> -->
              <template #radif1="{ data }">
                      {{data.rowIndex + 1}}
              </template>
              <template #actions="{ data }">
            <div>
               <Button                
                class="btn btn-success"                            
                target="_blank"
                :title="'انتخاب : '+data.row.data.username"
                @click="ChooseUser(data.row.data)"
                >
                تخصیص به
            </Button>
            <!-- :href="VUE_APP_API_URL+'File/Download?key=' + data.row.data.encryptFilePath" -->
            </div>
          </template>
            <DxSummary>
                <DxTotalItem
                column="radif1"
                summary-type="count"                
                />

                <DxTotalItem
                column="Id"
                summary-type="sum"
                value-format="#,##0.###;0;(#,##0.###)"
                />

                <DxTotalItem
                column="orderNo"
                summary-type="count"
                alignment="center"
                />
            </DxSummary>
            <DxStateStoring
            :enabled="false"
            type="localStorage"
            storage-key="storage"
            />


              <DxFilterRow :visible="true"/>
              <DxLoadPanel :enabled="true"/>
             
            </DxDataGrid>
</template>

<script>
import {dxGrid} from '@/components/dxGrid'
import { stringLiteral } from '@babel/types';
import { computed, reactive } from '@vue/reactivity'
import { useStore } from 'vuex'

export default {
    props:{
        title:String,
        uid:String,
        username:String,
    },
    mixins: [dxGrid],
    setup(props,context){
        let  height =window.innerHeight-200;
        const store = useStore();
        let filterEnabled = true;
        const UserColumns = [
            { cellTemplate: "radif1", caption: "#", dataType: 'number', allowFiltering: false,width: 50, alignment: "center"},            
            { dataField: 'id', caption: 'شناسه', dataType: 'string',alignment:'center',visible:false },
            { dataField: 'userInfo', caption: 'مشخصات', dataType: 'string' ,alignment:'center'},            
            { dataField: 'username', caption: 'نام کاربری', dataType: 'string' ,alignment:'center',format: { type: "fixedPoint",  precision: 0 } },
            { cellTemplate: 'actions', caption: 'عملیات', allowFiltering: false, allowSorting: false },
        ];

        const ChooseUser = (data) => {            
            console.log('ChooseUser : ',data);
            context.emit('UserSelected',data);            
        }

        const onEditorPreparing=(e)=>{          
            if (e.parentType === "searchPanel") {
                  e.editorOptions.stylingMode = "outlined";
              }
        }

        const reloadData = () =>{
          debugger
           
           const holder_param={
              uid:props.uid,
              username:props.username
           }
            store.dispatch('user/getUsersBaseInfos',holder_param);
        }

        const  selectOrder = (selectedRowsData) => {
            const item = selectedRowsData.selectedRowsData[0];
            
            };
        
  
        const onToolbarPreparing =(e) =>{
                // e.toolbarOptions.items.unshift({
                //     location: "before",
                //     template: "btnRGroup",
                // });
                // e.toolbarOptions.items.unshift({
                //     location: "after",
                //     template: "btnGroup",
                // });                
       };
      const OnRowPrepared = (e) => { 
            // if (e.rowType !== "data")  
            //     return  
            // if (e.data.expired === 1) {  
            // e.rowElement.classList.add("red-row");
            // }  
            // if (e.data.twoWeekRemain === 1) {  
            //     e.rowElement.classList.add("yellow-row");
            // }          
        }
        reloadData();
                    
        return {
            UsersDataSource: computed(() => store.getters['user/getUsers']),           
            UserColumns,
            onToolbarPreparing,
            onEditorPreparing,
            OnRowPrepared,
            reloadData,
            selectOrder,
            ChooseUser,
            filterEnabled,
            height
        };
    },
}
</script>

<style>
#ps_Table {
  height: 60vh;
}
</style>